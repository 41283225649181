import { useAppToolbarQuery as useBaseAppToolbarQuery} from 'generated/graphqlTypes';
import { IProject } from 'components/pages/ProjectPickerPage/dataService';


export interface IAppToolbarQuery {
  id: string;
  isSuperAdmin: boolean;
  projects: IProject[];
}


export function useAppToolbarQuery() {
  const queryTuple = useBaseAppToolbarQuery();

  const siteUser = queryTuple.data ? queryTuple.data.loggedInSiteUser : '';

  let data: IAppToolbarQuery | null = null;
  if (!queryTuple.loading && siteUser) {
    data = {
      id: siteUser.id,
      isSuperAdmin: siteUser.isSuperAdmin,
      projects: siteUser.projects,
    };
  }

  return {
    ...queryTuple,
    data
  };
}
