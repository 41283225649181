import _ from 'lodash';
import * as React from 'react';

import styles from './LogoField.module.css';

import imgEmptyLogo from 'assets/images/emptyLogo.svg';



const __bytesInKb = 1024;
const __kbInMb = 1024;
const __maxFileSizeMb = 5;
const __maxFileSizeBytes = __maxFileSizeMb * __kbInMb * __bytesInKb;

const __maxWidth = 500;
const __maxHeight = 500;



export interface ILogoFieldProps {
  disabled?: boolean;
  extraClassName?: string;
  logoUrl: string;
  onChange: (logoUrl: string) => void;
}

export const LogoField: React.FC<ILogoFieldProps> = props => {
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    setErrorMessage("");
  }, [props.logoUrl]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (_.isString(reader.result)) {
          const fileUrl = reader.result;
          if (file.size > __maxFileSizeBytes) {
            setErrorMessage(`File size cannot exceed ${__maxFileSizeMb}MB.`);
            return;
          }

          __getDimensionsOfImageFile(fileUrl)
            .then(dimensions => {
              if (dimensions.width > __maxWidth || dimensions.height > __maxHeight) {
                setErrorMessage(`Image dimensions cannot be greater than ${__maxWidth}x${__maxHeight}.`);
              } else {
                props.onChange(fileUrl);
              }
            });
        }
      }, false);
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className={`${styles.container} ${props.extraClassName || ''}`}>
      <div className={styles.innerContainer}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={props.logoUrl ? props.logoUrl : imgEmptyLogo}
            alt="Logo" />

          <input
            id="logoField"
            className={styles.fileField}
            disabled={props.disabled}
            type="file"
            accept="image/png,image/jpeg"
            onChange={handleChange} />
        </div>

        {errorMessage && (
          <div className={styles.errorMessage}>
            {errorMessage}
          </div>
        )}

        <label
          htmlFor="logoField"
          className={styles.label}>
          {props.logoUrl ?
            "Click to Edit Project Logo" :
            "Tap to add a client logo"}
        </label>
      </div>
    </div>
  );
};



interface IImageDimensions {
  width: number;
  height: number;
}

function __getDimensionsOfImageFile(dataUrl: string): Promise<IImageDimensions> {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      const dimensions: IImageDimensions = {
        width: image.naturalWidth,
        height: image.naturalHeight
      };
      resolve(dimensions);
    };
    image.src = dataUrl;
  });
}
