import * as React from 'react';
import {
  TabBar,
  ITab
} from 'components/shared/TabBar/TabBar';
import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';
import { ProjectMilestoneForm } from './ProjectMilestoneForm/ProjectMilestoneForm';
import { PaymentMilestoneForm } from './PaymentMilestoneForm/PaymentMilestoneForm';

import styles from './AddMilestoneModal.module.css';



export interface IProjectFormData {
  milestoneName: string;
  milestoneDesc: string;
}



export interface IAddMilestoneModalProps extends IBaseModalTemplateProps {
  onCreatePaymentMilestone: (amount: number, link: string) => void;
  onCreateProjectMilestone: (displayName: string, description: string) => void;
}



export const AddMilestoneModal: React.FC<IAddMilestoneModalProps> = props => {
  const [selectedTabId, setSelectedTabId] = React.useState('project');
  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [paymentLink, setPaymentLink] = React.useState('');

  const [milestoneName, setMilestoneName] = React.useState('');
  const [milestoneDesc, setMilestoneDesc] = React.useState('');



  function handleSuccessfulSubmitProjectMilestone() {
    props.onCreateProjectMilestone(milestoneName, milestoneDesc);
    closeForm();
  }



  function handleSuccessfulSubmitPaymentMilestone() {
    // Convert dollars to cents.
    props.onCreatePaymentMilestone(paymentAmount * 100, paymentLink);
    closeForm();
  }



  function deleteFormInfo() {
    setPaymentAmount(0);
    setPaymentLink('');
    setMilestoneName('');
    setMilestoneDesc('');
  }



  function closeForm() {
    props.onClose();
    deleteFormInfo();
  }



  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={closeForm}>
      <div className={styles.root}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Create a New Milestone
          </h1>

          <p className={styles.description}>
            Fill in the below information to create a new milestone.
          </p>

          <TabBar
            tabDefs={__tabDefs}
            selectedTabId={selectedTabId}
            onSelect={setSelectedTabId} />
        </div>

        {selectedTabId === 'project' && (
          <ProjectMilestoneForm
            name={milestoneName}
            description={milestoneDesc}
            buttonText="Create Milestone"
            onSetName={setMilestoneName}
            onSetDesc={setMilestoneDesc}
            onSuccessfulSubmit={handleSuccessfulSubmitProjectMilestone} />
        )}

        {selectedTabId === 'payment' && (
          <PaymentMilestoneForm
            amount={paymentAmount}
            link={paymentLink}
            onSetAmount={setPaymentAmount}
            onSetLink={setPaymentLink}
            onSuccessfulSubmit={handleSuccessfulSubmitPaymentMilestone} />
        )}
    </div>
    </BaseModalTemplate>
  );
};

const __tabDefs: ITab[] = [
  {
    id: "project",
    displayName: "PROJECT MILESTONE"
  },
  {
    id: "payment",
    displayName: "PAYMENT MILESTONE"
  }
];
