import * as React from 'react';
import _ from 'lodash';
import { ProjectDropdownCard } from '../ProjectDropdownCard/ProjectDropdownCard';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';
import { IProject } from 'components/pages/ProjectPickerPage/dataService';
import { useOnClickOutsideRef } from 'util/hooks';

import styles from './DropdownMenu.module.css';



export interface IDropdownMenuProps {
  currExtId: string;
  projects: IProject[];
  isVisible: boolean;
  isSuperUser?: boolean;

  onClick: () => void;
  onClickAway: () => void;
  onLogout: () => void;
}



export const DropdownMenu: React.FC<IDropdownMenuProps> = props => {
  const refContainer = useOnClickOutsideRef<HTMLDivElement>(props.onClickAway, props);

  const currProject = _.find(props.projects, project => project.extId === props.currExtId);
  return (
    <>
      {!_.isEmpty(props.projects) && currProject && (
        <div
          ref={refContainer}
          className={styles.userSettings}>
          <ProjectDropdownCard
            onClick={props.onClick}
            image={currProject.logoUrl}
            displayName={currProject.displayName}
            projectType={currProject.projectType.displayName}
            isFirst />

          {props.isVisible && (
            <div className={styles.dropdown}>
              {!props.isSuperUser && _.map(props.projects, project => (
                <NavLink
                  key={project.extId}
                  to={routes.milestones.path(project.extId)}>
                  <ProjectDropdownCard
                    onClick={props.onClick}
                    image={project.logoUrl}
                    displayName={project.displayName}
                    projectType={project.projectType.displayName} />
                </NavLink>
              ))}

              {props.isSuperUser && (
                <div className={styles.dropdownLinkContainer}>
                  <NavLink
                    className={styles.dropdownLink}
                    to={routes.projectPicker.path()}>
                    Project Picker
                  </NavLink>
                </div>
              )}

              <div className={styles.dropdownLinkContainer}>
                <NavLink
                  className={styles.dropdownLink}
                  to={routes.account.path()}>
                  My Account
                </NavLink>
              </div>

              <div
                onClick={props.onLogout}
                className={styles.logout}>
                <div className={styles.logoutText}>
                  Log out
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
