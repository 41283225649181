import _ from 'lodash';
import * as React from 'react';

import update from 'immutability-helper';
import { PageTemplate } from 'components/pages/PageTemplate/PageTemplate';
import { RouteComponentProps } from 'react-router';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { EmailField } from 'components/shared/forms/InputField/EmailField';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { PasswordField } from 'components/shared/forms/InputField/PasswordField';
import { Button } from 'components/shared/Button/Button';
import { LogoField } from 'components/shared/LogoField/LogoField';
import { ProjectNameField } from 'components/shared/forms/InputField/ProjectNameField';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import * as dataService from 'components/pages/AccountPage/dataService';
import * as firebaseUtil from 'util/firebase';

import styles from './AccountPage.module.css';



export interface IAccountCredentialForm {
  email: string;
  password: string;
  confirmPass: string;
}



export interface IPersonalInfoForm {
  name: string;
  encodedFileUrl: string | null;
}



export interface IAccountPageProps extends RouteComponentProps { }

export const AccountPage: React.FC<IAccountPageProps> = props => {
  const refForm = React.useRef<Form>(null);
  const queryTuple = dataService.useAccountPageQuery();
  const [fnSaveSiteUser, saveSiteUserTuple] = dataService.useSaveSiteUserMutation();
  const [credentialFormData, setCredentialFormData] = React.useState<IAccountCredentialForm>({
    email: queryTuple.data?.email ?? '',
    password: '',
    confirmPass: ''
  });
  const [infoFormData, setInfoFormData] = React.useState<IPersonalInfoForm>({
    encodedFileUrl: null,
    name: queryTuple.data?.fullName ?? ""
  });

  React.useEffect(() => {
    if (queryTuple.data) {
      setInfoFormData(update(infoFormData, {
        name: { $set: queryTuple.data.fullName }
      }));

      setCredentialFormData(update(credentialFormData, {
        email: { $set: queryTuple.data.email }
      }));
    }
  }, [queryTuple.data?.fullName]);



  async function handleSubmitPersonalInfo() {
    if (queryTuple.data && queryTuple.data.id) {
      await fnSaveSiteUser({
        id: queryTuple.data.id,
        fullName: infoFormData.name !== '' ? infoFormData.name : undefined,
        profilePicUrl: infoFormData.encodedFileUrl || undefined
      });
    }
  }



  function handleSubmitCredential() {
    if (credentialFormData.password === credentialFormData.confirmPass) {
      firebaseUtil.updateCredentials(credentialFormData.email, credentialFormData.password);
    } else {
      alert("Passwords don't match");
    }

    if (credentialFormData.email !== '' && queryTuple.data && queryTuple.data.id) {
      fnSaveSiteUser({
        id: queryTuple.data.id,
        email: credentialFormData.email
      });
    }
  }



  const loadingOverlayText = saveSiteUserTuple.loading ? "Saving" : "Loading";

  return (
    <>
      <LoadingOverlay
        isVisible={queryTuple.loading || saveSiteUserTuple.loading}
        text={loadingOverlayText} />

      <PageTemplate
        {...props}
        requiresUser
        toolbarType="pickerToolbar">
        <div className={styles.root}>
          <h1>
            My Account
          </h1>

          <div className={styles.content}>
            <div className={styles.boxes}>
              <div className={styles.credentialBox}>
                <Form
                  onSuccessfulSubmit={handleSubmitCredential}>
                  <div className={styles.upperBar}>
                    <h2 className={styles.boxTitle}>
                      Your Credentials
                    </h2>

                    <Button
                      type="submit"
                      extraClassName={styles.button}>
                      Update
                    </Button>
                  </div>

                  <InputRow>
                    <StackedInputCell>
                      <label className={styles.formText}>
                        Email Address
                      </label>

                      <EmailField
                        autoComplete={false}
                        ref={_.partial(Form.captureElement, refForm, 'email')}
                        placeholder="email@email.com"
                        value={credentialFormData.email}
                        onChange={email => (
                          setCredentialFormData(update(credentialFormData, {
                            email: { $set: email }
                          }))
                        )} />
                    </StackedInputCell>
                  </InputRow>

                  <InputRow>
                    <StackedInputCell>
                      <label className={styles.formText}>
                        Password
                      </label>

                      <PasswordField
                        autoComplete={false}
                        ref={_.partial(Form.captureElement, refForm, 'password')}
                        placeholder="********"
                        value={credentialFormData.password}
                        onChange={password => (
                          setCredentialFormData(update(credentialFormData, {
                            password: { $set: password }
                          }))
                        )} />
                    </StackedInputCell>
                  </InputRow>

                  <InputRow>
                    <StackedInputCell>
                      <label className={styles.formText}>
                        Re-type Password
                      </label>

                      <PasswordField
                        autoComplete={false}
                        ref={_.partial(Form.captureElement, refForm, 'confirmPassword')}
                        placeholder="********"
                        value={credentialFormData.confirmPass}
                        onChange={password => (
                          setCredentialFormData(update(credentialFormData, {
                            confirmPass: { $set: password }
                          }))
                        )} />
                    </StackedInputCell>
                  </InputRow>
                </Form>
              </div>

              <div className={styles.informationBox}>
                <Form
                  onSuccessfulSubmit={handleSubmitPersonalInfo}>
                  <div className={styles.upperBar}>
                    <h2 className={styles.boxTitle}>
                      Your Information
                    </h2>

                    <Button
                      extraClassName={styles.button}
                      type='submit'>
                      Update
                    </Button>
                  </div>

                  <LogoField
                    extraClassName={styles.logoField}
                    logoUrl={infoFormData.encodedFileUrl || (queryTuple.data ? queryTuple.data.profilePictureUrl : '')}
                    onChange={newUrl =>
                      setInfoFormData(update(infoFormData, {
                        encodedFileUrl: { $set: newUrl },
                      })
                      )} />

                  <label className={styles.formText}>
                    Name
                  </label>

                  <ProjectNameField
                    extraClassName={styles.infoNameField}
                    autoComplete={false}
                    ref={_.partial(Form.captureElement, refForm, 'name')}
                    placeholder="name"
                    value={infoFormData.name}
                    onChange={name => (
                      setInfoFormData(update(infoFormData, {
                        name: { $set: name }
                      }))
                    )} />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    </>
  );
};
