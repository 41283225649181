export function getExtensionFromFilename(filename: string) {
  const match =  filename.match(/\.(?<fileExtension>[a-zA-Z0-9\.-]+)$/);

  return match?.groups?.fileExtension || '';
}



export function getExtensionFromDataUrl(dataUrl: string) {
  return dataUrl.substring("data:image/".length, dataUrl.indexOf(";base64"));
}
