import * as React from 'react';
import _ from 'lodash';
import { AttachmentTabCard } from 'components/pages/MilestonePage/ProjectMilestone/ProjectTabs/AttachmentsTab/AttachmentCard/AttachmentTabCard';
import { BaseTabContent } from 'components/pages/MilestonePage/ProjectMilestone/ProjectTabs/BaseTabContent/BaseTabContent';
import { IAttachmentData } from 'components/pages/MilestonePage/dataService';

import styles from './AttachmentsTab.module.css';

import imgDocument from 'assets/images/documents.svg';



export interface IAttachmentTabProps {
  attachments: IAttachmentData[];

  onClickAddAttachment: () => void;
}



export const AttachmentsTab: React.FC<IAttachmentTabProps> = props => (
  <BaseTabContent
    emptyContentDef={__emptyContentInfo}
    showEmptyCase={_.isEmpty(props.attachments)}
    onClick={props.onClickAddAttachment}>
    {_.map(props.attachments, attachment => (
      <div
        key={attachment.id}
        className={styles.attachmentContainer}>
        <AttachmentTabCard
          displayName={attachment.displayName}
          fileName={attachment.description}
          resourceUrl={attachment.resourceUrl} />
      </div>
    ))}
  </BaseTabContent>
);



const __emptyContentInfo = {
  picture: imgDocument,
  title: "No Attachments or Links Yet",
  desc: "Tap the button below to add an attachment or link"
};
