import React from 'react';
import _ from 'lodash';
import { Form } from "metisoft-react-components/dist/components/forms/Form/Form";
import { InputRow } from "metisoft-react-components/dist/components/forms/InputRow/InputRow";
import { StackedInputCell } from "components/shared/forms/StackedInputCell/StackedInputCell";
import { PaymentAmountField } from "components/shared/forms/InputField/PaymentAmountField";
import { TextArea } from "components/shared/forms/TextArea/TextArea";
import { Button } from "components/shared/Button/Button";

import styles from './PaymentMilestoneForm.module.css';



export interface IPaymentMilestoneFormProps {
  amount: number;
  link: string;

  onSetAmount: (newAmount: number) => void;
  onSetLink: (newLink: string) => void;
  onSuccessfulSubmit: () => void;
}

export const PaymentMilestoneForm: React.FC<IPaymentMilestoneFormProps> = props => {
  const refForm = React.useRef<Form>(null);

  return (
    <Form onSuccessfulSubmit={props.onSuccessfulSubmit}>
      <InputRow>
        <StackedInputCell>
          <label className={styles.milestoneName}>
            Payment Amount
          </label>

          <PaymentAmountField
            ref={_.partial(Form.captureElement, refForm, 'paymentLink')}
            required
            placeholder="$ 25,000"
            value={String(props.amount)}
            onChange={amount => props.onSetAmount(parseInt(amount, 10))} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            Payment Link
          </label>

          <div className={styles.textAreaContainer}>
            <TextArea
              placeholder="Please Copy/Paste the Quickbooks Payment Link"
              required
              rows={6}
              value={props.link}
              onChange={link => props.onSetLink(link)} />
          </div>
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <Button
            extraClassName={styles.button}
            type="submit">
            Create Milestone
          </Button>
        </StackedInputCell>
      </InputRow>
    </Form>
  );
};
