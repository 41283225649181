import * as React from 'react';
import _ from 'lodash';

import {
  IProjectSettings,
  IProjectType
} from '../dataService';
import {
  IOptionDef,
  Select
} from 'components/shared/forms/Select/Select';

import { useSaveProjectMutation } from './dataService';
import { SettingsSectionCard } from '../SettingsSectionCard/SettingsSectionCard';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { LogoField } from 'components/shared/LogoField/LogoField';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { ProjectNameField } from 'components/shared/forms/InputField/ProjectNameField';
import { Button } from 'components/shared/Button/Button';

import styles from './ProjectSettingsSection.module.css';



export interface IProjectSettingsSectionProps {
  projectExtId: string;
  settings: IProjectSettings;
  projectTypes: IProjectType[];
}



export const ProjectSettingsSection: React.FC<IProjectSettingsSectionProps> = props => {
  const refForm = React.useRef<Form>(null);
  const [encodedFile, setEncodedFile] = React.useState('');
  const [appName, setAppName] = React.useState('');
  const [projectTypeId, setProjectTypeId] = React.useState('');

  const [fnSaveProject, saveProjectTuple] = useSaveProjectMutation(props.projectExtId);

  const projectTypes = _.map(props.projectTypes, (projectType): IOptionDef => ({
    id: projectType.name,
    label: projectType.displayName
  }));

  React.useEffect(() => {
    setAppName(props.settings.displayName);
    setProjectTypeId(props.settings.projectType.name);
  }, [
    props.settings.logoUrl,
    props.settings.displayName,
    props.settings.projectType.name
  ]);



  async function handleSuccessfulSubmit() {
    const logoUrl = encodedFile || undefined;

    const result = await fnSaveProject({
      logoUrl,
      appName,
      projectTypeId
    });

    setEncodedFile('');

    return result;
  }


  return (
    <SettingsSectionCard
      title="Project Settings"
      description="Edit information associated with this project. Only Iron Forge users can edit project settings. Clients do not have the access to edit these settings. Please check with the project manager before editing.">
      <Form
        ref={refForm}
        onSuccessfulSubmit={handleSuccessfulSubmit}>
        <div className={styles.logoFieldContainer}>
          <LogoField
            disabled={saveProjectTuple.loading}
            logoUrl={encodedFile || props.settings.logoUrl}
            onChange={setEncodedFile} />
        </div>

        <InputRow>
          <StackedInputCell>
            <label className={styles.formText}>
              Client Name
            </label>

            <ProjectNameField
              autoComplete={false}
              disabled={saveProjectTuple.loading}
              ref={_.partial(Form.captureElement, refForm, 'clientName')}
              placeholder="e.g. Iron Forge"
              value={appName}
              onChange={setAppName} />
          </StackedInputCell>
        </InputRow>

        <InputRow>
          <StackedInputCell>
            <label className={styles.formText}>
              Project Type
            </label>

            <Select
              disabled={saveProjectTuple.loading}
              extraClassName={styles.dropdownBar}
              selectedId={projectTypeId}
              optionDefs={projectTypes}
              onChange={setProjectTypeId} />
          </StackedInputCell>
        </InputRow>

        <InputRow>
          <StackedInputCell>
            <Button
              disabled={saveProjectTuple.loading}
              extraClassName={styles.button}
              type="submit">
              Update Information
            </Button>
          </StackedInputCell>
        </InputRow>
      </Form>
    </SettingsSectionCard>
  );
};
