import * as React from 'react';
import * as firebaseUtil from 'util/firebase';
import * as dataService from './dataService';
import {
  GenericToolbar,
  ILinkDef
} from 'components/shared/GenericToolbar/GenericToolbar';
import { RouteComponentProps } from 'react-router';
import { routes } from 'routes';

import styles from './ProjectPickerToolbar.module.css';



export interface IProjectPickerToolbarProps extends RouteComponentProps { }

export const ProjectPickerToolbar: React.FC<IProjectPickerToolbarProps> = props => {
  const queryTuple = dataService.useProjectPickerToolbarQuery();
  const linkDefs: ILinkDef[] = [
    {
      to: routes.account.path(),
      displayName: "My Account"
    }
  ];

  if (queryTuple.data && queryTuple.data.isSuperAdmin) {
    linkDefs.unshift({
      to: routes.projectPicker.path(),
      displayName: "Project Picker"
    });
  }

  return (
    <GenericToolbar
      {...props}
      linkDefs={linkDefs}>
      <div className={styles.root}>
        <div
          onClick={firebaseUtil.logOut}
          className={styles.logoutButton}>
          <div className={styles.logoutText}>
            LOG OUT
          </div>
        </div>
      </div>
    </GenericToolbar>
  );
};
