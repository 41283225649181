import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as environmentUtil from 'util/environment';
import * as serviceWorker from './serviceWorker';
import * as firebaseUtil from 'util/firebase';
import { ApolloProvider } from '@apollo/react-hooks';
import * as apolloService from 'services/apollo';
import gql from 'graphql-tag';

import './index.css';



try {
  const environment = environmentUtil.init();
  firebaseUtil.init(environment);

  apolloService.init(environment, async () => {
    const user = firebaseUtil.getLoggedInUser();
    if (!user || user === 'NO_LOGIN_ATTEMPTED_YET') {
      return {};
    }

    const idToken = await user.getIdToken();
    console.log(idToken);

    __getAndPrintVersion();

    return Promise.resolve({ authorization: `Bearer ${idToken}` });
  });

  firebaseUtil.authStatusAsObservable()
    .subscribe(user => {
      if (user === 'NO_LOGIN_ATTEMPTED_YET') { return; }
      const key = user !== null ? user.uid : "";
      ReactDOM.render(
        <ApolloProvider client={apolloService.getClient()}>
          <App key={key} />
        </ApolloProvider>,
        document.getElementById('root')
      );
    });

} catch (err) {
  ReactDOM.render(
    <div>
      {_.map(JSON.parse(err.message), (value, key) => (
        <div key={key}>{key} {value}</div>
      ))}
    </div>,
    document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



async function __getAndPrintVersion() {
  const retVal = await apolloService.getClient().query({query: gql`query { version }`});
  if (retVal && retVal.data && retVal.data.version) {
    console.log(`API version: ${retVal.data.version}`);
  } else {
    console.log("Unable to retrieve API version.");
  }

  console.log(`Client version: ${environmentUtil.getVersion()}`);
}
