import _ from "lodash";

import {
  usePageTemplateQuery as useBaseQuery,
  useSaveNotificationMutation as useBaseSaveNotificationMutation,
  useMarkAllNotificationsAsReadMutation as useBaseMarkAllNotificationsAsReadMutation,
  PageTemplateDocument
} from "generated/graphqlTypes";



export interface INotificationData {
  id: string;
  title: string;
  description: string;
  projectExtId: string;
  projectLogoUrl: string;
  projectDescription: string;
  milestoneId: string;
  isRead: boolean;
}



export interface IPageTemplateQuery {
  isSuperAdmin: boolean;
  notifications: INotificationData[];
}



export function usePageTemplateQuery() {
  const queryTuple = useBaseQuery();

  const siteUser = queryTuple.data ? queryTuple.data.loggedInSiteUser : null;

  let data: IPageTemplateQuery | null = null;
  if (!queryTuple.loading && siteUser) {
    data = {
      isSuperAdmin: siteUser.isSuperAdmin,
      notifications: _.map(siteUser.notifications, (notification): INotificationData => ({
        id: notification.id,
        isRead: notification.isRead,
        description: notification.description,
        projectExtId: notification.auditEvent.project.extId,
        projectDescription: `${notification.auditEvent.project.displayName} - ${notification.auditEvent.project.projectType.displayName}`,
        projectLogoUrl: notification.auditEvent.project.logoUrl,
        milestoneId: notification.auditEvent.milestone?.id || '',
        title: notification.shortDescription
      }))
    };
  }

  return {
    ...queryTuple,
    data
  };
}



interface ISaveNotificationArgs {
  notificationId: string;
  isRead: boolean;
}

export function useSaveNotificationMutation() {
  const [fnRunMutation, mutationTuple] = useBaseSaveNotificationMutation({
    refetchQueries: [
      {
        query: PageTemplateDocument,
      }
    ],
    awaitRefetchQueries: true
  });

  const fnWrappedMutation = (args: ISaveNotificationArgs) => {
    return fnRunMutation({
      variables: {
        notificationInput: {
          isRead: args.isRead,
          notificationId: args.notificationId
        }
      }
    });
  };

  let data: string | null = null;
  if (mutationTuple.data && mutationTuple.data.saveNotification) {
    data = mutationTuple.data.saveNotification.id;
  }

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



interface ISaveAllNotificationArgs {
  isRead: boolean;
}

export function useSaveAllNotificationsMutation() {
  const [fnRunMutation, mutationTuple] = useBaseMarkAllNotificationsAsReadMutation({
    refetchQueries: [
      {
        query: PageTemplateDocument,
      }
    ],
    awaitRefetchQueries: true
  });

  const fnWrappedMutation = (args: ISaveAllNotificationArgs) => {
    return fnRunMutation({
      variables: {
        notificationInput: {
          isRead: args.isRead
        }
      }
    });
  };

  let data: boolean | null = null;
  if (mutationTuple.data && mutationTuple.data.markAllNotificationsAsRead) {
    data = mutationTuple.data.markAllNotificationsAsRead;
  }

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}
