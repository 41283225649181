import _ from "lodash";
import {
  useProjectPickerPageQuery as useBaseQuery,
  useCreateProjectMutation as useBaseCreateProjectMutation,
  ProjectPickerPageDocument,
  ProjectType
} from "generated/graphqlTypes";



export interface IProjectType {
  name: string;
  displayName: string;
}



export interface IProject {
  id: string;
  extId: string;
  displayName: string;
  logoUrl: string;
  projectType: IProjectType;
}



export interface IProjectPickerPageQuery {
  id: string;
  isSuperAdmin: boolean;
  projects: IProject[];
  projectTypes: ProjectType[];
}



export function useProjectPickerPageQuery(filterText: string) {
  const queryTuple = useBaseQuery();

  const siteUser = queryTuple.data ? queryTuple.data.loggedInSiteUser : null;
  const appMetaData = queryTuple.data ? queryTuple.data.appMetaData : null;

  let data: IProjectPickerPageQuery | null = null;
  if (!queryTuple.loading && siteUser && appMetaData) {
    data = {
      id: siteUser.id,
      isSuperAdmin: siteUser.isSuperAdmin,
      projects: siteUser.projects,
      projectTypes: appMetaData.projectTypes
    };
  }

  if (!_.isEmpty(filterText) && !_.isNil(data) && !_.isEmpty(data)) {
    data.projects = _.filter(data.projects, project => _.includes(
      _.toLower(project.displayName),
      _.toLower(filterText)
    ));
  }

  return {
    ...queryTuple,
    data
  };
}



export interface ICreateProjectReturn {
  project: IProject;
}



export interface ICreateProjectArgs {
  appName: string;
  dataUrl: string;
  projectType: string;
}



export function useCreateProjectMutation() {
  const [fnRunMutation, mutationTuple] = useBaseCreateProjectMutation({
    refetchQueries: [
      {
        query: ProjectPickerPageDocument
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;
  if (mutationTuple.data && mutationTuple.data.createProject) {
    data = mutationTuple.data.createProject.id;
  }

  const fnWrappedMutation = (args: ICreateProjectArgs) => {
    return fnRunMutation({
      variables: {
        projectInput: {
          appDisplayName: args.appName,
          encodedFileInput: {
            dataUrl: args.dataUrl
          },
          projectType: args.projectType
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}
