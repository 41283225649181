import * as React from 'react';
import _ from 'lodash';
import { LogCard } from './LogCard/LogCard';
import { ILogData } from 'components/pages/MilestonePage/dataService';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { NoteField } from 'components/shared/forms/InputField/NoteField';
import { Button } from 'components/shared/Button/Button';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';

import styles from './LogTab.module.css';

import imgDocument from 'assets/images/emptyLogs.svg';
import { ProfilePicture } from 'components/shared/ProfilePicture/ProfilePicture';



export interface ILogTabProps {
  logs: ILogData[];
  currUserProfilePicUrl: string;
  isAccepted: boolean;

  onSendNote: (note: string) => void;
}

export const LogTab: React.FC<ILogTabProps> = props => {
  const [note, setNote] = React.useState('');
  const refForm = React.useRef<Form>(null);
  const hasLogs = !_.isEmpty(props.logs);



  function handleSuccesfulSubmit() {
    if (!note) {
      return;
    }

    props.onSendNote(note);
    setNote('');
  }

  return (
    <div>
      <div className={styles.logs}>
        {hasLogs && _.map(props.logs, (log, index) => (
          <div
            className={styles.logContainer}
            key={index}>
            <LogCard
              date={log.time}
              description={log.description}
              profilePicUrl={log.profilePictureUrl}
              name={log.userName} />
          </div>
        ))}

        {!hasLogs && (
          <EmptyLogTab />
        )}
      </div>

      {!props.isAccepted && (
        <Form
          extraClassName={styles.newLogContainer}
          ref={refForm}
          onSuccessfulSubmit={handleSuccesfulSubmit}>
          <InputRow>
            <ProfilePicture profilePictureUrl={props.currUserProfilePicUrl} />

            <NoteField
              extraClassName={styles.inputField}
              autoComplete={false}
              ref={_.partial(Form.captureElement, refForm, 'note')}
              placeholder="Add a note..."
              value={note}
              onChange={updatedNote => setNote(updatedNote)} />

            <Button type='submit'>
              Send
            </Button>
          </InputRow>
        </Form>
      )}
    </div>
  );
};



const EmptyLogTab: React.FC = () => (
  <div className={styles.emptyRoot}>
    <img
      src={imgDocument}
      className={styles.emptyTitle}
      alt="no user profile" />

    <h1 className={styles.emptyTitle}>
      No Logs or Notes
    </h1>

    <div className={styles.emptyDesc}>
      Create a note below by writing something
    </div>
  </div>
);
