import * as React from 'react';

import styles from './ProjectDropdownCard.module.css';

import imgDropdown from 'assets/images/dropDown.svg';



export interface IProjectDropdownCardProps {
  onClick?: () => void;
  image: string;
  displayName: string;
  projectType: string;
  isFirst?: boolean;
}



export const ProjectDropdownCard: React.FC<IProjectDropdownCardProps> = props => (
  <div
    onClick={props.onClick}
    className={styles.root}>
    <div className={styles.content}>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={props.image}
          alt="Project profile" />
      </div>

      <div className={styles.text}>
        <div className={styles.projectName}>
          {props.displayName}
        </div>

        <div className={styles.projectType}>
          {props.projectType}
        </div>
      </div>
    </div>

    <div className={styles.dropdown}>
      {props.isFirst && (
        <img
          className={styles.dropdownImage}
          src={imgDropdown}
          alt="Click to access the drop down menu" />
      )}
    </div>
  </div>
);
