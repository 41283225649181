import {genInputFieldComponent} from "./InputFieldFactory";



export const EmailField = genInputFieldComponent('email', (value, required) => {
  const maxLength = 256;
  const trimmedValue = value.trim();

  if (required && trimmedValue.length === 0) {
    return "Required.";
  } else if (trimmedValue.length > maxLength) {
    return `Email must be fewer than ${maxLength} characters in length.`;
  } else {
    return "";
  }
});
