import * as React from 'react';

import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';

import { CredentialForm } from 'components/pages/CredentialPage/modals/CredentialForm/CredentialForm';
import { useCreateCredentialMutation } from './dataService';

import styles from './AddCredentialModal.module.css';



export interface IAddCredentialModalProps extends IBaseModalTemplateProps {
  projectExtId: string;
}



export const AddCredentialModal: React.FC<IAddCredentialModalProps> = props => {
  const [fnCreateCredential] = useCreateCredentialMutation(props.projectExtId);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [serviceName, setServiceName] = React.useState('');
  const [serviceUrl, setServiceUrl] = React.useState('');



  function closeForm() {
    props.onClose();

    setUsername('');
    setPassword('');
    setServiceName('');
    setServiceUrl('');
  }



  function handleSubmit() {
    return fnCreateCredential(
      serviceName,
      serviceUrl,
      username,
      password
    )
      .then(() => {
        closeForm();
      });
  }



  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={closeForm}>
      <div className={styles.root}>
        <h1 className={styles.title}>
          Add New Credential
        </h1>

        <p className={styles.desc}>
          Use the credentials supplied to you to access your Iron Forge account. Not sure what your account is? Contact us at info@ironforge.co.
        </p>

        <CredentialForm
          submitButtonLabel="Add Credential"
          username={username}
          password={password}
          serviceName={serviceName}
          serviceUrl={serviceUrl}
          onChangeUsername={setUsername}
          onChangePassword={setPassword}
          onChangeServiceName={setServiceName}
          onChangeServiceUrl={setServiceUrl}
          onSubmit={handleSubmit} />
      </div>
    </BaseModalTemplate>
  );
};
