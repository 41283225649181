import React from 'react';
import {
  BrowserRouter,
  Switch,
  withRouter,
  Route
} from 'react-router-dom';
import { routes } from './routes';
import { HomePage } from 'components/pages/HomePage/HomePage';
import { AuthenticationPage } from 'components/pages/AuthenticationPage/AuthenticationPage';
import { CredentialPage } from 'components/pages/CredentialPage/CredentialPage';
import { AttachmentsPage } from 'components/pages/AttachmentsPage/AttachmentsPage';
import { SettingsPage } from 'components/pages/SettingsPage/SettingsPage';
import { MilestonePage } from 'components/pages/MilestonePage/MilestonePage';
import { ProjectPickerPage } from 'components/pages/ProjectPickerPage/ProjectPickerPage';
import { AccountPage } from 'components/pages/AccountPage/AccountPage';



const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={routes.home.rawPath}
          component={withRouter(HomePage)} />

        <Route
          exact
          path={routes.auth.rawPath}
          component={withRouter(AuthenticationPage)} />

        <Route
          exact
          path={routes.milestones.rawPath}
          component={withRouter(MilestonePage)} />

        <Route
          exact
          path={routes.credentials.rawPath}
          component={withRouter(CredentialPage)} />

        <Route
          exact
          path={routes.attachments.rawPath}
          component={withRouter(AttachmentsPage)} />

        <Route
          exact
          path={routes.settings.rawPath}
          component={withRouter(SettingsPage)} />

        <Route
          exact
          path={routes.account.rawPath}
          component={withRouter(AccountPage)} />

        <Route
          exact
          path={routes.projectPicker.rawPath}
          component={withRouter(ProjectPickerPage)} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
