import * as React from 'react';
import _ from 'lodash';
import {
  NavLink,
  RouteComponentProps
} from 'react-router-dom';
import { routes } from 'routes';

import styles from './GenericToolbar.module.css';

import imgLogo from 'assets/images/ironForgeLogo.png';



export interface ILinkDef {
  to: string;
  displayName: string;
}



export interface IGenericToolbarProps extends RouteComponentProps {
  linkDefs?: ILinkDef[];
}



export const GenericToolbar: React.FC<IGenericToolbarProps> = props => (
  <div className={styles.root}>
    <div className={styles.logoContainer}>
      <NavLink to={routes.home.path}>
        <img
          alt="Iron Forge Logo"
          src={imgLogo} />
      </NavLink>
    </div>

    <nav className={styles.linksContainer}>
      {_.map(props.linkDefs, route => (
        <div
          key={route.to}
          className={styles.navLinkContainer}>
          <NavLink
            activeClassName={styles.activeLink}
            className={styles.link}
            to={route.to}>
            {route.displayName}
          </NavLink>
        </div>
      ))}
    </nav>

    <div className={styles.content}>
      {props.children}
    </div>
  </div>
);
