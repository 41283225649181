import React from "react";



export function useOnClickOutsideRef<T extends HTMLElement>(onClickAway: () => void, resubscribe: any) {
  const refContainer = React.useRef<T | null>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const elContainer = refContainer.current;
      if (elContainer && event.target && !elContainer.contains(event.target as Node)) {
        onClickAway();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [resubscribe]);

  return refContainer;
}
