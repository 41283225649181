import {genInputFieldComponent} from "./InputFieldFactory";

export const PasswordField = genInputFieldComponent('password', (value, required) => {
  const trimmedValue = value.trim();

  if (required && trimmedValue.length === 0) {
    return "Required.";
  }
  return "";
});
