import * as React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { BaseModalTemplate, IBaseModalTemplateProps } from 'components/shared/BaseModalTemplate/BaseModalTemplate';
import { Button } from 'components/shared/Button/Button';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { ActionNameField } from 'components/shared/forms/InputField/ActionNameField';
import { TextArea } from 'components/shared/forms/TextArea/TextArea';

import styles from './AddActionModal.module.css';


interface IFormData {
  actionItemName: string;
  actionItemDesc: string;
}



export interface IAddActionModalProps extends IBaseModalTemplateProps {
  onCreateAction: (displayName: string, description: string) => void;
}



export const AddActionModal: React.FC<IAddActionModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const [formData, setFormData] = React.useState<IFormData>({
    actionItemName: "",
    actionItemDesc: ""
  });



  function deleteFormInfo() {
    setFormData(update(formData, {
      actionItemDesc: { $set: "" },
      actionItemName: { $set: "" }
    }));
  }



  function closeForm() {
    props.onClose();
    deleteFormInfo();
  }



  function handleSuccessfulSubmit() {
    props.onCreateAction(formData.actionItemName, formData.actionItemDesc);
    closeForm();
  }



  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={closeForm}>
      <div className={styles.root}>
        <h1 className={styles.title}>
          Create a New Action Item
        </h1>

        <p className={styles.desc}>
          Fill in the below information to create a new action item.
        </p>

        <Form
          ref={refForm}
          onSuccessfulSubmit={handleSuccessfulSubmit}>
          <InputRow>
            <StackedInputCell>
              <label className={styles.formText}>
                Action Item Name
              </label>

              <ActionNameField
                ref={_.partial(Form.captureElement, refForm, 'actionName')}
                required
                placeholder="e.g. Upload Logo + Branding Documents"
                value={formData.actionItemName}
                onChange={name =>
                  setFormData(update(formData, {
                    actionItemName: { $set: name }
                  }))
                } />
            </StackedInputCell>
          </InputRow>

          <InputRow>
            <StackedInputCell>
              <label className={styles.formText}>
                Action Item Description
              </label>

              <div className={styles.textAreaContainer}>
                <TextArea
                  rows={6}
                  required
                  placeholder="e.g. Please upload any logos or branding guidelines to the attachments section. Once completed, mark this action item as complete. "
                  value={formData.actionItemDesc}
                  onChange={desc =>
                    setFormData(update(formData, {
                      actionItemDesc: { $set: desc }
                    }))
                  } />
              </div>
            </StackedInputCell>
          </InputRow>

          <InputRow>
            <StackedInputCell>
              <Button
                extraClassName={styles.button}
                type="submit">
                Create New Action
              </Button>
            </StackedInputCell>
          </InputRow>
        </Form>
      </div>
    </BaseModalTemplate>
  );
};
