export const routes = {
  home: {
    rawPath: '/',
    path: () => '/'
  },
  auth: {
    rawPath: '/auth/:pageState',
    path: (pageState: 'login' | 'signUp' | 'error', inviteCode?: string, projectName?: string) =>
      `/auth/${pageState}?${projectName ? `projectName=${projectName}` : ''}${inviteCode ? `&inviteCode=${inviteCode}` : ''}`
  },
  milestones: {
    rawPath: '/project/:projectExtId/milestones/:milestoneId?',
    path: (projectExtId: string, milestoneId?: string) =>
      `/project/${projectExtId}/milestones${milestoneId ? `/${milestoneId}` : ''}`
  },
  credentials: {
    rawPath: '/project/:projectExtId/credentials',
    path: (projectExtId: string) =>
      `/project/${projectExtId}/credentials`
  },
  attachments: {
    rawPath: '/project/:projectExtId/attachments',
    path: (projectExtId: string) =>
      `/project/${projectExtId}/attachments`
  },
  settings: {
    rawPath: '/project/:projectExtId/settings',
    path: (projectExtId: string) =>
      `/project/${projectExtId}/settings`
  },
  account: {
    rawPath: '/account',
    path: () =>
      `/account`
  },
  projectPicker: {
    rawPath: '/projectPicker',
    path: () =>
      `/projectPicker`
  }
};
