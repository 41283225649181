import * as React from 'react';

import * as moneyUtil from 'util/moneyUtil';
import * as dataService from '../dataService';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Button } from 'components/shared/Button/Button';

import styles from './PaymentMilestone.module.css';

import imgReorder from 'assets/images/whiteDragIcon.svg';
import imgBigCheckmark from 'assets/images/bigCheckmark.svg';



export interface IPaymentMilestoneProps {
  milestone: dataService.IPaymentMilestoneData;
  draggableProps?: DraggableProvidedDragHandleProps;
  userAuthorizedAsAdmin: boolean;
  requestOffsetTopObservable: Observable<string>;

  onRespondOffsetTop: (offsetTop: number) => void;
  onPayment: () => void;
}



export const PaymentMilestone: React.FC<IPaymentMilestoneProps> = props => {
  const refRoot = React.useRef<HTMLDivElement>(null);
  const milestoneIsAccepted = props.milestone.status.name === 'accepted';
  const userIsAdmin = props.userAuthorizedAsAdmin;



  React.useEffect(() => {
    const subscription = props.requestOffsetTopObservable
      .pipe(filter(id => id === props.milestone.id))
      .subscribe(() => refRoot.current && props.onRespondOffsetTop(refRoot.current.offsetTop));

    return () => subscription.unsubscribe();
  }, [props.requestOffsetTopObservable, props.onRespondOffsetTop, props.milestone, refRoot]);



  return (
    <div
      ref={refRoot}
      className={styles.root}>
      <div className={styles.infoBody}>
        <div className={styles.infoText}>
          PAYMENT MILESTONE {props.milestone.rank + 1}
        </div>

        {milestoneIsAccepted && (
          <div className={styles.paymentText}>
            Payment Completed!
          </div>
        )}

        {!milestoneIsAccepted && (
          <div className={styles.paymentText}>
            ${moneyUtil.cents2Dollars(props.milestone.amountCents)}
          </div>
        )}
      </div>

      {!milestoneIsAccepted && userIsAdmin && (
        <div className={styles.pendingMilestone}>
          <div>
            <div
              {...props.draggableProps}
              className={styles.dragContainer}>
              <img
                className={styles.dragImg}
                src={imgReorder}
                alt="reorder" />

              <div className={styles.dragText}>
                Drag to Reorder
              </div>
            </div>
          </div>

          <Button
            withoutStyle
            onClick={props.onPayment}
            extraClassName={styles.button}>
            Mark Milestone As Paid
          </Button>
        </div>
      )}

      {milestoneIsAccepted && userIsAdmin && (
        <div>
          <img
            alt="payment complete"
            src={imgBigCheckmark} />
        </div>
      )}

      {!userIsAdmin && !milestoneIsAccepted && (
        <Button
          withoutStyle
          onClick={() => window.location.assign(props.milestone.paymentUrl)}
          extraClassName={styles.button}>
          Pay Milestone
        </Button>
      )}
    </div>
  );
};
