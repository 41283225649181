import * as React from 'react';
import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';
import { Button } from 'components/shared/Button/Button';

import styles from './DeleteProjectModal.module.css';



export interface IDeleteProjectModalProps extends IBaseModalTemplateProps {
  onSubmit: () => void;
}

export const DeleteProjectModal: React.FC<IDeleteProjectModalProps> = props => (
  <BaseModalTemplate
    isVisible={props.isVisible}
    onClose={props.onClose}>
    <div className={styles.root}>
      <h1 className={styles.title}>
        Delete Project Milestone
      </h1>

      <p className={styles.description}>
        Deleting this project will delete all associated actions. This cannot be undone. Logs and attachments will still be saved.
      </p>

      <Button onClick={props.onSubmit}>
        Delete Milestone
      </Button>

      <div
        onClick={props.onClose}
        className={styles.cancelButton}>
        No, Cancel
      </div>
    </div>
  </BaseModalTemplate>
);
