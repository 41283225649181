import { useHomePageQuery as useBaseQuery } from "generated/graphqlTypes";
import { IProject } from "../ProjectPickerPage/dataService";



export interface IHomePageQuery {
  isSuperAdmin: boolean;
  projects: IProject[];
}



export function useHomePageQuery() {
  const queryTuple = useBaseQuery({
    fetchPolicy: "no-cache"
  });

  const siteUser = queryTuple.data && queryTuple.data.loggedInSiteUser ?
    queryTuple.data.loggedInSiteUser :
    null;

  let data: IHomePageQuery | null = null;
  if (!queryTuple.loading && siteUser) {
    data = {
      isSuperAdmin: siteUser.isSuperAdmin,
      projects: siteUser.projects,
    };
  }

  return {
    ...queryTuple,
    data
  };
}
