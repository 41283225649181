import React from 'react';
import _ from 'lodash';
import {
  GenericToolbar,
  ILinkDef
} from 'components/shared/GenericToolbar/GenericToolbar';
import * as firebaseUtil from 'util/firebase';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from 'routes';
import { useAppToolbarQuery } from './dataService';
import { DropdownMenu } from './DropdownMenu/DropdownMenu';
import { Button } from 'components/shared/Button/Button';

import styles from './AppToolbar.module.css';

import imgNotification from 'assets/images/notification.svg';



export interface IAppToolbarProps extends RouteComponentProps<{
  projectExtId?: string;
}> {
  hasUnreadNotifications: boolean;
  onClickNotification: () => void;
}



export const AppToolbar: React.FC<IAppToolbarProps> = props => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const queryTuple = useAppToolbarQuery();
  const projectId = props.match.params.projectExtId ? props.match.params.projectExtId : null;
  let linkDefs: ILinkDef[] = [];



  function handleClickDropdown() {
    setIsDropdownVisible(!isDropdownVisible);
  }



  function handleClickAway() {
    setIsDropdownVisible(false);
  }



  function handleLogout() {
    firebaseUtil.logOut();
  }



  const isSuperUser = queryTuple.data ? queryTuple.data.isSuperAdmin : false;
  const projects = queryTuple.data ? queryTuple.data.projects : [];

  if (projectId !== null) {
    linkDefs = [
      {
        to: routes.milestones.path(projectId),
        displayName: "Milestones"
      },
      {
        to: routes.credentials.path(projectId),
        displayName: "Project Credentials"
      },
      {
        to: routes.attachments.path(projectId),
        displayName: "All Attachments"
      }
    ];

    if (isSuperUser) {
      linkDefs = _.concat(linkDefs, {
        to: routes.settings.path(projectId),
        displayName: "Settings"
      });
    }
  }



  return (
    <GenericToolbar
      {...props}
      linkDefs={linkDefs}>
      <div className={styles.root}>
        {projectId !== null && (
          <DropdownMenu
            currExtId={projectId}
            projects={projects}
            onLogout={handleLogout}
            onClick={handleClickDropdown}
            onClickAway={handleClickAway}
            isVisible={isDropdownVisible}
            isSuperUser={isSuperUser} />
        )}

        <div className={styles.notificationAnchor}>
          <Button
            withoutStyle
            onClick={props.onClickNotification}
            extraClassName={styles.notifications}>
            {props.hasUnreadNotifications && (
              <div className={styles.unreadNotification} />
            )}

            <img
              className={styles.notificationBell}
              src={imgNotification}
              alt="Notifications" />
          </Button>
        </div>
      </div>
    </GenericToolbar>
  );
};
