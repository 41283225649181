import * as React from 'react';
import _ from 'lodash';

import { ICredential } from '../dataService';
import { Button } from 'components/shared/Button/Button';

import styles from './CredentialTable.module.css';



export interface ICredentialTableProps {
  credentials: ICredential[];

  onClickCopyPassword: (password: string) => void;
  onClickEdit: (credential: ICredential) => void;
  onClickDelete: (credentialId: string) => void;
}



export const CredentialTable: React.FC<ICredentialTableProps> = props => {
  return (
    <table className={styles.root}>
      <thead className={styles.tableHeader}>
        <tr>
          <th>Website/Service Name</th>

          <th>Username</th>

          <th>Password</th>

          <th>URL</th>

          <th>Actions</th>
        </tr>
      </thead>

      <tbody className={styles.tableBody}>
        {
          _.map(props.credentials, credential => (
            <tr key={credential.id}>
              <td>{credential.serviceName}</td>

              <td>{credential.username}</td>

              <td>
                <Button
                  withoutStyle
                  onClick={() => props.onClickCopyPassword(credential.password)}>
                  Click to copy
                </Button>
              </td>

              <td>{credential.serviceUrl}</td>

              <td>
                <Button
                  extraClassName={styles.editButton}
                  withoutStyle
                  onClick={() => props.onClickEdit(credential)}>
                  Edit
                </Button>

                <Button
                  withoutStyle
                  extraClassName={styles.deleteButton}
                  onClick={() => props.onClickDelete(credential.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};
