import * as React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';

import styles from './ProjectCard.module.css';

import imgPickProject from 'assets/images/pickProject.svg';



export interface IProjectCardProps {
  id: string;
  extId: string;
  projectType: string;
  displayName: string;
  logoUrl: string;
}



export const ProjectCard: React.FC<IProjectCardProps> = props => (
  <div className={styles.root}>
    <img
      className={styles.logo}
      src={props.logoUrl}
      alt="Logo of the current project" />

    <div className={styles.bottombar}>
      <div>
        <div>{props.displayName}</div>
        <div className={styles.projectDesc}>{props.projectType}</div>
      </div>

      <div>
        <Link to={routes.milestones.path(props.extId)}>
          <img
            alt="Go to project"
            src={imgPickProject} />
        </Link>
      </div>
    </div>
  </div>
);
