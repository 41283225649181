import { useProjectPickerToolbarQuery as useBaseQuery} from 'generated/graphqlTypes';


export interface IProjectPickerToolbarQuery {
  id: string;
  isSuperAdmin: boolean;
}

export function useProjectPickerToolbarQuery() {
  const queryTuple = useBaseQuery();

  const siteUser = queryTuple.data ? queryTuple.data.loggedInSiteUser : '';

  let data: IProjectPickerToolbarQuery | null = null;
  if (!queryTuple.loading && siteUser) {
    data = {
      id: siteUser.id,
      isSuperAdmin: siteUser.isSuperAdmin
    };
  }

  return {
    ...queryTuple,
    data
  };
}
