import * as React from 'react';

import styles from './Button.module.css';



export interface IButtonProps {
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  extraClassName?: string;
  withoutStyle?: boolean;

  onClick?: () => void;
}

export const Button: React.FC<IButtonProps> = props => {
  const className = `${props.withoutStyle ? styles.noButton : styles.root} ${props.extraClassName || ''}`;
  return (
    <button
      className={className}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};
