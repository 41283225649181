import { genInputFieldComponent } from "./InputFieldFactory";



export const PaymentAmountField = genInputFieldComponent("number", (value, required) => {
    const trimmedValue = value.trim();

    if (required && trimmedValue.length === 0) {
      return "Required.";
    }
    return "";
});
