import * as React from 'react';
import {
  IInputFieldProps,
  InputField
} from 'components/shared/forms/InputField/InputField';



export function genInputFieldComponent(
  type: IInputFieldProps['type'],
  fnValidate: IInputFieldProps['fnValidate']
) {
  return class extends React.Component<Omit<IInputFieldProps, 'type' | 'fnValidate'>> {

    private __refInputField = React.createRef<InputField>();



    public render = () => {
      return (
        <InputField
          ref={this.__refInputField}
          {...this.props}
          type={type}
          fnValidate={fnValidate} />
      );
    };



    public validate = (immediateValue?: string) => {
      const elInputField = this.__refInputField.current;
      if (elInputField) {
        return elInputField.validate(immediateValue);
      }

      return false;
    };

  };
}
