import * as React from 'react';
import _ from 'lodash';

import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { CredentialNameField } from 'components/shared/forms/InputField/CredentialNameField';
import { UsernameField } from 'components/shared/forms/InputField/UsernameField';
import { PasswordField } from 'components/shared/forms/InputField/PasswordField';
import { UrlField } from 'components/shared/forms/InputField/UrlField';
import { Button } from 'components/shared/Button/Button';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';

import styles from './CredentialForm.module.css';



export interface ICredentialFormProps {
  submitButtonLabel: string;

  username: string;
  password: string;
  serviceName: string;
  serviceUrl: string;

  onChangeUsername: (username: string) => void;
  onChangePassword: (password: string) => void;
  onChangeServiceName: (serviceName: string) => void;
  onChangeServiceUrl: (serviceUrl: string) => void;

  onSubmit: () => void;
}



export const CredentialForm: React.FC<ICredentialFormProps> = props => {
  const refForm = React.useRef<Form>(null);



  return (
    <Form
      ref={refForm}
      onSuccessfulSubmit={props.onSubmit}>
      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            Website Name
          </label>

          <CredentialNameField
            ref={_.partial(Form.captureElement, refForm, 'serviceName')}
            required
            value={props.serviceName}
            placeholder="e.g. Amazon Web Services"
            onChange={props.onChangeServiceName} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            Username
          </label>

          <UsernameField
            ref={_.partial(Form.captureElement, refForm, 'username')}
            required
            autoComplete={false}
            value={props.username}
            placeholder="e.g. ironforge"
            onChange={props.onChangeUsername} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            Password
          </label>

          <PasswordField
            ref={_.partial(Form.captureElement, refForm, 'password')}
            required
            autoComplete={false}
            value={props.password}
            placeholder="**********"
            onChange={props.onChangePassword} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            URL
          </label>

          <UrlField
            ref={_.partial(Form.captureElement, refForm, 'serviceUrl')}
            required
            value={props.serviceUrl}
            placeholder="https://www.ironforge.co"
            onChange={props.onChangeServiceUrl} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <Button
            extraClassName={styles.button}
            type="submit">
            {props.submitButtonLabel}
          </Button>
        </StackedInputCell>
      </InputRow>
    </Form>
  );
};
