import * as _ from 'lodash';
import * as React from 'react';



export interface IOptionDef {
  id: string;
  label: string;
}

export interface ISelectProps {
  disabled?: boolean;
  optionDefs: IOptionDef[];
  selectedId: string;
  extraClassName?: string;

  onChange: (selectedOptionId: string) => void;
}

export const Select: React.FC<ISelectProps> = props => (
  <select
    className={props.extraClassName}
    disabled={props.disabled}
    value={props.selectedId}
    onChange={ev => props.onChange(ev.target.value)}>
    {_.map(props.optionDefs, def => (
      <option
        key={def.id}
        value={def.id}>
        {def.label}
      </option>
    ))}
  </select>
);
