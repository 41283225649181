import * as React from 'react';

import styles from './NewProjectCard.module.css';

import imgCreateNewProject from 'assets/images/createNewProject.svg';



export interface INewProjectProps {
  onClick: () => void;
}

export const NewProjectCard: React.FC<INewProjectProps> = props => (
  <div
    onClick={props.onClick}
    className={styles.root}>
    <img
      className={styles.newProjectLogo}
      src={imgCreateNewProject}
      alt="Logo of the new project" />

    <div>
      <div className={styles.text}>
        Create a New Project
      </div>

      <div className={styles.description}>
        Click here to create a new client project
      </div>
    </div>
  </div>
);
