import {
  Schema, ValidationError,
  Validator
} from 'jsonschema';
import * as _ from 'lodash';



export const nonEmptyStringSchema: Schema = {
  type: 'string',
  minLength: 1
};



export function checkAgainstSchema(value: any, schema: Schema, errors: ValidationError[]) {
  const v = new Validator();
  const result = v.validate(value, schema);

  _.forEach(result.errors, err => errors.push(err));

  return _.isEmpty(result.errors);
}



export function validateArgs<TArgs>(funcName: string, schema: Schema, args: TArgs) {
  const errors: ValidationError[] = [];
  if (!checkAgainstSchema(args, schema, errors)) {
    console.error(funcName, errors);
    throw new Error('INVALID_ARGS');
  }
}
