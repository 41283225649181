import * as React from 'react';
import _ from 'lodash';
import {
  IActionCardData,
  ISaveActionArgs,
  IMilestoneStatusType,
} from 'components/pages/MilestonePage/dataService';
import { ActionCard } from 'components/pages/MilestonePage/ProjectMilestone/ProjectTabs/YourActionsTab/ActionCard/ActionCard';
import { BaseTabContent } from 'components/pages/MilestonePage/ProjectMilestone/ProjectTabs/BaseTabContent/BaseTabContent';

import styles from './YourActionsTab.module.css';

import imgSmallCheck from 'assets/images/smallCheckbox.svg';



export interface IYourActionsTabProps {
  actions: IActionCardData[];
  userAuthorizedAsAdmin: boolean;
  milestoneStatus: IMilestoneStatusType;

  onClick: () => void;
  onDeleteAction: (actionId: string) => void;
  onSaveAction: (input: ISaveActionArgs) => void;
}

export const YourActionsTab: React.FC<IYourActionsTabProps> = props => {
  const actions = _.sortBy(props.actions, 'metaCreated');

  return (
    <BaseTabContent
      disableAddButton={!props.userAuthorizedAsAdmin || (props.userAuthorizedAsAdmin && props.milestoneStatus.name === 'accepted')}
      emptyContentDef={__emptyContentInfo}
      showEmptyCase={_.isEmpty(props.actions)}
      onClick={props.onClick}>
      {_.map(actions, action => (
        <div
          key={action.id}
          className={styles.actionContainer}>
          <ActionCard
            milestoneStatus={props.milestoneStatus}
            action={action}
            userAuthorizedAsAdmin={props.userAuthorizedAsAdmin}
            onCancel={() => props.onDeleteAction(action.id)}
            onUserSubmit={props.onSaveAction}/>
        </div>
      ))}
    </BaseTabContent>
  );
};



const __emptyContentInfo = {
  picture: imgSmallCheck,
  title: "No Action Items Yet",
  desc: "Tap the button below to add an action item"
};
