import * as React from 'react';
import _ from 'lodash';
import * as dataService from 'components/pages/HomePage/dataService';
import {
  RouteComponentProps,
  Redirect
} from 'react-router';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { routes } from 'routes';
import { PageTemplate } from '../PageTemplate/PageTemplate';



export interface IHomePageProps extends RouteComponentProps { }

export const HomePage: React.FC<IHomePageProps> = props => {
  const queryTuple = dataService.useHomePageQuery();
  const data = queryTuple.data ? queryTuple.data : null;
  const projects = data ? data.projects : [];

  let screenType;
  if (data && data.isSuperAdmin) {
    screenType = 'projectPicker';
  } else if (data && !_.isEmpty(projects) && !data.isSuperAdmin) {
    screenType = 'milestones';
  } else {
    screenType = 'empty';
  }

  return (
    <>
      <LoadingOverlay
        isVisible={queryTuple.loading}
        text="Loading" />

      <PageTemplate
        requiresUser
        {...props}>
        {screenType === 'projectPicker' && (
          <Redirect to={routes.projectPicker.path()} />
        )}

        {screenType === 'milestones' && (
          <Redirect to={routes.milestones.path(projects[0].extId)} />
        )}

        {screenType === 'empty' && !queryTuple.loading && (
          <div>
            Couldn't find any projects
          </div>
        )}
      </PageTemplate>
    </>
  );
};
