import * as React from 'react';
import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';
import { Button } from 'components/shared/Button/Button';

import styles from './SubmitMilestoneModal.module.css';



export interface ISubmitMilestoneModal extends IBaseModalTemplateProps {
  onSubmit: () => void;
}

export const SubmitMilestoneModal: React.FC<ISubmitMilestoneModal> = props => (
  <BaseModalTemplate
    isVisible={props.isVisible}
    onClose={props.onClose}>
    <div className={styles.root}>
      <h1 className={styles.title}>
        Submit Milestone
      </h1>

      <p className={styles.description}>
        Submitting this milestone verifies that you are satisfied with the progress of this milestone. This cannot be reverted.
      </p>

      <Button onClick={() => {
        props.onSubmit();
        props.onClose();
      }}>
        Submit Milestone
      </Button>

      <div
        onClick={props.onClose}
        className={styles.cancelButton}>
        No, Cancel
      </div>
    </div>
  </BaseModalTemplate>
);
