import * as React from 'react';
import _ from 'lodash';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { EmailField } from 'components/shared/forms/InputField/EmailField';
import { PasswordField } from 'components/shared/forms/InputField/PasswordField';
import { Button } from 'components/shared/Button/Button';

import styles from './AuthenticationForm.module.css';



export interface IAuthenticationFormProps {
  email: string;
  password: string;
  passwordResetSent: boolean;
  formDisabled: boolean;

  onSuccessfulSubmit: () => void;
  onForgotPassword: () => void;
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
}

export const AuthenticationForm: React.FC<IAuthenticationFormProps> = props => {
  const refForm = React.useRef<Form>(null);

  return (
    <Form
      extraClassName={styles.form}
      ref={refForm}
      onSuccessfulSubmit={props.onSuccessfulSubmit}>
      <InputRow>
        <StackedInputCell>
          <label className={styles.inputLabel}>
            Email Address
          </label>

          <EmailField
            ref={_.partial(Form.captureElement, refForm, 'emailAddress')}
            required
            value={props.email}
            onChange={props.onChangeEmail} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.inputLabel}>
            Password
          </label>

          <PasswordField
            ref={_.partial(Form.captureElement, refForm, 'password')}
            required
            value={props.password}
            onChange={props.onChangePassword} />
        </StackedInputCell>
      </InputRow>

      <Button
        disabled={props.formDisabled}
        type="submit">
        Log In
      </Button>

      {!props.passwordResetSent && (
        <Button
          withoutStyle
          extraClassName={styles.forgotPassword}
          onClick={props.onForgotPassword}>
          Forgot Your Password?
        </Button>
      )}

      {props.passwordResetSent && (
        <div className={styles.forgotPassword}>
          Password Reset Sent
        </div>
      )}
    </Form>
  );
};
