import * as React from 'react';
import { Button } from '../Button/Button';

import styles from './BaseModalTemplate.module.css';

import imgCancelButton from 'assets/images/cancelButton.svg';



export interface IBaseModalTemplateProps {
  isVisible: boolean;

  onClose: () => void;
}

export const BaseModalTemplate: React.FC<IBaseModalTemplateProps> = props => {
  const refContainer = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    function handleWheel(event: MouseEvent) {
      const elContainer = refContainer.current;
      if (elContainer && event.target && props.isVisible) {
        event.preventDefault();
      }
    }

    document.addEventListener('wheel', handleWheel, {passive: false});
    return () => document.removeEventListener('wheel', handleWheel);
  }, [props]);

  return (
    <div
      ref={refContainer}
      className={`${styles.root} ${props.isVisible ? styles.visible : styles.hidden}`}>
      <div
        className={styles.backdrop}
        onClick={props.onClose} />

      <div className={styles.modal}>
        <div className={styles.cancelButtonAnchor}>
          <Button
            extraClassName={styles.cancel}
            onClick={props.onClose}
            withoutStyle>
            <img
              alt="cancel"
              src={imgCancelButton} />
          </Button>
        </div>

        {props.children}
      </div>
    </div>
  );
};
