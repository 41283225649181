import * as React from 'react';

import styles from './AccessUserRow.module.css';



export interface IAccessUserRowProps {
  email: string;
  pictureUrl: string;
  title: string;
}



export const AccessUserRow: React.FC<IAccessUserRowProps> = props => {
  return (
    <div className={styles.root}>
      <img
        className={styles.profilePicture}
        src={props.pictureUrl}
        alt={`User ${props.title}`} />

      <div className={styles.userInfo}>
        <h4>{props.title}</h4>

        <span>
          {props.email}
        </span>
      </div>
    </div>
  );
};
