import * as React from 'react';

import styles from './SettingsSectionCard.module.css';
import { Card } from 'components/shared/Card/Card';



export interface ISettingsSectionCardProps {
  title: string;
  description: string;
}



export const SettingsSectionCard: React.FC<ISettingsSectionCardProps> = props => {
  return (
    <Card extraClassName={styles.root}>
      <div className={styles.detail}>
        <div className={styles.headline}>
          Iron Forge Development
        </div>

        <h2 className={styles.title}>
          {props.title}
        </h2>

        <p>
          {props.description}
        </p>
      </div>

      <div className={styles.content}>
        {props.children}
      </div>
    </Card>
  );
};
