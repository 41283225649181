import {
  useAccountPageQuery as useBaseAccountPageQuery,
  useSaveSiteUserMutation as useBaseSaveSiteUserMutation,
  AccountPageDocument
} from 'generated/graphqlTypes';



export function useAccountPageQuery() {
  const queryTuple = useBaseAccountPageQuery();

  return {
    ...queryTuple,
    data: queryTuple.data?.loggedInSiteUser && !queryTuple.loading ? {
      id: queryTuple.data.loggedInSiteUser.id,
      profilePictureUrl: queryTuple.data.loggedInSiteUser.profilePictureUrl,
      fullName: queryTuple.data.loggedInSiteUser.fullName,
      email: queryTuple.data.loggedInSiteUser.email
    } : null
  };
}



export interface ISaveSiteUserArgs {
  id: string;
  email?: string;
  fullName?: string;
  profilePicUrl?: string;
}

export function useSaveSiteUserMutation() {
  const [fnRunMutation, mutationTuple] = useBaseSaveSiteUserMutation({
    refetchQueries: [
      {
        query: AccountPageDocument
      }
    ],
    awaitRefetchQueries: true
  });

  const fnWrappedMutation = (args: ISaveSiteUserArgs) => {
    return fnRunMutation({
      variables: {
        userInput: {
          id: args.id,
          email: args.email,
          fullName: args.fullName,
          encodedFileInput: {
            dataUrl: args.profilePicUrl
          }
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data: {
      id: mutationTuple.data?.saveSiteUser?.id ?? ''
    }
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}
