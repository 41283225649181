import {
  SettingsPageDocument,
  useSaveProjectMutation as useBaseMutation
} from 'generated/graphqlTypes';



export interface ISaveProjectMutationArgs {
  appName?: string;
  logoUrl?: string;
  projectTypeId?: string;
}



export function useSaveProjectMutation(projectExtId: string) {
  const [fnMutate, mutationTuple] = useBaseMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SettingsPageDocument,
        variables: {
          projectExtId
        }
      }
    ]
  });

  function fnWrappedMutation(input: ISaveProjectMutationArgs) {
    return fnMutate({
      variables: {
        input: {
          projectExtId,
          appDisplayName: input.appName,
          encodedFileInput: {
            dataUrl: input.logoUrl
          },
          projectType: input.projectTypeId
        }
      }
    });
  }

  return [
    fnWrappedMutation,
    mutationTuple
  ] as const;
}
