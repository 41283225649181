import React from "react";
import _ from "lodash";
import { Form } from "metisoft-react-components/dist/components/forms/Form/Form";
import { InputRow } from "metisoft-react-components/dist/components/forms/InputRow/InputRow";
import { StackedInputCell } from "components/shared/forms/StackedInputCell/StackedInputCell";
import { TextArea } from "components/shared/forms/TextArea/TextArea";
import { Button } from "components/shared/Button/Button";
import { MilestoneNameField } from "components/shared/forms/InputField/MilestoneNameField";

import styles from './ProjectMilestoneForm.module.css';



export interface IProjectMilestoneFormProps {
  allowEmpty?: boolean;
  buttonText: string;
  name: string;
  description: string;

  onSetName: (newName: string) => void;
  onSetDesc: (newDesc: string) => void;
  onSuccessfulSubmit: () => void;
}

export const ProjectMilestoneForm: React.FC<IProjectMilestoneFormProps> = props => {
  const refForm = React.useRef<Form>(null);

  return (
    <Form onSuccessfulSubmit={props.onSuccessfulSubmit}>
      <InputRow>
        <StackedInputCell>
          <label className={styles.milestoneName}>
            Milestone Name
          </label>

          <MilestoneNameField
            ref={_.partial(Form.captureElement, refForm, 'milestoneName')}
            required={!props.allowEmpty}
            placeholder="e.g. Art Direction"
            value={props.name}
            onChange={name => props.onSetName(name)} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            Milestone Description
          </label>

          <div className={styles.textAreaContainer}>
            <TextArea
              placeholder="e.g. Every Iron Forge project starts with setting art direction. From fonts to color pallette, art direction is the look and feel of the app or site. We’ll start with a couple screens and a couple ideas to get a better sense of how the software will look."
              required={!props.allowEmpty}
              rows={6}
              value={props.description}
              onChange={desc => props.onSetDesc(desc)} />
          </div>
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <Button
            extraClassName={styles.button}
            type="submit">
            {props.buttonText}
          </Button>
        </StackedInputCell>
      </InputRow>
    </Form>
  );
};
