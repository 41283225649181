import * as React from 'react';
import _ from 'lodash';

import {
  ITab,
  TabBar
} from 'components/shared/TabBar/TabBar';
import {
  IProjectUser,
  IPendingInvite
} from '../dataService';

import { useLinkSiteUserToProjectMutation } from './dataService';
import { SettingsSectionCard } from '../SettingsSectionCard/SettingsSectionCard';
import { Button } from 'components/shared/Button/Button';
import { AccessUserRow } from './AccessUserRow/AccessUserRow';
import { LinkSiteUserModal } from './LinkSiteUserModal/LinkSiteUserModal';

import styles from './ProjectAccessSection.module.css';

import imgGenericUser from 'assets/images/anonymousUser.svg';



export interface IProjectAccessSectionProps {
  projectExtId: string;
  users: IProjectUser[];
  pendingInvites: IPendingInvite[];
}



export const ProjectAccessSection: React.FC<IProjectAccessSectionProps> = props => {
  const [fnLinkSiteUserToProject, linkSiteUserToProjectTuple] = useLinkSiteUserToProjectMutation(props.projectExtId);

  const [linkSiteUserModalVisible, setLinkSiteUserModalVisible] = React.useState(false);
  const [selectedAccessTabId, setSelectedAccessTabId] = React.useState('user');
  const accessTabs: ITab[] = [
    {
      id: 'user',
      displayName: "USERS"
    }
  ];



  return (
    <>
      <LinkSiteUserModal
        onSubmit={fnLinkSiteUserToProject}
        isVisible={linkSiteUserModalVisible}
        onClose={() => setLinkSiteUserModalVisible(false)} />

      <SettingsSectionCard
        title="Project Access"
        description="Here you can invite new users or remove current users on your team that have access to this project. All users currently have the same permissions to add attachments, complete milestones, and finish action items.">
        <TabBar
          tabDefs={accessTabs}
          selectedTabId={selectedAccessTabId}
          onSelect={setSelectedAccessTabId} />

        <Button
          disabled={linkSiteUserToProjectTuple.loading}
          extraClassName={styles.addAccessButton}
          onClick={() => setLinkSiteUserModalVisible(true)}>
          Add +
        </Button>

        <div className={styles.accessUsers}>
          {
            _.map(props.users, (user, index) => (
              <AccessUserRow
                key={`user-${user.id}`}
                email={user.email}
                pictureUrl={user.profilePictureUrl}
                title={user.fullName} />
            ))
          }

          {
            _.map(props.pendingInvites, invite => (
              <AccessUserRow
                key={`invite-${invite.id}`}
                email={invite.email}
                pictureUrl={imgGenericUser}
                title="Pending Invitation..." />
            ))
          }
        </div>
      </SettingsSectionCard>
    </>
  );
};
