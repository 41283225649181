import React from "react";

import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from "components/shared/BaseModalTemplate/BaseModalTemplate";
import {
  TabBar,
  ITab
} from "components/shared/TabBar/TabBar";
import { LinkForm } from "./LinkForm/LinkForm";
import { AttachmentForm } from "./AttachmentForm/AttachmentForm";

import styles from './UploadAttachmentModal.module.css';



export interface IFile {
  originalFilename: string;
  dataUrl: string;
}

export interface IUploadAttachmentModalProps extends IBaseModalTemplateProps {
  loading: boolean;

  onUploadLinkAttachment: (displayName: string, linkUrl: string) => void;
  onUploadFileAttachment: (displayName: string, fileData: IFile) => void;
}

export const UploadAttachmentModal: React.FC<IUploadAttachmentModalProps> = props => {
  const [selectedTabId, setSelectedTabId] = React.useState('attachment');

  const [attachmentDisplayName, setAttachmentDisplayName] = React.useState('');
  const [resourceUrl, setResourceUrl] = React.useState('');
  const [fileData, setFileData] = React.useState<IFile | null>(null);



  React.useEffect(() => {
    clearForm();
  }, [selectedTabId]);



  function handleSuccessfulSubmitLink() {
    props.onUploadLinkAttachment(attachmentDisplayName, resourceUrl)
    closeForm();
  }



  async function handleSuccesfulSubmitAttachment() {
    if (!fileData) {
      return;
    }

    props.onUploadFileAttachment(attachmentDisplayName, fileData);
    closeForm();
  }



  function clearForm() {
    setAttachmentDisplayName('');
    setResourceUrl('');
    setFileData(null);
  }



  function closeForm() {
    clearForm();
    props.onClose();
  }

  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={closeForm}>
      <div className={styles.root}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Add a New Attachment or Link
          </h1>

          <p className={styles.description}>
            Use the credentials supplied to you to access your Iron Forge account. Not sure what your account is? Contact us at info@ironforge.co.
          </p>

          <TabBar
            tabDefs={__tabDefs}
            selectedTabId={selectedTabId}
            onSelect={setSelectedTabId} />
        </div>

        {selectedTabId === 'link' && (
          <LinkForm
            formDisabled={props.loading}
            linkDisplayName={attachmentDisplayName}
            linkUrl={resourceUrl}
            onSetLinkDisplayName={setAttachmentDisplayName}
            onSetLinkUrl={setResourceUrl}
            onSuccessfulSubmit={handleSuccessfulSubmitLink} />
        )}

        {selectedTabId === 'attachment' && (
          <AttachmentForm
            attachmentDisplayName={attachmentDisplayName}
            dataUrl={fileData?.dataUrl ?? ''}
            filename={fileData?.originalFilename ?? ''}
            formDisabled={props.loading}
            onSetAttachmentDisplayName={setAttachmentDisplayName}
            onProcessedFile={(originalFilename, dataUrl) => setFileData({
              originalFilename,
              dataUrl
            })}
            onSuccessfulSubmit={handleSuccesfulSubmitAttachment} />
        )}
      </div>
    </BaseModalTemplate>
  );
};



const __tabDefs: ITab[] = [
  {
    id: "attachment",
    displayName: "ATTACHMENT"
  },
  {
    id: "link",
    displayName: "LINK"
  }
];
