import {
  CredentialPageDocument,
  useCreateCredentialMutation as useBaseMutation
} from 'generated/graphqlTypes';


export function useCreateCredentialMutation(projectExtId: string) {
  const [fnRunMutation, mutationTuple] = useBaseMutation({
    refetchQueries: [
      {
        query: CredentialPageDocument,
        variables: {
          projectExtId
        }
      }
    ],
    awaitRefetchQueries: true
  });

  function fnWrappedMutation(serviceName: string, serviceUrl: string, username: string, password: string) {
    return fnRunMutation({
      variables: {
        input: {
          projectExtId,
          serviceName,
          username,
          password,
          serviceUrl
        }
      }
    });
  }

  return [
    fnWrappedMutation,
    mutationTuple
  ] as const;
}
