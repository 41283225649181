import * as React from 'react';
import _ from 'lodash';

import * as dataService from './dataService';
import { UploadAttachmentModal } from 'components/shared/UploadAttachmentModal/UploadAttachmentModal';
import { RouteComponentProps } from 'react-router-dom';
import { PageTemplate } from 'components/pages/PageTemplate/PageTemplate';
import { AttachmentCard } from './AttachmentCard/AttachmentCard';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { Button } from 'components/shared/Button/Button';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';

import styles from './AttachmentsPage.module.css';




export interface IAttachmentsPageProps extends RouteComponentProps<{ projectExtId: string }> { }

export const AttachmentsPage: React.FC<IAttachmentsPageProps> = props => {
  const [filterText, setFilterText] = React.useState('');
  const [isUploadAttachmentModalVisible, setIsUploadAttachmentModalVisible] = React.useState(false);

  const queryTuple = dataService.useAttachmentPageQuery(props.match.params.projectExtId, filterText);
  const [fnUploadLinkAttachment, uploadLinkAttachmentTuple] = dataService.useUploadProjectAttachmentLinkMutation(props.match.params.projectExtId);
  const [fnUploadFileAttachment, uploadFileAttachmentTuple] = dataService.useUploadProjectAttachmentFileMutation(props.match.params.projectExtId);



  const loading = _.some([
    queryTuple,
    uploadLinkAttachmentTuple,
    uploadFileAttachmentTuple
  ], tuple => tuple.loading);

  return (
    <>
      <PageTemplate
        {...props}
        requiresUser
        toolbarType="projectToolbar">
        <div className={styles.content}>
          <div className={styles.topBar}>
            <h1 className={styles.pageTitle}>
              All Attachments
            </h1>

            <Button
              extraClassName={styles.uploadAttachmentButton}
              onClick={() => setIsUploadAttachmentModalVisible(true)}>
              Upload Attachment +
            </Button>

            <SearchBar
              filterText={filterText}
              onChange={setFilterText} />
          </div>

          <div className={styles.attachmentContainer}>
            {!_.isEmpty(queryTuple.data) && _.map(queryTuple.data.attachments, attachment => (
              <div
                className={styles.attachmentCardContainer}
                key={attachment.id}>
                <AttachmentCard attachment={attachment} />
              </div>
            ))}
          </div>
        </div>
      </PageTemplate>

      <UploadAttachmentModal
        loading={loading}
        onUploadFileAttachment={(displayName, fileData) => {
          fnUploadFileAttachment(displayName, queryTuple.data.projectId, fileData.dataUrl, fileData.originalFilename);
          setIsUploadAttachmentModalVisible(false);
        }}
        onUploadLinkAttachment={(displayName, linkUrl) => {
          fnUploadLinkAttachment(displayName, queryTuple.data.projectId, linkUrl);
          setIsUploadAttachmentModalVisible(false);
        }}
        isVisible={isUploadAttachmentModalVisible}
        onClose={() => setIsUploadAttachmentModalVisible(false)} />

      <LoadingOverlay
        isVisible={loading}
        text="Working..." />
    </>
  );
};
