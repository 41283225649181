
import * as React from 'react';

import {
  IAttachment,
  IAttachmentType
} from 'components/pages/AttachmentsPage/dataService';

import styles from './AttachmentCard.module.css';

import imgDownloadButton from 'assets/images/downloadButton.svg';
import imgLink from 'assets/images/iconLink.svg';
import imgVideo from 'assets/images/iconVideo.svg';
import imgFile from 'assets/images/iconFile.svg';
import imgUnknown from 'assets/images/iconUnknown.svg';



export interface IAttachmentCardProps {
  attachment: IAttachment;
}

export const AttachmentCard: React.FC<IAttachmentCardProps> = props => (
  <div className={styles.root}>
    <div className={styles.imageContainer}>
      <img
        className={styles.image}
        src={props.attachment.attachmentType === 'image' ?
          props.attachment.resourceUrl :
          __url2AttachmentType[props.attachment.attachmentType]
        }
        alt="Attachment Representation" />
    </div>

    <div className={styles.bottomBar}>
      <div className={styles.text}>
        <div className={styles.fileName}>
          {props.attachment.displayName}
        </div>

        <div className={styles.fullFileName}>
          {props.attachment.description}
        </div>
      </div>

      <a
        className={styles.button}
        href={props.attachment.resourceUrl}
        target="__blank">
        <img
          src={imgDownloadButton}
          alt="Download or navigate" />
      </a>
    </div>
  </div>
);



const __url2AttachmentType: Record<IAttachmentType, string> = {
  'link': imgLink,
  'video': imgVideo,
  'file': imgFile,
  'unknown': imgUnknown,
  'image': ''
};
