import React from "react";
import _ from "lodash";
import { Form } from "metisoft-react-components/dist/components/forms/Form/Form";
import { InputRow } from "metisoft-react-components/dist/components/forms/InputRow/InputRow";
import { StackedInputCell } from "components/shared/forms/StackedInputCell/StackedInputCell";
import { Button } from "components/shared/Button/Button";
import { AddAttachmentInput } from 'components/shared/UploadAttachmentModal/AddAttachmentInput/AddAttachmentInput';
import { AttachmentNameInputField } from "components/shared/forms/InputField/AttachmentNameInputField";

import styles from './AttachmentForm.module.css';



export interface IAttachmentFormProps {
  attachmentDisplayName: string;
  filename: string;
  dataUrl: string;
  formDisabled?: boolean;

  onSetAttachmentDisplayName: (attachmentDisplayName: string) => void;
  onProcessedFile: (originalFilename: string, dataUrl: string) => void;
  onSuccessfulSubmit: () => void;
}

export const AttachmentForm: React.FC<IAttachmentFormProps> = props => {
  const refForm = React.useRef<Form>(null);

  return (
    <Form
      extraClassName={styles.root}
      onSuccessfulSubmit={props.onSuccessfulSubmit}>
      <InputRow>
        <StackedInputCell>
          <label className={styles.attachmentName}>
            Attachment Name
          </label>

          <AttachmentNameInputField
            required
            disabled={props.formDisabled}
            ref={_.partial(Form.captureElement, refForm, 'attachmentDisplayName')}
            placeholder="e.g. Branding Document"
            value={props.attachmentDisplayName}
            onChange={props.onSetAttachmentDisplayName} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <div className={styles.uploadAttachmentFileInputContainer}>
            <AddAttachmentInput
              disabled={props.formDisabled}
              urlToShow={props.dataUrl}
              onChange={props.onProcessedFile} />
          </div>
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <Button
            disabled={_.isEmpty(props.attachmentDisplayName) || _.isEmpty(props.dataUrl) || props.formDisabled}
            extraClassName={styles.button}
            type="submit">
            Upload File
          </Button>
        </StackedInputCell>
      </InputRow>
    </Form>
  );
};
