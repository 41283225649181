import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useSettingsPageQuery } from './dataService';
import { PageTemplate } from 'components/pages/PageTemplate/PageTemplate';
import { ProjectAccessSection } from './ProjectAccessSection/ProjectAccessSection';
import { ProjectSettingsSection } from './ProjectSettingsSection/ProjectSettingsSection';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';

import styles from './SettingsPage.module.css';



export interface ISettingsPageProps extends RouteComponentProps<{
  projectExtId?: string;
}> { }

export const SettingsPage: React.FC<ISettingsPageProps> = props => {
  const projectExtId = props.match.params.projectExtId ? props.match.params.projectExtId : '';

  const queryTuple = useSettingsPageQuery(projectExtId);



  return (
    <>
      <LoadingOverlay
        isVisible={queryTuple.loading}
        text="Loading" />

      <PageTemplate
        {...props}
        requiresUser
        requiresAdmin
        toolbarType="projectToolbar">
        <div className={styles.root}>
          <h1 className={styles.pageTitle}>
            Settings
          </h1>

          {queryTuple.data && (
            <>
              <div className={styles.sectionContainer}>
                <ProjectAccessSection
                  pendingInvites={queryTuple.data.pendingInvites || []}
                  projectExtId={projectExtId}
                  users={queryTuple.data.users} />
                </div>

              <div className={styles.sectionContainer}>
                <ProjectSettingsSection
                  projectExtId={projectExtId}
                  projectTypes={queryTuple.data.projectTypes}
                  settings={queryTuple.data.settings} />
              </div>
            </>
          )}
        </div>
      </PageTemplate>
    </>
  );
};
