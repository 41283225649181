import React from "react";
import _ from "lodash";
import { Form } from "metisoft-react-components/dist/components/forms/Form/Form";
import { InputRow } from "metisoft-react-components/dist/components/forms/InputRow/InputRow";
import { StackedInputCell } from "components/shared/forms/StackedInputCell/StackedInputCell";
import { Button } from "components/shared/Button/Button";
import { LinkInputField } from "components/shared/forms/InputField/LinkInputField";
import { LinkNameInputField } from "components/shared/forms/InputField/LinkNameInputField";

import styles from './LinkForm.module.css';



export interface ILinkFormProps {
  linkDisplayName: string;
  linkUrl: string;
  formDisabled?: boolean;

  onSetLinkDisplayName: (newLinkName: string) => void;
  onSetLinkUrl: (newLink: string) => void;
  onSuccessfulSubmit: () => void;
}

export const LinkForm: React.FC<ILinkFormProps> = props => {
  const refForm = React.useRef<Form>(null);

  return (
    <Form
      ref={refForm}
      onSuccessfulSubmit={props.onSuccessfulSubmit}>
      <InputRow>
        <StackedInputCell>
          <label className={styles.linkName}>
            Link Name
          </label>

          <LinkNameInputField
            disabled={props.formDisabled}
            required
            ref={_.partial(Form.captureElement, refForm, 'linkName')}
            placeholder="e.g. Branding Document"
            value={props.linkDisplayName}
            onChange={name => props.onSetLinkDisplayName(name)} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.formText}>
            Link
          </label>

          <div className={styles.textAreaContainer}>
            <LinkInputField
              disabled={props.formDisabled}
              required
              ref={_.partial(Form.captureElement, refForm, 'link')}
              placeholder="https://www.ironforge.co"
              value={props.linkUrl}
              onChange={link => props.onSetLinkUrl(link)} />
          </div>
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <Button
            disabled={props.formDisabled}
            extraClassName={styles.button}
            type="submit">
            Add Link
          </Button>
        </StackedInputCell>
      </InputRow>
    </Form>
  );
};
