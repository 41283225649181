import * as React from 'react';
import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';
import { Button } from 'components/shared/Button/Button';

import styles from './PaymentModal.module.css';


export interface IPaymentModalProps extends IBaseModalTemplateProps {
  onClickPayment: () => void;
}



export const PaymentModal: React.FC<IPaymentModalProps> = props => (
  <BaseModalTemplate
    isVisible={props.isVisible}
    onClose={props.onClose}>
    <div className={styles.root}>
      <h1 className={styles.title}>
        Mark as Paid
      </h1>

      <p className={styles.desc}>
        Marking this payment milestone as paid should only be done by the CEO, COO, or project manager.  Marking as paid will send a confirmation email to the client alerting that we have received payment. Do not mark as paid unless absolutely sure the client has paid.
      </p>

      <Button onClick={props.onClickPayment}>
        Mark as Paid
      </Button>

      <div
        onClick={props.onClose}
        className={styles.cancelButton}>
        No, Cancel
      </div>
    </div>
  </BaseModalTemplate>
);
