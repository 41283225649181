import * as React from 'react';
import _ from 'lodash';

import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';

import { ICredential } from 'components/pages/CredentialPage/dataService';
import { CredentialForm } from '../CredentialForm/CredentialForm';
import { useSaveCredentialMutation } from './dataService';

import styles from './EditCredentialModal.module.css';



export interface IEditCredentialModalProps extends IBaseModalTemplateProps {
  projectExtId: string;
  credential: ICredential;
}



export const EditCredentialModal: React.FC<IEditCredentialModalProps> = props => {
  const [fnSaveCredential] = useSaveCredentialMutation(props.projectExtId);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [serviceName, setServiceName] = React.useState("");
  const [serviceUrl, setServiceUrl] = React.useState("");

  React.useEffect(() => {
    if (props.credential.id !== '') {
      setUsername(props.credential.username);
      setPassword(props.credential.password);
      setServiceName(props.credential.serviceName);
      setServiceUrl(props.credential.serviceUrl);
    }
  }, [props.credential]);


  function closeForm() {
    props.onClose();

    setUsername('');
    setPassword('');
    setServiceName('');
    setServiceUrl('');
  }



  function handleSubmit() {
    const trimmedData = _.omitBy({
      username,
      password,
      serviceName,
      serviceUrl
    }, value => !value);
    return fnSaveCredential(
      props.credential.id,
      trimmedData.serviceName,
      trimmedData.serviceUrl,
      trimmedData.username,
      trimmedData.password
    )
      .then(() => {
        closeForm();
      });
  }



  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={closeForm}>
      <div className={styles.root}>
        <h1 className={styles.title}>
          Edit a Credential
        </h1>

        <p className={styles.desc}>
          Fill in the information below to update a credential.
        </p>

        <CredentialForm
          submitButtonLabel="Save Credential"
          username={username}
          password={password}
          serviceName={serviceName}
          serviceUrl={serviceUrl}
          onChangeUsername={setUsername}
          onChangePassword={setPassword}
          onChangeServiceName={setServiceName}
          onChangeServiceUrl={setServiceUrl}
          onSubmit={handleSubmit} />
      </div>
    </BaseModalTemplate>
  );
};
