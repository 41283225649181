import _ from 'lodash';
import moment from 'moment';
import {
  useMilestonePageQuery as useBaseQuery,
  useDeleteActionMutation as useBaseDeleteActionMutation,
  useCreateActionMutation as useBaseCreateActionMutation,
  useCreateProjectMilestoneMutation as useBaseCreateProjectMilestoneMutation,
  useCreatePaymentMilestoneMutation as useBaseCreatePaymentMilestoneMutation,
  useCreateAuditEventMutation as useBaseCreateAuditEventMutation,
  useSaveActionMutation as useBaseSaveActionMutation,
  useSavePaymentMilestoneMutation as useBaseSavePaymentMilestoneMutation,
  useSaveProjectMilestoneMutation as useBaseSaveProjectMilestoneMutation,
  useDeleteProjectMilestoneMutation as useBaseDeleteProjectMilestoneMutation,
  useReorderMilestonesMutation as useBaseReorderMilestoneMutation,
  useUploadMilestoneFileAttachmentMutation as useBaseUploadMilestoneFileAttachmentMutation,
  useUploadMilestoneLinkAttachmentMutation as useBaseUploadMilestoneLinkAttachmentMutation,
  MilestonePageDocument,
  Action
} from 'generated/graphqlTypes';


export type IMilestoneData = IPaymentMilestoneData | IProjectMilestoneData;

export const milestoneShell: IPaymentMilestoneData = {
  id: '0',
  amountCents: 0,
  paymentUrl: '',
  rank: 0,
  status: {
    name: "pending",
    displayName: ''
  },
  type: "PaymentMilestone"
};



type IActionStatus = 'new' | 'submitted' | 'rejected' | 'approved';

export interface IActionStatusType {
  displayName: string;
  name: IActionStatus;
}



type IMilestoneStatus = 'pending' | 'inProgress' | 'submitted' | 'accepted';

export interface IMilestoneStatusType {
  displayName: string;
  name: IMilestoneStatus;
}



export interface IPaymentMilestoneData {
  type: 'PaymentMilestone';

  id: string;
  amountCents: number;
  rank: number;
  paymentUrl: string;
  status: IMilestoneStatusType;
}



export interface IProjectMilestoneData {
  type: 'ProjectMilestone';

  id: string;
  rank: number;
  title: string;
  description: string;
  actions: IActionCardData[];
  attachments: IAttachmentData[];
  logs: ILogData[];
  loggedInUserPic: string;
  status: IMilestoneStatusType;
}



export interface IAttachmentData {
  id: string;
  displayName: string;
  description: string;
  resourceUrl: string;
}



export interface ILogData {
  id: string;
  creatorId: string;
  userName: string;
  description: string;
  time: string;
  profilePictureUrl: string;
}



export interface IActionCardData {
  id: string;
  metaCreated: string;
  displayName: string;
  description: string;
  status: IActionStatusType;
  userAuthorizedAsAdmin?: boolean;
}



export interface ICreateActionReturn {
  id: string;
  displayName: string;
  description: string;
  status: {
    id: string;
    displayName: string;
    name: string;
  };
}



export function useMilestonePageQuery(extId: string) {
  const queryTuple = useBaseQuery({
    variables: {
      extId
    }
  });

  const project = queryTuple.data ? queryTuple.data.project : null;
  const siteUser = queryTuple.data ? queryTuple.data.loggedInSiteUser : null;

  let milestones: IMilestoneData[] = [];
  if (!queryTuple.loading && project && siteUser) {
    milestones = _.map(project.milestones, (milestone): IMilestoneData => {
      if (milestone.__typename === 'PaymentMilestone') {
        return ({
          type: milestone.__typename,
          id: milestone.id,
          amountCents: milestone.paymentAmountCents,
          rank: milestone.ranking,
          paymentUrl: milestone.paymentUrl,
          status: {
            name: __graphqlMilestoneStatus2FrontendStatus(milestone.status.name),
            displayName: milestone.status.displayName
          }
        });
      }

      if (milestone.__typename === 'ProjectMilestone') {
        return ({
          type: milestone.__typename,
          rank: milestone.ranking,
          id: milestone.id,
          title: milestone.displayName,
          description: milestone.description,
          actions: __convertToActionQuery(milestone.actions, siteUser.isSuperAdmin),
          attachments: _.map(milestone.attachments, (attachment): IAttachmentData => ({
            id: attachment.id,
            displayName: attachment.displayName,
            resourceUrl: attachment.resourceUrl,
            description: attachment.description
          })),
          logs: _.map(milestone.logs, log => ({
            id: log.id,
            creatorId: log.creator.id,
            time: moment(log.metaCreated).format('h:mm a [on] MMM Do YYYY'),
            description: log.description,
            profilePictureUrl: log.creator.profilePictureUrl,
            userName: log.creator.fullName
          })),
          loggedInUserPic: siteUser.profilePictureUrl,
          status: {
            name: __graphqlMilestoneStatus2FrontendStatus(milestone.status.name),
            displayName: milestone.status.displayName
          }
        });
      }

      throw new Error("Invalid milestone type received.");
    });
  }

  return {
    ...queryTuple,
    data: {
      milestones,
      isSuperAdmin: siteUser?.isSuperAdmin ?? false,
      projectId: project?.id ?? ''
    }
  };
}



export function useDeleteActionMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseDeleteActionMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;
  if (mutationTuple.data && mutationTuple.data.deleteAction) {
    data = mutationTuple.data.deleteAction;
  }

  const fnWrappedMutation = (actionId: string) => {
    return fnRunMutation({
      variables: {
        actionId
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ICreateActionArgs {
  displayName: string;
  description: string;
  milestoneId: string;
}

export function useCreateActionMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseCreateActionMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: ICreateActionReturn | null = null;
  if (mutationTuple.data && mutationTuple.data.createAction) {
    const returnValues = mutationTuple.data.createAction;
    data = {
      id: returnValues.id,
      displayName: returnValues.displayName,
      description: returnValues.description,
      status: {
        id: returnValues.status.id,
        displayName: returnValues.status.displayName,
        name: returnValues.status.name
      }
    };
  }

  const fnWrappedMutation = (args: ICreateActionArgs) => {
    return fnRunMutation({
      variables: {
        actionInput: {
          displayName: args.displayName,
          description: args.description,
          milestoneId: args.milestoneId
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ICreateAuditEventArgs {
  milestoneId: string;
  description: string;
}

export function useCreateAuditEventMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseCreateAuditEventMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;
  if (mutationTuple.data && mutationTuple.data.createAuditEvent) {
    data = mutationTuple.data.createAuditEvent.id;
  }

  const fnWrappedMutation = (args: ICreateAuditEventArgs) => {
    return fnRunMutation({
      variables: {
        eventInput: {
          milestoneId: args.milestoneId,
          description: args.description
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ICreateProjectMilestoneArgs {
  displayName: string;
  description: string;
  projectExtId: string;
}

export function useCreateProjectMilestoneMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseCreateProjectMilestoneMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;

  if (mutationTuple.data && mutationTuple.data.createProjectMilestone) {
    data = mutationTuple.data.createProjectMilestone.id;
  }

  const fnWrappedMutation = (args: ICreateProjectMilestoneArgs) => {
    return fnRunMutation({
      variables: {
        input: {
          displayName: args.displayName,
          description: args.description,
          projectExtId: args.projectExtId
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ICreatePaymentMilestoneArgs {
  paymentAmountCents: number;
  paymentUrl: string;
  projectExtId: string;
}

export function useCreatePaymentMilestoneMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseCreatePaymentMilestoneMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;

  if (mutationTuple.data && mutationTuple.data.createPaymentMilestone) {
    data = mutationTuple.data.createPaymentMilestone.id;
  }

  const fnWrappedMutation = (args: ICreatePaymentMilestoneArgs) => {
    return fnRunMutation({
      variables: {
        input: {
          paymentAmountCents: args.paymentAmountCents,
          paymentUrl: args.paymentUrl,
          projectExtId: args.projectExtId
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ISaveActionArgs {
  actionId: string;
  displayName?: string;
  description?: string;
  statusName?: string;
}

export function useSaveActionMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseSaveActionMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;
  if (mutationTuple.data && mutationTuple.data.saveAction) {
    data = mutationTuple.data.saveAction.id;
  }

  const fnWrappedMutation = (args: ISaveActionArgs) => {
    return fnRunMutation({
      variables: {
        actionInput: {
          actionId: args.actionId,
          displayName: args.displayName,
          description: args.description,
          status: args.statusName
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ISavePaymentMilestoneArgs {
  milestoneId: string;
  paymentAmountCents?: number;
  paymentUrl?: string;
  status?: string;
}

export function useSavePaymentMilestoneMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseSavePaymentMilestoneMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;

  if (mutationTuple.data && mutationTuple.data.savePaymentMilestone) {
    data = mutationTuple.data.savePaymentMilestone.id;
  }

  const fnWrappedMutation = (args: ISavePaymentMilestoneArgs) => {
    return fnRunMutation({
      variables: {
        milestoneInput: {
          paymentAmountCents: args.paymentAmountCents,
          paymentUrl: args.paymentUrl,
          milestoneId: args.milestoneId,
          status: args.status
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface ISaveProjectMilestoneArgs {
  milestoneId: string;
  displayName?: string;
  description?: string;
  status?: string;
}

export function useSaveProjectMilestoneMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseSaveProjectMilestoneMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;

  if (mutationTuple.data && mutationTuple.data.saveProjectMilestone) {
    data = mutationTuple.data.saveProjectMilestone.id;
  }

  const fnWrappedMutation = (args: ISaveProjectMilestoneArgs) => {
    return fnRunMutation({
      variables: {
        milestoneInput: {
          description: args.description,
          displayName: args.displayName,
          milestoneId: args.milestoneId,
          status: args.status
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export function useDeleteProjectMilestoneMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseDeleteProjectMilestoneMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;
  if (mutationTuple.data && mutationTuple.data.deleteProjectMilestone) {
    data = mutationTuple.data.deleteProjectMilestone;
  }

  const fnWrappedMutation = (id: string) => {
    return fnRunMutation({
      variables: {
        milestoneId: id
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface IReorderMilestoneArgs {
  projectExtId: string;
  orderedMilestoneIds: string[];
}

export function useReorderMilestoneMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseReorderMilestoneMutation({
    refetchQueries: [
      {
        query: MilestonePageDocument,
        variables: { extId }
      }
    ],
    awaitRefetchQueries: true
  });

  let data: string | null = null;

  if (mutationTuple.data && mutationTuple.data.reorderMilestones) {
    data = mutationTuple.data.reorderMilestones.extId;
  }

  const fnWrappedMutation = (args: IReorderMilestoneArgs) => {
    return fnRunMutation({
      variables: {
        reorderInput: {
          orderedMilestoneIds: args.orderedMilestoneIds,
          projectExtId: args.projectExtId
        }
      }
    });
  };

  const newTuple = {
    ...mutationTuple,
    data
  };

  return [
    fnWrappedMutation,
    newTuple
  ] as const;
}



export interface IUploadMilestoneFileAttachmentArgs {
  displayName: string;
  milestoneId: string;
  encodedFile: {
    filename: string;
    encodedFileInput: {
      dataUrl: string;
      fileExtension: string;
    }
  };
}

export function useUploadMilestoneFileAttachmentMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseUploadMilestoneFileAttachmentMutation({
    refetchQueries: [{
      query: MilestonePageDocument,
      variables: { extId }
    }]
  });

  const fnWrappedMutation = (args: IUploadMilestoneFileAttachmentArgs) => fnRunMutation({
    variables: {
      ...args,
      encodedFile: {
        encodedFileInput: args.encodedFile.encodedFileInput,
        filename: args.encodedFile.filename
      },
      projectExtId: extId
    }
  });

  return [fnWrappedMutation, {
    ...mutationTuple,
    data: mutationTuple.data?.uploadAttachment?.id ?? ''
  }] as const;
}



export interface IUploadMilestoneLinkAttachmentArgs {
  milestoneId: string;
  linkUrl: string;
  displayName: string;
}

export function useUploadMilestoneLinkAttachmentMutation(extId: string) {
  const [fnRunMutation, mutationTuple] = useBaseUploadMilestoneLinkAttachmentMutation({
    refetchQueries: [{
      query: MilestonePageDocument,
      variables: { extId }
    }]
  });

  const fnWrappedMutation = (args: IUploadMilestoneLinkAttachmentArgs) => fnRunMutation({
    variables: {
      ...args,
      projectExtId: extId
    }
  });

  return [fnWrappedMutation, {
    ...mutationTuple,
    data: mutationTuple.data?.uploadAttachment?.id ?? ''
  }] as const;
}



function __convertToActionQuery(actions: Action[], isSuperAdmin: boolean): IActionCardData[] {
  return _.map(actions, (action): IActionCardData => ({
    id: action.id,
    metaCreated: action.metaCreated,
    displayName: action.displayName,
    description: action.description,
    status: {
      displayName: action.status.displayName,
      name: __graphqlActionStatus2FrontendStatus(action.status.name)
    },
    userAuthorizedAsAdmin: isSuperAdmin
  }));
}



function __graphqlActionStatus2FrontendStatus(status: string) {
  const validActionStatus: _.Dictionary<IActionStatus> = {
    new: 'new',
    submitted: 'submitted',
    rejected: 'rejected',
    approved: 'approved'
  };

  if (_.isNil(validActionStatus[status])) {
    throw new Error('INVALID_ACTION_STATUS');
  }

  return validActionStatus[status];
}



function __graphqlMilestoneStatus2FrontendStatus(status: string) {
  const validMilestoneStatus: _.Dictionary<IMilestoneStatus> = {
    pending: 'pending',
    inProgress: 'inProgress',
    submitted: 'submitted',
    accepted: 'accepted'
  };

  if (_.isNil(validMilestoneStatus[status])) {
    throw new Error('INVALID_MILESTONE_STATUS');
  }

  return validMilestoneStatus[status];
}
