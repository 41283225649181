import * as React from 'react';
import _ from 'lodash';
import * as dataService from '../dataService';
import update from 'immutability-helper';
import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { ProjectNameField } from 'components/shared/forms/InputField/ProjectNameField';
import { Button } from 'components/shared/Button/Button';
import { LogoField } from 'components/shared/LogoField/LogoField';
import { Select } from 'components/shared/forms/Select/Select';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';

import styles from './NewProjectModal.module.css';



interface IProjectFormData {
  encodedFileUrl: string | null;
  appName: string;
  projectType: string;
}



export interface INewProjectModalProps extends IBaseModalTemplateProps {
  projectTypes: dataService.IProjectType[];
}



export const NewProjectModal: React.FC<INewProjectModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const defaultProjectType = props.projectTypes[0];
  const [formData, setFormData] = React.useState<IProjectFormData>({
    appName: '',
    encodedFileUrl: null,
    projectType: defaultProjectType.name
  });

  const [fnCreateProject, createProjectTuple] = dataService.useCreateProjectMutation();



  function deleteFormInfo() {
    setFormData(update(formData, {
      appName: {$set: ''},
      encodedFileUrl: {$set: ''},
      projectType: {$set: defaultProjectType.name}
    }));
  }



  function handleSuccessfulSubmit() {
    if (!formData.encodedFileUrl) {
      alert("Please submit a logo.");
      return;
    }

    return fnCreateProject({
      appName: formData.appName,
      dataUrl: formData.encodedFileUrl,
      projectType: formData.projectType
    })

      .then(() => {
        deleteFormInfo();
        props.onClose();
      });
  }



  return (
    <>
      <LoadingOverlay
        text="Sending to the code monkeys..."
        isVisible={createProjectTuple.called && createProjectTuple.loading} />

      <BaseModalTemplate
        isVisible={props.isVisible}
        onClose={() => {
          deleteFormInfo();
          props.onClose();
        }}>

        <div className={styles.root}>
          <h1 className={styles.title}>
            Create a New Project
          </h1>

          <p className={styles.desc}>
            Fill in the below information to create a new project.
          </p>

          <Form
            extraClassName={styles.form}
            ref={refForm}
            onSuccessfulSubmit={handleSuccessfulSubmit}>
            <LogoField
              extraClassName={styles.imageBar}
              logoUrl={formData.encodedFileUrl || ''}
              onChange={newLogoUrl =>
                setFormData(update(formData, {
                  encodedFileUrl: {$set: newLogoUrl},
                })
              )} />

            <InputRow>
              <StackedInputCell>
                <label className={styles.formText}>
                  Client Name
                </label>

                <ProjectNameField
                  ref={_.partial(Form.captureElement, refForm, 'projectName')}
                  required
                  value={formData.appName}
                  onChange={newProjectName => (
                    setFormData(update(formData, {
                      appName: {$set: newProjectName}
                    }))
                  )} />
              </StackedInputCell>
            </InputRow>

            <InputRow>
              <StackedInputCell>
                <label className={styles.formText}>
                  Project Type
                </label>

                <Select
                  extraClassName={styles.dropdownBar}
                  selectedId={formData.projectType}
                  optionDefs={
                    _.map(props.projectTypes, (projectType: dataService.IProjectType) => {
                      return {
                        id: projectType.name,
                        label: projectType.displayName
                      };
                    })
                  }
                  onChange={selectedOptionId => (
                    setFormData(update(formData, {
                      projectType: {$set: selectedOptionId}
                    }))
                  )} />
              </StackedInputCell>
            </InputRow>

            <Button type="submit">
              Create Project
            </Button>
          </Form>
        </div>
      </BaseModalTemplate>
    </>
  );
};
