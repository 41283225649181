import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {setContext} from 'apollo-link-context';
import {HttpLink} from 'apollo-link-http';
import * as environmentUtil from 'util/environment';
import introspectionQueryResultData from '../generated/fragmentTypes.json';



let __client: ApolloClient<NormalizedCacheObject>;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});



export function init(config: environmentUtil.IEnvironment, fnAuthHeaders: () => Promise<_.Dictionary<string>>) {
  if (__client) {
    throw new Error('APOLLO_CLIENT_INITIALIZED');
  }

  const authLink = setContext(async (operation, prevContext) => {
    return {
      headers: {
        ...prevContext.headers,
        ...(await fnAuthHeaders())
      }
    };
  });

  __client = new ApolloClient({
    cache: new InMemoryCache({ fragmentMatcher }),
    link: authLink.concat(new HttpLink({uri: `${config.api.url}${config.api.pathToGraphQlEndpoint}`}))
  });
}



export function getClient() {
  return __client;
}
