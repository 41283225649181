import * as React from 'react';

import styles from './LoadingOverlay.module.css';



export interface ILoadingOverlayProps {
  isVisible: boolean;
  text: string;
}

export const LoadingOverlay: React.FC<ILoadingOverlayProps> = props => (
  <div className={`${styles.root} ${props.isVisible ? styles.visible : styles.hidden}`}>
    <div className={styles.content}>
      <div className={styles.loader} />

      <div className={styles.text}>
        {props.text}
      </div>
    </div>
  </div>
);
