import * as React from 'react';

import styles from './AttachmentTabCard.module.css';

import imgDownload from 'assets/images/download.svg';



export interface IAttachmentTabCardProps {
  displayName: string;
  fileName: string;
  resourceUrl: string;
}



export const AttachmentTabCard: React.FC<IAttachmentTabCardProps> = props => (
  <div className={styles.root}>
    <div className={styles.infoText}>
      <h2 className={styles.attachmentName}>
        {props.displayName}
      </h2>

      <div className={styles.attachmentFilePath}>
        {props.fileName}
      </div>
    </div>


    <a
      className={styles.button}
      href={props.resourceUrl}
      target="__blank">
      <img
        className={styles.download}
        src={imgDownload}
        alt="download" />
    </a>

  </div>
);
