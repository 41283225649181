import * as React from 'react';
import _ from 'lodash';

import {
  useCredentialPageQuery,
  useDeleteCredentialMutation,
  ICredential
} from './dataService';

import { RouteComponentProps } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import { PageTemplate } from 'components/pages/PageTemplate/PageTemplate';
import { Button } from 'components/shared/Button/Button';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { CredentialTable } from './CredentialTable/CredentialTable';
import { AddCredentialModal } from './modals/AddCredentialModal/AddCredentialModal';
import { EditCredentialModal } from './modals/EditCredentialModal/EditCredentialModal';

import styles from './CredentialPage.module.css';



export interface ICredentialPageProps extends RouteComponentProps<{
  projectExtId?: string;
}> { }



export const CredentialPage: React.FC<ICredentialPageProps> = props => {
  const projectExtId = props.match.params.projectExtId ? props.match.params.projectExtId : '';

  const [addCredentialModalVisible, setAddCredentialModalVisible] = React.useState(false);
  const [editCredentialModalVisible, setEditCredentialModalVisible] = React.useState(false);
  const [selectedCredential, setSelectedCredential] = React.useState<ICredential>({
    id: '',
    password: '',
    serviceName: '',
    serviceUrl: '',
    username: ''
  });
  const [fnDeleteCredential, deleteCredentialTuple] = useDeleteCredentialMutation(projectExtId);

  const clipboard = useClipboard();
  const queryTuple = useCredentialPageQuery(projectExtId);

  const isLoading = _.some([
    queryTuple,
    deleteCredentialTuple
  ], tuple => tuple.loading);



  function handleClickEdit(credential: ICredential) {
    setSelectedCredential(credential);
    setEditCredentialModalVisible(true);
  }



  function handleClickCopyPassword(password: string) {
    clipboard.copy(password);
  }



  return (
    <>
      <LoadingOverlay
        isVisible={isLoading}
        text="Loading" />

      <AddCredentialModal
        projectExtId={projectExtId}
        isVisible={addCredentialModalVisible}
        onClose={() => setAddCredentialModalVisible(false)} />

      <EditCredentialModal
        projectExtId={projectExtId}
        credential={selectedCredential}
        isVisible={editCredentialModalVisible}
        onClose={() => setEditCredentialModalVisible(false)} />

      <PageTemplate
        {...props}
        requiresUser
        toolbarType="projectToolbar">
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <h1 className={styles.pageTitle}>
                Project Credentials
              </h1>

              <p className={styles.pageDescription}>
                On occassion we’ll request or create credentials to various services needed for your project. Below both the Iron Forge team and your team can keep track of all credentials needed.
              </p>
            </div>

            <div className={styles.headerRight}>
              <Button onClick={() => setAddCredentialModalVisible(true)}>
                Add New Credential +
              </Button>
            </div>
          </div>

          <div className={styles.table}>
            <CredentialTable
              credentials={queryTuple.data}
              onClickCopyPassword={handleClickCopyPassword}
              onClickEdit={handleClickEdit}
              onClickDelete={fnDeleteCredential} />
          </div>
        </div>
      </PageTemplate>
    </>
  );
};
