import _ from 'lodash';
import React from 'react';

import styles from './AddAttachmentInput.module.css';

import imgDocument from "assets/images/documents.svg";



export interface IAddAttachmentInputProps {
  disabled?: boolean;
  urlToShow: string;

  onChange: (originalFilename: string, dataUrl: string) => void;
}

export const AddAttachmentInput: React.FC<IAddAttachmentInputProps> = props => {

  return (
    <div
      className={styles.root}>
      {props.urlToShow ? (
        <img
          alt="Attachment Preview"
          className={styles.previewImage}
          src={props.urlToShow} />
      ) : (
        <>
          <label
            className={styles.customLabel}
            htmlFor="attachment-input">
            <img
              alt="Add attachment indicator"
              src={imgDocument} />

            <div className={styles.title}>
              Drag and Drop Attachment
            </div>

            <div className={styles.description}>
              or upload by clicking here
            </div>
          </label>

          <input
            className={styles.fileInput}
            disabled={props.disabled}
            id="attachment-input"
            type="file"
            onChange={event => {
              if (!event.target.files || _.isEmpty(event.target.files)) {
                return;
              }

              const file = event.target.files[0];
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                if (!_.isString(reader.result)) {
                  return;
                }

                props.onChange(file.name, reader.result);
              }, false);

              reader.readAsDataURL(file);
            }} />
        </>
        )}
    </div>
  );
};
