import * as React from 'react';
import _ from 'lodash';

import styles from './TabBar.module.css';



export interface ITab {
  id: string;
  displayName: string;
}



export interface ITabBarProps {
  tabDefs: ITab[];
  selectedTabId: string;

  onSelect: (newTabId: string) => void;
}

export const TabBar: React.FC<ITabBarProps> = props => (
  <div className={styles.root}>
    <div className={styles.infoBar}>
      {_.map(props.tabDefs, barType => (
          <Tab
            key={barType.id}
            displayName={barType.displayName}
            onSelect={_.partial(props.onSelect, barType.id)}
            isSelected={barType.id === props.selectedTabId} />
      ))}
    </div>
  </div>
);



interface ITabProps {
  displayName: string;
  isSelected: boolean;

  onSelect: () => void;
}

const Tab: React.FC<ITabProps> = props => {
  const style = props.isSelected ?
    `${styles.tabButton} ${styles.bold}` :
    styles.tabButton;

  return (
    <div>
      <div
        className={style}
        onClick={props.onSelect}>
        {props.displayName}
      </div>

      {props.isSelected && (
        <div className={styles.activeBar} />
      )}
    </div>
  );
};
