import * as React from 'react';

import styles from './StackedInputCell.module.css';



export interface IStackedInputCellProps { }

export const StackedInputCell: React.FC<IStackedInputCellProps> = props => (
  <div className={styles.root}>
    {props.children}
  </div>
);
