import * as React from 'react';

import styles from './TextArea.module.css';



export interface ITextAreaProps {
  cols?: number;
  rows?: number;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  value: string;

  onChange?: (value: string) => void;
}

export const TextArea: React.FC<ITextAreaProps> = props => (
  <textarea
    className={styles.root}
    cols={props.cols}
    disabled={props.disabled}
    placeholder={props.placeholder}
    required={props.required}
    rows={props.rows}
    value={props.value}
    onChange={ev => {
      if (props.onChange) {
        props.onChange(ev.target.value);
      }
    }} />
);
