import _ from 'lodash';

import { useSettingsPageQuery as useBaseQuery } from 'generated/graphqlTypes';



export interface IProjectUser {
  id: string;
  email: string;
  fullName: string;
  profilePictureUrl: string;
}

export interface IPendingInvite {
  id: string;
  inviteCode: string;
  email: string;
}



export interface IProjectType {
  displayName: string;
  name: string;
}



export interface IProjectSettings {
  id: string;
  displayName: string;
  logoUrl: string;
  projectType: IProjectType;
}



interface ISettingsData {
  settings: IProjectSettings;
  users: IProjectUser[];
  projectTypes: IProjectType[];
  pendingInvites: IPendingInvite[];
}



export function useSettingsPageQuery(projectExtId: string) {
  const queryTuple = useBaseQuery({
    variables: {
      projectExtId
    }
  });

  let data: ISettingsData | null = null;

  if (queryTuple.data && queryTuple.data.appMetaData && queryTuple.data.project) {
    const project = queryTuple.data.project;

    const projectTypes = _.map(queryTuple.data.appMetaData.projectTypes, (type): IProjectType => ({
      displayName: type.displayName,
      name: type.name
    }));

    const settings: IProjectSettings = {
      id: project.id,
      displayName: project.displayName,
      logoUrl: project.logoUrl,
      projectType: {
        displayName: project.projectType.displayName,
        name: project.projectType.name,
      }
    };

    const users = _.map(project.users, (user): IProjectUser => ({
      id: user.id,
      email: user.email,
      fullName: user.fullName,
      profilePictureUrl: user.profilePictureUrl
    }));

    const pendingInvites = _.map(project.pendingInvites, (invite): IPendingInvite => ({
      email: invite.email,
      id: invite.id,
      inviteCode: invite.inviteCode
    }));

    data = {
      settings,
      users,
      pendingInvites,
      projectTypes
    };
  }

  return {
    ...queryTuple,
    data
  };
}
