import _ from 'lodash';

import {
  CredentialPageDocument,
  useCredentialPageQuery as useBaseQuery,
  useDeleteCredentialMutation as useBaseDeleteMutation
} from 'generated/graphqlTypes';



export interface ICredential {
  id: string;
  serviceName: string;
  serviceUrl: string;
  username: string;
  password: string;
}



export function useCredentialPageQuery(projectExtId: string) {
  const queryTuple = useBaseQuery({
    variables: {
      projectExtId
    }
  });

  let credentials: ICredential[] = [];
  if (queryTuple.data && queryTuple.data.project) {
    credentials = _.map(queryTuple.data.project.credentials, (credential): ICredential => ({
      id: credential.id,
      serviceName: credential.serviceName,
      serviceUrl: credential.serviceUrl,
      username: credential.username,
      password: credential.password
    }));
  }

  return {
    ...queryTuple,
    data: credentials
  };
}



export function useDeleteCredentialMutation(projectExtId: string) {
  const [fnRunMutation, mutationTuple] = useBaseDeleteMutation({
    refetchQueries: [
      {
        query: CredentialPageDocument,
        variables: {
          projectExtId
        }
      }
    ],
    awaitRefetchQueries: true
  });

  function fnWrappedMutation(credentialId: string) {
    return fnRunMutation({
      variables: {
        credentialId
      }
    });
  }

  return [
    fnWrappedMutation,
    mutationTuple
  ] as const;
}
