import _ from 'lodash';
import * as React from 'react';

import styles from './InputField.module.css';



export type IRequiredMode = 'notRequired' | 'required';

export interface IInputFieldProps {
  type: 'email' | 'text' | 'password' | 'number' | 'url';
  styleType?: 'normal' | 'menu';
  fieldName?: string;
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  extraClassName?: string;
  overrideClassName?: string;
  autoComplete?: boolean;
  required?: boolean;
  disableValidateOnChange?: boolean;
  disabled?: boolean;

  /**
   * Returns an error message to display to the user based on the input.
   * If the input is valid, returns the empty string.
   */
  fnValidate: (value: string, required?: boolean) => string;
}

/**
 * Intended to be a generic text field control that is simply used as a partial
 * implementation of other controls that require a text input control.
 */
export class InputField extends React.Component<IInputFieldProps> {

  private __refInput = React.createRef<HTMLInputElement>();



  public componentDidUpdate = (prevProps: IInputFieldProps) => {
    if (this.props.value !== prevProps.value) {
      _.defer(() => this.validate());
    }
  };



  public setCustomValidity = (errorMessage: string) => {
    if (this.__refInput.current) {
      this.__refInput.current.setCustomValidity(errorMessage);
    }
  };



  public render() {
    let className = `${styles.root} ${this.props.extraClassName || ''}`;
    if (this.props.styleType === 'menu') {
      className += ` ${styles.menu}`;
    }
    if (this.props.overrideClassName) {
      className = this.props.overrideClassName;
    }

    let autoComplete = 'on';
    if (this.props.hasOwnProperty('autoComplete')) {
      autoComplete = this.props.autoComplete ? 'on' : 'new-password';
    }

    return (
      <input
        ref={this.__refInput}
        type={this.props.type}
        className={className}
        name={this.props.fieldName}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.__handleChange}
        autoComplete={autoComplete}
        disabled={this.props.disabled}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur} />
    );
  }



  private __handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.props.onChange(value);
  };



  public validate = (immediateValue?: string) => {
    const elInput = this.__refInput.current;
    if (!elInput) {
      return false;
    }

    const value = !_.isUndefined(immediateValue) ?
      immediateValue.trim() :
      this.props.value.trim();
    const errorMessage = this.props.fnValidate(value, this.props.required);
    elInput.setCustomValidity(errorMessage);
    return !errorMessage;
  };

}
