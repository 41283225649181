import {
  Schema,
  ValidationError
} from 'jsonschema';
import * as _ from 'lodash';
import {
  checkAgainstSchema, nonEmptyStringSchema
} from './schema';



const firebaseSchema: Schema = {
  type: 'object',
  properties: {
    apiKey: nonEmptyStringSchema,
    authDomain: nonEmptyStringSchema
  },
  required: [
    'apiKey',
    'authDomain'
  ]
};

const apiSchema: Schema = {
  type: 'object',
  properties: {
    url: nonEmptyStringSchema,
    pathToUploadEndpoint: nonEmptyStringSchema,
    pathToGraphQlEndpoint: nonEmptyStringSchema
  },
  required: [
    'url',
    'pathToUploadEndpoint',
    'pathToGraphQlEndpoint'
  ]
};

const environmentSchema: Schema = {
  type: 'object',
  properties: {
    environmentName: nonEmptyStringSchema,
    firebase: firebaseSchema,
    api: apiSchema
  },
  required: [
    'environmentName',
    'firebase',
    'api'
  ]
};

export interface IEnvironment {
  environmentName: string;
  firebase: {
    apiKey: string;
    authDomain: string;
  };
  api: {
    url: string;
    pathToUploadEndpoint: string;
    pathToGraphQlEndpoint: string;
  };
}

let __environment: IEnvironment;



export function init(): IEnvironment {
  const environment = require('../config/env.json');

  const validationErrors: ValidationError[] = [];
  if (!checkAgainstSchema(environment, environmentSchema, validationErrors)) {
    console.error(_.repeat('*', 80));
    console.error("ENVIRONMENT CONFIG FILE CONTENTS ARE INVALID!");

    const errors = _.reduce(validationErrors, (accumulator, validationError) => {
      accumulator[validationError.property] = validationError.message;

      return accumulator;
    }, {} as _.Dictionary<string>);
    console.table(errors);
    console.error(_.repeat('*', 80));

    throw new Error(JSON.stringify(errors));
  }

  __environment = environment;

  return environment;
}



export function getConfig() {
  if (!__environment) {
    throw new Error('ENVIRONMENT_NOT_INITIALIZED');
  }

  return __environment;
}



export function getVersion() {
  let versionFile: any | null = null;

  try {
    versionFile = require('config/version.json');
  } catch (e) {
    versionFile = null;
  }

  if (versionFile !== null && versionFile.version) {
    return versionFile.version;
  } else {
    return null;
  }
}
