import * as React from 'react';
import _ from 'lodash';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvidedDragHandleProps
} from 'react-beautiful-dnd';
import {
  UploadAttachmentModal,
  IFile
} from 'components/shared/UploadAttachmentModal/UploadAttachmentModal';

import * as dataService from 'components/pages/MilestonePage/dataService';
import { Subject } from 'rxjs';
import { RouteComponentProps } from 'react-router';
import { PageTemplate } from 'components/pages/PageTemplate/PageTemplate';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { PaymentMilestone } from 'components/pages/MilestonePage/PaymentMilestone/PaymentMilestone';
import { PaymentModal } from 'components/pages/MilestonePage/Modals/PaymentModal/PaymentModal';
import { AddActionModal } from 'components/pages/MilestonePage/Modals/AddActionModal/AddActionModal';
import { Button } from 'components/shared/Button/Button';
import { AddMilestoneModal } from './Modals/AddMilestoneModal/AddMilestoneModal';
import { ProjectMilestone } from './ProjectMilestone/ProjectMilestone';
import { SaveProjectMilestoneModal } from './Modals/SaveProjectMilestoneModal/SaveProjectMilestoneModal';
import { DeleteProjectModal } from './Modals/DeleteProjectModal/DeleteProjectModal';
import { SubmitMilestoneModal } from './Modals/SubmitMilestoneModal/SubmitMilestoneModal';

import styles from './MilestonePage.module.css';

import imgAddMilestone from 'assets/images/addMilestone.svg';



export interface IMilestoneStatus {
  displayName: string;
  name: string;
}



export interface IMilestonePageProps extends RouteComponentProps<{
  projectExtId?: string;
  milestoneId?: string;
}> {}

export const MilestonePage: React.FC<IMilestonePageProps> = props => {
  const extId = props.match.params.projectExtId || '';
  const scrollToMilestoneId = props.match.params.milestoneId || '';
  const refRequestOffsetTopSubject = React.useRef(new Subject<string>());
  const queryTuple = dataService.useMilestonePageQuery(extId);
  const [lastMilestoneClicked, setLastMilestoneClicked] = React.useState<dataService.IMilestoneData>(dataService.milestoneShell);

  const [isAddMilestoneModalVisible, setIsAddMilestoneModalVisible] = React.useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = React.useState(false);
  const [isAddNewActionModalVisible, setIsAddNewActionModalVisible] = React.useState(false);
  const [isSaveProjectModalVisible, setIsSaveProjectModalVisible] = React.useState(false);
  const [isDeleteProjectModalVisible, setIsDeleteProjectModalVisible] = React.useState(false);
  const [isAddAttachmentModalVisible, setIsAddAttachmentModalVisible] = React.useState(false);
  const [isSubmitMilestoneModalVisible, setIsSubmitMilestoneModalVisible] = React.useState(false);

  const [fnDeleteAction, deleteActionTuple] = dataService.useDeleteActionMutation(extId);
  const [fnCreateAction, createActionTuple] = dataService.useCreateActionMutation(extId);
  const [fnCreateProjectMilestone, createProjectMilestoneTuple] = dataService.useCreateProjectMilestoneMutation(extId);
  const [fnCreatePaymentMilestone, createPaymentMilestoneTuple] = dataService.useCreatePaymentMilestoneMutation(extId);
  const [fnCreateAuditEvent, createAuditEventTuple] = dataService.useCreateAuditEventMutation(extId);
  const [fnSaveAction, saveActionTuple] = dataService.useSaveActionMutation(extId);
  const [fnSavePaymentMilestone, savePaymentMilestoneTuple] = dataService.useSavePaymentMilestoneMutation(extId);
  const [fnSaveProjectMilestone, saveProjectMilestoneTuple] = dataService.useSaveProjectMilestoneMutation(extId);
  const [fnDeleteProjectMilestone, deleteProjectMilestoneTuple] = dataService.useDeleteProjectMilestoneMutation(extId);
  const [fnReorderMilestone, reorderMilestoneTuple] = dataService.useReorderMilestoneMutation(extId);
  const [fnUploadMilestoneLinkAttachment, uploadMilestoneLinkAttachmentTuple] = dataService.useUploadMilestoneLinkAttachmentMutation(extId);
  const [fnUploadMilestoneFileAttachment, uploadMilestoneFileAttachmentTuple] = dataService.useUploadMilestoneFileAttachmentMutation(extId);

  const isLoading = _.some([
    deleteActionTuple,
    createActionTuple,
    createProjectMilestoneTuple,
    createPaymentMilestoneTuple,
    createAuditEventTuple,
    saveActionTuple,
    savePaymentMilestoneTuple,
    saveProjectMilestoneTuple,
    deleteProjectMilestoneTuple,
    reorderMilestoneTuple,
    uploadMilestoneLinkAttachmentTuple,
    uploadMilestoneFileAttachmentTuple,
    queryTuple
  ], tuple => tuple.loading);



  React.useEffect(() => {
    if (!isLoading && scrollToMilestoneId) {
      refRequestOffsetTopSubject.current.next(scrollToMilestoneId);
    }
  }, [isLoading, scrollToMilestoneId, refRequestOffsetTopSubject]);



  function handleRespondOffsetTop(offsetTop: number) {
    window.scrollTo(0, offsetTop);
  }



  function handleCreateProjectMilestone(displayName: string, description: string) {
    return fnCreateProjectMilestone({
      displayName,
      description,
      projectExtId: extId
    });
  }



  function handleCreatePaymentMilestone(paymentAmountCents: number, paymentUrl: string) {
    return fnCreatePaymentMilestone({
      paymentAmountCents,
      paymentUrl,
      projectExtId: extId
    });
  }



  function handleUploadLinkAttachment(displayName: string, linkUrl: string) {
    return fnUploadMilestoneLinkAttachment({
      displayName,
      linkUrl,
      milestoneId: lastMilestoneClicked.id
    });
  }



  function handleUploadFileAttachment(displayName: string, fileData: IFile) {
    return fnUploadMilestoneFileAttachment({
      displayName,
      encodedFile: {
        encodedFileInput: {
          dataUrl: fileData.dataUrl,
          fileExtension: fileData.originalFilename
        },
        filename: fileData.originalFilename
      },
      milestoneId: lastMilestoneClicked.id
    });
  }



  function savePaymentMilestone(paymentAmountCents?: number, paymentUrl?: string, status?: string) {
    return fnSavePaymentMilestone({
      milestoneId: lastMilestoneClicked.id,
      paymentAmountCents,
      paymentUrl,
      status
    });
  }



  function handleCreateAction(displayName: string, description: string) {
    fnCreateAction({
      description,
      displayName,
      milestoneId: lastMilestoneClicked.id
    });
  }



  function handleCreateAuditEvent(description: string, milestoneId: string) {
    fnCreateAuditEvent({
      description,
      milestoneId
    });
  }



  function handleReleaseMilestone(milestoneId: string) {
    fnSaveProjectMilestone({
      milestoneId,
      status: 'submitted'
    });
  }



  function genericMilestone2SpecificMilestone(milestone: dataService.IMilestoneData, provided: DraggableProvidedDragHandleProps | undefined) {
    if (milestone.type === 'PaymentMilestone') {
      return (
        <PaymentMilestone
          milestone={milestone}
          draggableProps={provided}
          userAuthorizedAsAdmin={(queryTuple.data && queryTuple.data.isSuperAdmin) || false}
          requestOffsetTopObservable={refRequestOffsetTopSubject.current.asObservable()}
          onRespondOffsetTop={handleRespondOffsetTop}
          onPayment={() => {
            setIsPaymentModalVisible(!isPaymentModalVisible);
            setLastMilestoneClicked(milestone);
          }} />
      );
    }

    if (milestone.type === 'ProjectMilestone') {
      return (
        <ProjectMilestone
          milestone={milestone}
          draggableProps={provided}
          userAuthorizedAsAdmin={(queryTuple.data && queryTuple.data.isSuperAdmin) || false}
          currentProfilePic={milestone.loggedInUserPic}
          requestOffsetTopObservable={refRequestOffsetTopSubject.current.asObservable()}
          onRespondOffsetTop={handleRespondOffsetTop}
          onAddAction={() => {
            setLastMilestoneClicked(milestone);
            setIsAddNewActionModalVisible(true);
          }}
          onAddNote={(note: string) => handleCreateAuditEvent(note, milestone.id)}
          onSaveAction={fnSaveAction}
          onDeleteAction={fnDeleteAction}
          onClickAddAttachment={() => {
            setLastMilestoneClicked(milestone);
            setIsAddAttachmentModalVisible(true);
          }}
          onSubmitMilestone={() => {
            setLastMilestoneClicked(milestone);
            setIsSubmitMilestoneModalVisible(true);
          }}
          onSaveMilestone={() => {
            setLastMilestoneClicked(milestone);
            setIsSaveProjectModalVisible(true);
          }}
          onReleaseMilestone={() => {
            handleReleaseMilestone(milestone.id);
          }}
          onDeleteMilestone={() => {
            setLastMilestoneClicked(milestone);
            setIsDeleteProjectModalVisible(true);
          }} />
      );
    }

    // Milestone isn't a payment or project type
    return null;
  }



  function getOrderedIds(milestonesToReorder: dataService.IMilestoneData[], startIndex: number, endIndex: number) {
    if (_.isEmpty(milestonesToReorder)) {
      return null;
    }

    const clonedMilestones = _.cloneDeep(milestonesToReorder);
    const movedMilestone = _.pullAt(clonedMilestones, [startIndex]);
    clonedMilestones.splice(endIndex, 0, movedMilestone[0]);
    return _.map(clonedMilestones, 'id');
  }



  function handleDragEnd(milestonesToReorder: dataService.IMilestoneData[], result: DropResult) {
    if (!result.destination) {
      return;
    }

    const orderedMilestoneIds = getOrderedIds(milestonesToReorder, result.source.index, result.destination.index);
    if (orderedMilestoneIds) {
      fnReorderMilestone({
        orderedMilestoneIds,
        projectExtId: extId
      });
    }
  }



  return (
    <>
      <LoadingOverlay
        isVisible={isLoading}
        text="Loading" />

      <PaymentModal
        isVisible={isPaymentModalVisible}
        onClose={() => setIsPaymentModalVisible(!isPaymentModalVisible)}
        onClickPayment={() => {
          setIsPaymentModalVisible(!isPaymentModalVisible);
          savePaymentMilestone(undefined, undefined, 'accepted');
        }} />

      <AddActionModal
        isVisible={isAddNewActionModalVisible}
        onCreateAction={handleCreateAction}
        onClose={() => setIsAddNewActionModalVisible(!isAddNewActionModalVisible)} />

      <AddMilestoneModal
        isVisible={isAddMilestoneModalVisible}
        onCreateProjectMilestone={handleCreateProjectMilestone}
        onCreatePaymentMilestone={handleCreatePaymentMilestone}
        onClose={() => setIsAddMilestoneModalVisible(!isAddMilestoneModalVisible)} />

      <UploadAttachmentModal
        isVisible={isAddAttachmentModalVisible}
        loading={isLoading}
        onUploadLinkAttachment={handleUploadLinkAttachment}
        onUploadFileAttachment={handleUploadFileAttachment}
        onClose={() => setIsAddAttachmentModalVisible(!isAddAttachmentModalVisible)} />

      <SaveProjectMilestoneModal
        isVisible={isSaveProjectModalVisible}
        onClose={() => setIsSaveProjectModalVisible(!isSaveProjectModalVisible)}
        milestoneId={lastMilestoneClicked.id}
        name={lastMilestoneClicked.type === 'ProjectMilestone' ? lastMilestoneClicked.title : ""}
        description={lastMilestoneClicked.type === 'ProjectMilestone' ? lastMilestoneClicked.description : ""}
        onSubmit={fnSaveProjectMilestone} />

      <DeleteProjectModal
        isVisible={isDeleteProjectModalVisible}
        onClose={() => setIsDeleteProjectModalVisible(!isDeleteProjectModalVisible)}
        onSubmit={() => {
          setIsDeleteProjectModalVisible(false);
          fnDeleteProjectMilestone(lastMilestoneClicked.id);
        }} />

      <SubmitMilestoneModal
        isVisible={isSubmitMilestoneModalVisible}
        onClose={() => setIsSubmitMilestoneModalVisible(!isSubmitMilestoneModalVisible)}
        onSubmit={() => {
          fnSaveProjectMilestone({
            milestoneId: lastMilestoneClicked.id,
            status: 'accepted'
          });
        }} />

      <PageTemplate
        {...props}
        requiresUser
        toolbarType='projectToolbar'>
        <div className={styles.content}>
          <div className={styles.titleBar}>
            <h1 className={styles.milestoneTitle}>
              Milestones
            </h1>

            {queryTuple.data && queryTuple.data.isSuperAdmin && (
              <Button onClick={() => setIsAddMilestoneModalVisible(true)}>
                Create a New Milestone
              </Button>
            )}
          </div>

          <DragDropContext onDragEnd={_.partial(handleDragEnd, queryTuple.data.milestones)}>
            <Droppable droppableId="droppable">
              {droppableProvided => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}>
                  {_.map(queryTuple.data.milestones, (milestone, index) => {
                    return (
                      <Draggable
                        isDragDisabled={milestone.status.name !== 'pending'}
                        key={milestone.id}
                        draggableId={milestone.id}
                        index={index}>
                        {draggableProvided => (
                          <div
                            key={milestone.id}
                            className={styles.milestoneContainer}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}>
                            {genericMilestone2SpecificMilestone(milestone, draggableProvided.dragHandleProps)}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {_.isEmpty(queryTuple.data.milestones) && !queryTuple.loading && (
            <EmptyMilestoneContent
              isSuperAdmin={(queryTuple.data && queryTuple.data.isSuperAdmin) || false} />
          )}
        </div>
      </PageTemplate>
    </>
  );
};



interface IEmptyMilestoneProps {
  isSuperAdmin?: boolean;
}

const EmptyMilestoneContent: React.FC<IEmptyMilestoneProps> = props => (
  <div>
    <div className={styles.emptyBox}>
      <div className={styles.imageContainer}>
        <img
          src={imgAddMilestone}
          alt="Add a new Milestone" />
      </div>

      <div className={styles.emptyText}>
        No Milestones Yet
      </div>

      {props.isSuperAdmin && (
        <div className={styles.emptyDesc}>
          Create a new milestone at the button above.
        </div>
      )}
    </div>
  </div>
);
