import * as React from 'react';
import _ from 'lodash';
import * as dataService from 'components/pages/ProjectPickerPage/dataService';
import { RouteComponentProps } from 'react-router';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { NewProjectModal } from './NewProjectModal/NewProjectModal';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { NewProjectCard } from './NewProjectCard/NewProjectCard';
import { ProjectCard } from 'components/shared/ProjectCard/ProjectCard';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';

import styles from './ProjectPickerPage.module.css';



export interface IProjectPickerPageProps extends RouteComponentProps { }



export const ProjectPickerPage: React.FC<IProjectPickerPageProps> = props => {
  const [isProjectModalVisible, setIsProjectModalVisible] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const queryTuple = dataService.useProjectPickerPageQuery(filterText);
  const projects = queryTuple.data ? queryTuple.data.projects : [];
  return (
    <>
      <LoadingOverlay
        isVisible={queryTuple.loading}
        text="Loading" />

      {queryTuple.data && (
        <NewProjectModal
          isVisible={isProjectModalVisible}
          onClose={() => setIsProjectModalVisible(!isProjectModalVisible)}
          projectTypes={queryTuple.data.projectTypes} />
      )}

      <PageTemplate
        {...props}
        requiresUser
        requiresAdmin
        toolbarType="pickerToolbar">
        <div className={styles.content}>
          <div className={styles.topBar}>
            <div className={styles.pageTitle}>
              Projects
            </div>

            <SearchBar
              filterText={filterText}
              onChange={setFilterText} />
          </div>

          <div className={styles.pageDescription}>
            Active Projects
          </div>

          {!queryTuple.loading && (
            <div className={styles.projects}>
              <div className={styles.projectCardContainer}>
                <NewProjectCard
                  onClick={() => setIsProjectModalVisible(true)} />
              </div>

              {_.map(projects, project => (
                <div
                  key={project.extId}
                  className={styles.projectCardContainer}>
                  <ProjectCard
                    id={project.id}
                    extId={project.extId}
                    projectType={project.projectType.displayName}
                    displayName={project.displayName}
                    logoUrl={project.logoUrl} />
                </div>
              ))}
            </div>
          )}
        </div>
      </PageTemplate>
    </>
  );
};
