import * as React from 'react';
import {
  ISaveActionArgs,
  IActionCardData,
  IMilestoneStatusType
} from 'components/pages/MilestonePage/dataService';
import { Button } from 'components/shared/Button/Button';

import styles from './ActionCard.module.css';

import imgEmptyCheckbox from 'assets/images/emptyCheckbox.svg';
import imgFilledCheckbox from 'assets/images/filledCheckbox.svg';
import imgRedX from 'assets/images/redX.svg';




export interface IActionCardProps {
  action: IActionCardData;
  milestoneStatus: IMilestoneStatusType;
  userAuthorizedAsAdmin: boolean;

  onCancel: () => void;
  onUserSubmit: (actionInput: ISaveActionArgs) => void;
}

export const ActionCard: React.FC<IActionCardProps> = props => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div>
        <h1 className={styles.title}>
          {props.action.displayName}
        </h1>

        <div className={styles.description}>
          {props.action.description}
        </div>
      </div>

      <div className={styles.checkbox}>
        {(props.action.status.name === 'new' ||
          props.action.status.name === 'rejected') &&
          !props.userAuthorizedAsAdmin && (
            <img
              src={imgEmptyCheckbox}
              onClick={() => props.onUserSubmit({
                actionId: props.action.id,
                statusName: 'submitted'
              })}
              alt="Empty check box" />
          )
        }

        {(props.action.status.name === 'submitted' ||
          props.action.status.name === 'approved') &&
          !props.userAuthorizedAsAdmin && (
            <img
              src={imgFilledCheckbox}
              alt="filled checkbox" />
          )
        }

        {props.userAuthorizedAsAdmin &&
          props.milestoneStatus.name !== 'accepted' &&
          props.action.status.name === 'new' && (
          <Button
            onClick={props.onCancel}
            withoutStyle>
            <img
              src={imgRedX}
              alt="Cancel" />
          </Button>
        )}
      </div>
    </div>

  {!props.userAuthorizedAsAdmin && props.action.status.name === 'rejected' && (
      <div className={styles.rejectedText}>
        <div>Your submission has been rejected.</div>
        <div>Please review your submission.</div>
      </div>
    )}

    {props.userAuthorizedAsAdmin && props.action.status.name === 'submitted' && (
      <div className={styles.adminAction}>
        <Button
          withoutStyle
          extraClassName={styles.acceptAction}
          onClick={() => props.onUserSubmit({
            actionId: props.action.id,
            statusName: 'approved'
          })}>

          <div className={styles.buttonText}>
            Accept
          </div>
        </Button>

        <Button
          withoutStyle
          extraClassName={styles.denyAction}
          onClick={() => props.onUserSubmit({
            actionId: props.action.id,
            statusName: 'rejected'
          })}>

          <div className={styles.buttonText}>
            Reject
          </div>
        </Button>
      </div>
    )}
  </div>
);
