import {
  SettingsPageDocument,
  useLinkSiteUserToProjectMutation as useBaseMutation
} from 'generated/graphqlTypes';



export function useLinkSiteUserToProjectMutation(projectExtId: string) {
  const [fnMutate, mutationTuple] = useBaseMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SettingsPageDocument,
        variables: {
          projectExtId
        }
      }
    ]
  });

  function fnWrappedMutate(email: string) {
    return fnMutate({
      variables: {
        projectExtId,
        email
      }
    });
  }

  return [
    fnWrappedMutate,
    mutationTuple
  ] as const;
}
