import { genInputFieldComponent } from "./InputFieldFactory";



const urlRegex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/);



export const UrlField = genInputFieldComponent("text", (value, required) => {
  const trimmedValue = value.trim();

  if (required && trimmedValue.length === 0) {
    return "Required.";
  }

  if (!urlRegex.test(trimmedValue)) {
    return "Not a valid url.";
  }

  return "";
});
