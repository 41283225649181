import { genInputFieldComponent } from "./InputFieldFactory";



export const LinkNameInputField = genInputFieldComponent("text", (value, required) => {
  const trimmedValue = value.trim();

  if (required && trimmedValue.length === 0) {
    return "Required.";
  }
  return "";
});
