import React from 'react';

import styles from './ProfilePicture.module.css';

import imgGenericUser from 'assets/images/anonymousUser.svg';



export interface IProfilePictureProps {
  profilePictureUrl: string;
}

export const ProfilePicture: React.FC<IProfilePictureProps> = props => (
  <img
    alt="User profile"
    src={props.profilePictureUrl || imgGenericUser}
    className={styles.root} />
);
