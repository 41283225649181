import React from "react";
import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from "components/shared/BaseModalTemplate/BaseModalTemplate";
import { ISaveProjectMilestoneArgs } from "../../dataService";
import { ProjectMilestoneForm } from "../AddMilestoneModal/ProjectMilestoneForm/ProjectMilestoneForm";

import styles from './SaveProjectMilestoneModal.module.css';



export interface ISaveProjectMilestoneModalProps extends IBaseModalTemplateProps {
  milestoneId: string;
  name: string;
  description: string;

  onSubmit: (args: ISaveProjectMilestoneArgs) => void;
}

export const SaveProjectMilestoneModal: React.FC<ISaveProjectMilestoneModalProps> = props => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    setName(props.name);
    setDescription(props.description);
  }, [props.name && props.description]);



  function onSuccessfulSubmit() {
    props.onSubmit({
      milestoneId: props.milestoneId,
      description,
      displayName: name,
    });
    closeForm();
  }

  function closeForm() {
    props.onClose();
  }

  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={closeForm}>
      <div className={styles.root}>
        <h1 className={styles.title}>
          Edit Milestone
        </h1>

        <p className={styles.desc}>
          Fill in the information below to edit a milestone.
        </p>

        <ProjectMilestoneForm
          name={name}
          description={description}
          buttonText="Save Milestone"
          onSetName={setName}
          onSetDesc={setDescription}
          onSuccessfulSubmit={onSuccessfulSubmit}
          allowEmpty />
      </div>
    </BaseModalTemplate>
  );
};
