import * as React from 'react';
import { Button } from 'components/shared/Button/Button';

import styles from './BaseTabContent.module.css';



interface IEmptyContentDef {
  picture: string;
  title: string;
  desc: string;
}



export interface IBaseTabContentProps {
  showEmptyCase: boolean;
  emptyContentDef: IEmptyContentDef;
  disableAddButton?: boolean;

  onClick: () => void;
}



export const BaseTabContent: React.FC<IBaseTabContentProps> = props => (
  <div className={styles.root}>
    {!(props.showEmptyCase) && (
      <div className={styles.itemContainer}>
        {props.children}

        {!props.disableAddButton && (
          <Button
            onClick={props.onClick}
            extraClassName={styles.addAnotherButton}>
            Add Another +
          </Button>
        )}
      </div>
    )}

    {props.showEmptyCase && (
      <div>
        <div className={styles.emptyContent}>
          <img
            src={props.emptyContentDef.picture}
            className={styles.emptyTitle}
            alt="no user profile" />

          <h1 className={styles.emptyTitle}>
            {props.emptyContentDef.title}
          </h1>

          <div className={styles.emptyDesc}>
            {props.emptyContentDef.desc}
          </div>

          {!props.disableAddButton && (
            <Button
              extraClassName={styles.addAnotherButtonEmpty}
              onClick={props.onClick}>
              Add +
            </Button>
          )}
        </div>
      </div>
    )}
  </div>
);
