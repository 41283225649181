import * as React from 'react';

import { Button } from 'components/shared/Button/Button';

import styles from './NotificationCard.module.css';

import imgSideChevron from 'assets/images/sideChevron.svg';



export interface INotificationCardProps {
  id: string;
  isRead: boolean;
  title: string;
  description: string;
  projectLogoUrl: string;
  projectStatus: string;

  onClick: (id: string) => void;
}

export const NotificationCard: React.FC<INotificationCardProps> = props => (
  <Button
    onClick={() => props.onClick(props.id)}
    withoutStyle
    extraClassName={styles.root}>
    <div className={styles.isReadContainer}>
      {!props.isRead && (
        <div className={styles.isReadIndicator} />
      )}
    </div>

    <img
      src={props.projectLogoUrl}
      className={styles.logoUrl} />

    <div className={styles.content}>
      <h2 className={styles.title}>
        {props.title}
      </h2>

      <div className={styles.description}>
        {props.description}
      </div>

      <div className={styles.projectDesc}>
        {props.projectStatus}
      </div>
    </div>

    <img
      className={styles.arrow}
      src={imgSideChevron} />
  </Button>
);
