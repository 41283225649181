import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A base-64 encoded file. */
  EncodedFileInput: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};


export type Action = {
   __typename?: 'Action',
  id: Scalars['ID'],
  metaCreated: Scalars['String'],
  displayName: Scalars['String'],
  description: Scalars['String'],
  status: ActionStatus,
};

export type ActionStatus = {
   __typename?: 'ActionStatus',
  id: Scalars['ID'],
  name: Scalars['String'],
  displayName: Scalars['String'],
};

export type AppMetaData = {
   __typename?: 'AppMetaData',
  projectTypes: Array<ProjectType>,
};

export type Attachment = {
   __typename?: 'Attachment',
  id: Scalars['ID'],
  metaCreated: Scalars['String'],
  type: AttachmentType,
  displayName: Scalars['String'],
  description: Scalars['String'],
  resourceUrl: Scalars['String'],
};

export type AttachmentType = {
   __typename?: 'AttachmentType',
  id: Scalars['ID'],
  name: Scalars['String'],
  displayName: Scalars['String'],
};

export type AuditEvent = {
   __typename?: 'AuditEvent',
  id: Scalars['ID'],
  metaCreated: Scalars['String'],
  description: Scalars['String'],
  creator: SiteUser,
  project: Project,
  milestone?: Maybe<Milestone>,
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CreateActionInput = {
  displayName: Scalars['String'],
  description: Scalars['String'],
  milestoneId: Scalars['ID'],
};

export type CreateAuditEventInput = {
  description: Scalars['String'],
  milestoneId: Scalars['ID'],
};

export type CreateCredentialInput = {
  projectExtId: Scalars['ID'],
  serviceName: Scalars['String'],
  serviceUrl: Scalars['String'],
  username: Scalars['String'],
  password: Scalars['String'],
};

export type CreatePaymentMilestoneInput = {
  paymentAmountCents: Scalars['Int'],
  paymentUrl: Scalars['String'],
  projectExtId: Scalars['ID'],
};

export type CreateProjectInput = {
  appDisplayName: Scalars['String'],
  encodedFileInput: Scalars['EncodedFileInput'],
  projectType: Scalars['String'],
};

export type CreateProjectMilestoneInput = {
  displayName: Scalars['String'],
  description: Scalars['String'],
  projectExtId: Scalars['ID'],
};

export type Credential = {
   __typename?: 'Credential',
  id: Scalars['ID'],
  serviceName: Scalars['String'],
  serviceUrl: Scalars['String'],
  username: Scalars['String'],
  password: Scalars['String'],
};


export type EncodedFileInputWithFileName = {
  filename: Scalars['String'],
  encodedFileInput: Scalars['EncodedFileInput'],
};

export type Milestone = PaymentMilestone | ProjectMilestone;

export type MilestoneStatus = {
   __typename?: 'MilestoneStatus',
  id: Scalars['ID'],
  name: Scalars['String'],
  displayName: Scalars['String'],
};

export type Mutation = {
   __typename?: 'Mutation',
  reorderMilestones?: Maybe<Project>,
  createProject?: Maybe<Project>,
  saveSiteUser?: Maybe<SiteUser>,
  createAction?: Maybe<Action>,
  createProjectMilestone?: Maybe<ProjectMilestone>,
  createPaymentMilestone?: Maybe<PaymentMilestone>,
  uploadAttachment?: Maybe<Attachment>,
  registerSiteUser?: Maybe<SiteUser>,
  createCredential?: Maybe<Credential>,
  createAuditEvent?: Maybe<AuditEvent>,
  inviteUserToProject: Scalars['Boolean'],
  deleteAction?: Maybe<Scalars['ID']>,
  deleteProjectMilestone?: Maybe<Scalars['ID']>,
  deleteCredential?: Maybe<Scalars['ID']>,
  saveAction?: Maybe<Action>,
  saveProject?: Maybe<Project>,
  saveProjectMilestone?: Maybe<ProjectMilestone>,
  savePaymentMilestone?: Maybe<PaymentMilestone>,
  saveCredential?: Maybe<Credential>,
  saveNotification?: Maybe<Notification>,
  markAllNotificationsAsRead?: Maybe<Scalars['Boolean']>,
};


export type MutationReorderMilestonesArgs = {
  reorderInput: ReorderMilestoneInput
};


export type MutationCreateProjectArgs = {
  projectInput: CreateProjectInput
};


export type MutationSaveSiteUserArgs = {
  userInput: SaveSiteUserInput
};


export type MutationCreateActionArgs = {
  actionInput: CreateActionInput
};


export type MutationCreateProjectMilestoneArgs = {
  milestoneInput: CreateProjectMilestoneInput
};


export type MutationCreatePaymentMilestoneArgs = {
  milestoneInput: CreatePaymentMilestoneInput
};


export type MutationUploadAttachmentArgs = {
  input: UploadAttachmentInput
};


export type MutationRegisterSiteUserArgs = {
  userInput: RegisterSiteUserInput
};


export type MutationCreateCredentialArgs = {
  credentialInput: CreateCredentialInput
};


export type MutationCreateAuditEventArgs = {
  eventInput: CreateAuditEventInput
};


export type MutationInviteUserToProjectArgs = {
  projectExtId: Scalars['ID'],
  email: Scalars['String']
};


export type MutationDeleteActionArgs = {
  actionId: Scalars['ID']
};


export type MutationDeleteProjectMilestoneArgs = {
  milestoneId: Scalars['String']
};


export type MutationDeleteCredentialArgs = {
  credentialId: Scalars['ID']
};


export type MutationSaveActionArgs = {
  actionInput: SaveActionInput
};


export type MutationSaveProjectArgs = {
  projectInput: SaveProjectInput
};


export type MutationSaveProjectMilestoneArgs = {
  milestoneInput: SaveProjectMilestoneInput
};


export type MutationSavePaymentMilestoneArgs = {
  milestoneInput: SavePaymentMilestoneInput
};


export type MutationSaveCredentialArgs = {
  credentialInput: SaveCredentialInput
};


export type MutationSaveNotificationArgs = {
  notificationInput: SaveNotificationInput
};

export type Notification = {
   __typename?: 'Notification',
  id: Scalars['ID'],
  isRead: Scalars['Boolean'],
  metaCreated: Scalars['String'],
  description: Scalars['String'],
  shortDescription: Scalars['String'],
  auditEvent: AuditEvent,
};

export type PaymentMilestone = {
   __typename?: 'PaymentMilestone',
  id: Scalars['ID'],
  ranking: Scalars['Int'],
  status: MilestoneStatus,
  paymentAmountCents: Scalars['Int'],
  paymentUrl: Scalars['String'],
};

export type Project = {
   __typename?: 'Project',
  id: Scalars['ID'],
  extId: Scalars['String'],
  displayName: Scalars['String'],
  logoUrl: Scalars['String'],
  projectType: ProjectType,
  milestones: Array<Milestone>,
  attachments: Array<Attachment>,
  credentials: Array<Credential>,
  users: Array<ProjectUser>,
  pendingInvites: Array<UserInvite>,
};

export type ProjectMilestone = {
   __typename?: 'ProjectMilestone',
  id: Scalars['ID'],
  ranking: Scalars['Int'],
  status: MilestoneStatus,
  displayName: Scalars['String'],
  description: Scalars['String'],
  actions: Array<Action>,
  attachments: Array<Attachment>,
  logs: Array<AuditEvent>,
};

export type ProjectType = {
   __typename?: 'ProjectType',
  id: Scalars['ID'],
  name: Scalars['String'],
  displayName: Scalars['String'],
};

export type ProjectUser = {
   __typename?: 'ProjectUser',
  id: Scalars['ID'],
  email: Scalars['String'],
  fullName: Scalars['String'],
  profilePictureUrl: Scalars['String'],
};

export type Query = {
   __typename?: 'Query',
  version: Scalars['String'],
  loggedInSiteUser?: Maybe<SiteUser>,
  project?: Maybe<Project>,
  appMetaData?: Maybe<AppMetaData>,
  validInviteCode: Scalars['Boolean'],
};


export type QueryProjectArgs = {
  extId: Scalars['ID']
};


export type QueryValidInviteCodeArgs = {
  code: Scalars['String']
};

export type RegisterSiteUserInput = {
  inviteCode: Scalars['String'],
  idToken: Scalars['ID'],
  fullName: Scalars['String'],
  encodedFileInput?: Maybe<Scalars['EncodedFileInput']>,
};

export type ReorderMilestoneInput = {
  orderedMilestoneIds: Array<Scalars['ID']>,
  projectExtId: Scalars['ID'],
};

export type SaveActionInput = {
  actionId: Scalars['ID'],
  displayName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type SaveCredentialInput = {
  credentialId: Scalars['ID'],
  serviceName?: Maybe<Scalars['String']>,
  serviceUrl?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
};

export type SaveNotificationInput = {
  notificationId: Scalars['String'],
  isRead: Scalars['Boolean'],
};

export type SavePaymentMilestoneInput = {
  milestoneId: Scalars['ID'],
  paymentAmountCents?: Maybe<Scalars['Int']>,
  paymentUrl?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type SaveProjectInput = {
  projectExtId: Scalars['ID'],
  appDisplayName?: Maybe<Scalars['String']>,
  encodedFileInput?: Maybe<Scalars['EncodedFileInput']>,
  projectType?: Maybe<Scalars['String']>,
};

export type SaveProjectMilestoneInput = {
  milestoneId: Scalars['ID'],
  displayName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type SaveSiteUserInput = {
  id: Scalars['ID'],
  email?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  encodedFileInput?: Maybe<Scalars['EncodedFileInput']>,
  title?: Maybe<Scalars['String']>,
};

export type SiteUser = {
   __typename?: 'SiteUser',
  id: Scalars['ID'],
  email: Scalars['String'],
  fullName: Scalars['String'],
  profilePictureUrl: Scalars['String'],
  title: Scalars['String'],
  isSuperAdmin: Scalars['Boolean'],
  projects: Array<Project>,
  notifications: Array<Notification>,
};


export type UploadAttachmentInput = {
  projectExtId: Scalars['ID'],
  milestoneId?: Maybe<Scalars['ID']>,
  encodedFileInput?: Maybe<EncodedFileInputWithFileName>,
  linkUrl?: Maybe<Scalars['String']>,
  displayName: Scalars['String'],
};

export type UserInvite = {
   __typename?: 'UserInvite',
  id: Scalars['ID'],
  inviteCode: Scalars['String'],
  email: Scalars['String'],
};

export type AccountPageQueryVariables = {};


export type AccountPageQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'fullName' | 'email' | 'profilePictureUrl'>
  )> }
);

export type SaveSiteUserMutationVariables = {
  userInput: SaveSiteUserInput
};


export type SaveSiteUserMutation = (
  { __typename?: 'Mutation' }
  & { saveSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id'>
  )> }
);

export type UploadProjectAttachmentLinkMutationVariables = {
  projectExtId: Scalars['ID'],
  linkUrl: Scalars['String'],
  displayName: Scalars['String']
};


export type UploadProjectAttachmentLinkMutation = (
  { __typename?: 'Mutation' }
  & { uploadAttachment: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type UploadProjectAttachmentFileMutationVariables = {
  projectExtId: Scalars['ID'],
  encodedFile: EncodedFileInputWithFileName,
  displayName: Scalars['String']
};


export type UploadProjectAttachmentFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadAttachment: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type AttachmentsPageQueryVariables = {
  projectExtId: Scalars['ID']
};


export type AttachmentsPageQuery = (
  { __typename?: 'Query' }
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { attachments: Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'metaCreated' | 'displayName' | 'description' | 'resourceUrl'>
      & { type: (
        { __typename?: 'AttachmentType' }
        & Pick<AttachmentType, 'id' | 'name' | 'displayName'>
      ) }
    )> }
  )> }
);

export type ValidInviteCodeQueryVariables = {
  code: Scalars['String']
};


export type ValidInviteCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'validInviteCode'>
);

export type CredentialPageQueryVariables = {
  projectExtId: Scalars['ID']
};


export type CredentialPageQuery = (
  { __typename?: 'Query' }
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { credentials: Array<(
      { __typename?: 'Credential' }
      & Pick<Credential, 'id' | 'serviceName' | 'serviceUrl' | 'username' | 'password'>
    )> }
  )> }
);

export type DeleteCredentialMutationVariables = {
  credentialId: Scalars['ID']
};


export type DeleteCredentialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCredential'>
);

export type CreateCredentialMutationVariables = {
  input: CreateCredentialInput
};


export type CreateCredentialMutation = (
  { __typename?: 'Mutation' }
  & { createCredential: Maybe<(
    { __typename?: 'Credential' }
    & Pick<Credential, 'id'>
  )> }
);

export type SaveCredentialMutationVariables = {
  input: SaveCredentialInput
};


export type SaveCredentialMutation = (
  { __typename?: 'Mutation' }
  & { saveCredential: Maybe<(
    { __typename?: 'Credential' }
    & Pick<Credential, 'id'>
  )> }
);

export type HomePageQueryVariables = {};


export type HomePageQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'isSuperAdmin'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'extId' | 'displayName' | 'logoUrl'>
      & { projectType: (
        { __typename?: 'ProjectType' }
        & Pick<ProjectType, 'name' | 'displayName'>
      ) }
    )> }
  )> }
);

export type CreateActionMutationVariables = {
  actionInput: CreateActionInput
};


export type CreateActionMutation = (
  { __typename?: 'Mutation' }
  & { createAction: Maybe<(
    { __typename?: 'Action' }
    & Pick<Action, 'id' | 'displayName' | 'description'>
    & { status: (
      { __typename?: 'ActionStatus' }
      & Pick<ActionStatus, 'id' | 'name' | 'displayName'>
    ) }
  )> }
);

export type CreatePaymentMilestoneMutationVariables = {
  input: CreatePaymentMilestoneInput
};


export type CreatePaymentMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentMilestone: Maybe<(
    { __typename?: 'PaymentMilestone' }
    & Pick<PaymentMilestone, 'id'>
  )> }
);

export type CreateProjectMilestoneMutationVariables = {
  input: CreateProjectMilestoneInput
};


export type CreateProjectMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { createProjectMilestone: Maybe<(
    { __typename?: 'ProjectMilestone' }
    & Pick<ProjectMilestone, 'id'>
  )> }
);

export type DeleteActionMutationVariables = {
  actionId: Scalars['ID']
};


export type DeleteActionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAction'>
);

export type DeleteProjectMilestoneMutationVariables = {
  milestoneId: Scalars['String']
};


export type DeleteProjectMilestoneMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectMilestone'>
);

export type ReorderMilestonesMutationVariables = {
  reorderInput: ReorderMilestoneInput
};


export type ReorderMilestonesMutation = (
  { __typename?: 'Mutation' }
  & { reorderMilestones: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'extId'>
  )> }
);

export type SaveActionMutationVariables = {
  actionInput: SaveActionInput
};


export type SaveActionMutation = (
  { __typename?: 'Mutation' }
  & { saveAction: Maybe<(
    { __typename?: 'Action' }
    & Pick<Action, 'id'>
  )> }
);

export type SavePaymentMilestoneMutationVariables = {
  milestoneInput: SavePaymentMilestoneInput
};


export type SavePaymentMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { savePaymentMilestone: Maybe<(
    { __typename?: 'PaymentMilestone' }
    & Pick<PaymentMilestone, 'id'>
  )> }
);

export type SaveProjectMilestoneMutationVariables = {
  milestoneInput: SaveProjectMilestoneInput
};


export type SaveProjectMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { saveProjectMilestone: Maybe<(
    { __typename?: 'ProjectMilestone' }
    & Pick<ProjectMilestone, 'id'>
  )> }
);

export type CreateAuditEventMutationVariables = {
  eventInput: CreateAuditEventInput
};


export type CreateAuditEventMutation = (
  { __typename?: 'Mutation' }
  & { createAuditEvent: Maybe<(
    { __typename?: 'AuditEvent' }
    & Pick<AuditEvent, 'id'>
  )> }
);

export type UploadMilestoneLinkAttachmentMutationVariables = {
  projectExtId: Scalars['ID'],
  milestoneId: Scalars['ID'],
  linkUrl: Scalars['String'],
  displayName: Scalars['String']
};


export type UploadMilestoneLinkAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { uploadAttachment: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type UploadMilestoneFileAttachmentMutationVariables = {
  projectExtId: Scalars['ID'],
  milestoneId: Scalars['ID'],
  encodedFile: EncodedFileInputWithFileName,
  displayName: Scalars['String']
};


export type UploadMilestoneFileAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { uploadAttachment: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type MilestonePageQueryVariables = {
  extId: Scalars['ID']
};


export type MilestonePageQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'isSuperAdmin' | 'profilePictureUrl'>
  )>, project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { milestones: Array<(
      { __typename?: 'PaymentMilestone' }
      & Pick<PaymentMilestone, 'id' | 'ranking' | 'paymentAmountCents' | 'paymentUrl'>
      & { status: (
        { __typename?: 'MilestoneStatus' }
        & Pick<MilestoneStatus, 'name' | 'displayName'>
      ) }
    ) | (
      { __typename?: 'ProjectMilestone' }
      & Pick<ProjectMilestone, 'id' | 'ranking' | 'displayName' | 'description'>
      & { status: (
        { __typename?: 'MilestoneStatus' }
        & Pick<MilestoneStatus, 'name' | 'displayName'>
      ), actions: Array<(
        { __typename?: 'Action' }
        & Pick<Action, 'id' | 'metaCreated' | 'displayName' | 'description'>
        & { status: (
          { __typename?: 'ActionStatus' }
          & Pick<ActionStatus, 'name' | 'displayName' | 'id'>
        ) }
      )>, attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'id' | 'displayName' | 'description' | 'resourceUrl'>
        & { type: (
          { __typename?: 'AttachmentType' }
          & Pick<AttachmentType, 'id' | 'name' | 'displayName'>
        ) }
      )>, logs: Array<(
        { __typename?: 'AuditEvent' }
        & Pick<AuditEvent, 'id' | 'metaCreated' | 'description'>
        & { creator: (
          { __typename?: 'SiteUser' }
          & Pick<SiteUser, 'id' | 'profilePictureUrl' | 'fullName'>
        ) }
      )> }
    )> }
  )> }
);

export type SaveNotificationMutationVariables = {
  notificationInput: SaveNotificationInput
};


export type SaveNotificationMutation = (
  { __typename?: 'Mutation' }
  & { saveNotification: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  )> }
);

export type MarkAllNotificationsAsReadMutationVariables = {};


export type MarkAllNotificationsAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAllNotificationsAsRead'>
);

export type PageTemplateQueryVariables = {};


export type PageTemplateQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'isSuperAdmin'>
    & { notifications: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'metaCreated' | 'description' | 'shortDescription' | 'isRead'>
      & { auditEvent: (
        { __typename?: 'AuditEvent' }
        & Pick<AuditEvent, 'id'>
        & { project: (
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'extId' | 'displayName' | 'logoUrl'>
          & { projectType: (
            { __typename?: 'ProjectType' }
            & Pick<ProjectType, 'id' | 'displayName'>
          ) }
        ), milestone: Maybe<(
          { __typename?: 'PaymentMilestone' }
          & Pick<PaymentMilestone, 'id'>
        ) | (
          { __typename?: 'ProjectMilestone' }
          & Pick<ProjectMilestone, 'id'>
        )> }
      ) }
    )> }
  )> }
);

export type CreateProjectMutationVariables = {
  projectInput: CreateProjectInput
};


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type ProjectPickerPageQueryVariables = {};


export type ProjectPickerPageQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'isSuperAdmin'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'displayName' | 'extId' | 'logoUrl'>
      & { projectType: (
        { __typename?: 'ProjectType' }
        & Pick<ProjectType, 'id' | 'name' | 'displayName'>
      ) }
    )> }
  )>, appMetaData: Maybe<(
    { __typename?: 'AppMetaData' }
    & { projectTypes: Array<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'name' | 'displayName'>
    )> }
  )> }
);

export type SaveProjectMutationVariables = {
  input: SaveProjectInput
};


export type SaveProjectMutation = (
  { __typename?: 'Mutation' }
  & { saveProject: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'displayName' | 'logoUrl'>
    & { projectType: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'displayName' | 'name'>
    ) }
  )> }
);

export type LinkSiteUserToProjectMutationVariables = {
  projectExtId: Scalars['ID'],
  email: Scalars['String']
};


export type LinkSiteUserToProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUserToProject'>
);

export type SettingsPageQueryVariables = {
  projectExtId: Scalars['ID']
};


export type SettingsPageQuery = (
  { __typename?: 'Query' }
  & { appMetaData: Maybe<(
    { __typename?: 'AppMetaData' }
    & { projectTypes: Array<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'displayName' | 'name'>
    )> }
  )>, project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'displayName' | 'logoUrl'>
    & { projectType: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'displayName' | 'name'>
    ), users: Array<(
      { __typename?: 'ProjectUser' }
      & Pick<ProjectUser, 'id' | 'email' | 'fullName' | 'profilePictureUrl'>
    )>, pendingInvites: Array<(
      { __typename?: 'UserInvite' }
      & Pick<UserInvite, 'id' | 'inviteCode' | 'email'>
    )> }
  )> }
);

export type AppToolbarQueryVariables = {};


export type AppToolbarQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'isSuperAdmin'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'extId' | 'displayName' | 'logoUrl'>
      & { projectType: (
        { __typename?: 'ProjectType' }
        & Pick<ProjectType, 'name' | 'displayName'>
      ) }
    )> }
  )> }
);

export type ProjectPickerToolbarQueryVariables = {};


export type ProjectPickerToolbarQuery = (
  { __typename?: 'Query' }
  & { loggedInSiteUser: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'isSuperAdmin'>
  )> }
);


export const AccountPageDocument = gql`
    query AccountPage {
  loggedInSiteUser {
    id
    fullName
    email
    profilePictureUrl
  }
}
    `;

/**
 * __useAccountPageQuery__
 *
 * To run a query within a React component, call `useAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountPageQuery, AccountPageQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountPageQuery, AccountPageQueryVariables>(AccountPageDocument, baseOptions);
      }
export function useAccountPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountPageQuery, AccountPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountPageQuery, AccountPageQueryVariables>(AccountPageDocument, baseOptions);
        }
export type AccountPageQueryHookResult = ReturnType<typeof useAccountPageQuery>;
export type AccountPageLazyQueryHookResult = ReturnType<typeof useAccountPageLazyQuery>;
export type AccountPageQueryResult = ApolloReactCommon.QueryResult<AccountPageQuery, AccountPageQueryVariables>;
export const SaveSiteUserDocument = gql`
    mutation SaveSiteUser($userInput: SaveSiteUserInput!) {
  saveSiteUser(userInput: $userInput) {
    id
  }
}
    `;
export type SaveSiteUserMutationFn = ApolloReactCommon.MutationFunction<SaveSiteUserMutation, SaveSiteUserMutationVariables>;

/**
 * __useSaveSiteUserMutation__
 *
 * To run a mutation, you first call `useSaveSiteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSiteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSiteUserMutation, { data, loading, error }] = useSaveSiteUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useSaveSiteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveSiteUserMutation, SaveSiteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveSiteUserMutation, SaveSiteUserMutationVariables>(SaveSiteUserDocument, baseOptions);
      }
export type SaveSiteUserMutationHookResult = ReturnType<typeof useSaveSiteUserMutation>;
export type SaveSiteUserMutationResult = ApolloReactCommon.MutationResult<SaveSiteUserMutation>;
export type SaveSiteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveSiteUserMutation, SaveSiteUserMutationVariables>;
export const UploadProjectAttachmentLinkDocument = gql`
    mutation UploadProjectAttachmentLink($projectExtId: ID!, $linkUrl: String!, $displayName: String!) {
  uploadAttachment(input: {projectExtId: $projectExtId, linkUrl: $linkUrl, displayName: $displayName}) {
    id
  }
}
    `;
export type UploadProjectAttachmentLinkMutationFn = ApolloReactCommon.MutationFunction<UploadProjectAttachmentLinkMutation, UploadProjectAttachmentLinkMutationVariables>;

/**
 * __useUploadProjectAttachmentLinkMutation__
 *
 * To run a mutation, you first call `useUploadProjectAttachmentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectAttachmentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectAttachmentLinkMutation, { data, loading, error }] = useUploadProjectAttachmentLinkMutation({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *      linkUrl: // value for 'linkUrl'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUploadProjectAttachmentLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProjectAttachmentLinkMutation, UploadProjectAttachmentLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProjectAttachmentLinkMutation, UploadProjectAttachmentLinkMutationVariables>(UploadProjectAttachmentLinkDocument, baseOptions);
      }
export type UploadProjectAttachmentLinkMutationHookResult = ReturnType<typeof useUploadProjectAttachmentLinkMutation>;
export type UploadProjectAttachmentLinkMutationResult = ApolloReactCommon.MutationResult<UploadProjectAttachmentLinkMutation>;
export type UploadProjectAttachmentLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProjectAttachmentLinkMutation, UploadProjectAttachmentLinkMutationVariables>;
export const UploadProjectAttachmentFileDocument = gql`
    mutation UploadProjectAttachmentFile($projectExtId: ID!, $encodedFile: EncodedFileInputWithFileName!, $displayName: String!) {
  uploadAttachment(input: {projectExtId: $projectExtId, encodedFileInput: $encodedFile, displayName: $displayName}) {
    id
  }
}
    `;
export type UploadProjectAttachmentFileMutationFn = ApolloReactCommon.MutationFunction<UploadProjectAttachmentFileMutation, UploadProjectAttachmentFileMutationVariables>;

/**
 * __useUploadProjectAttachmentFileMutation__
 *
 * To run a mutation, you first call `useUploadProjectAttachmentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectAttachmentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectAttachmentFileMutation, { data, loading, error }] = useUploadProjectAttachmentFileMutation({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *      encodedFile: // value for 'encodedFile'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUploadProjectAttachmentFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProjectAttachmentFileMutation, UploadProjectAttachmentFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProjectAttachmentFileMutation, UploadProjectAttachmentFileMutationVariables>(UploadProjectAttachmentFileDocument, baseOptions);
      }
export type UploadProjectAttachmentFileMutationHookResult = ReturnType<typeof useUploadProjectAttachmentFileMutation>;
export type UploadProjectAttachmentFileMutationResult = ApolloReactCommon.MutationResult<UploadProjectAttachmentFileMutation>;
export type UploadProjectAttachmentFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProjectAttachmentFileMutation, UploadProjectAttachmentFileMutationVariables>;
export const AttachmentsPageDocument = gql`
    query AttachmentsPage($projectExtId: ID!) {
  project(extId: $projectExtId) {
    id
    attachments {
      id
      metaCreated
      type {
        id
        name
        displayName
      }
      displayName
      description
      resourceUrl
    }
  }
}
    `;

/**
 * __useAttachmentsPageQuery__
 *
 * To run a query within a React component, call `useAttachmentsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsPageQuery({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *   },
 * });
 */
export function useAttachmentsPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttachmentsPageQuery, AttachmentsPageQueryVariables>) {
        return ApolloReactHooks.useQuery<AttachmentsPageQuery, AttachmentsPageQueryVariables>(AttachmentsPageDocument, baseOptions);
      }
export function useAttachmentsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttachmentsPageQuery, AttachmentsPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttachmentsPageQuery, AttachmentsPageQueryVariables>(AttachmentsPageDocument, baseOptions);
        }
export type AttachmentsPageQueryHookResult = ReturnType<typeof useAttachmentsPageQuery>;
export type AttachmentsPageLazyQueryHookResult = ReturnType<typeof useAttachmentsPageLazyQuery>;
export type AttachmentsPageQueryResult = ApolloReactCommon.QueryResult<AttachmentsPageQuery, AttachmentsPageQueryVariables>;
export const ValidInviteCodeDocument = gql`
    query ValidInviteCode($code: String!) {
  validInviteCode(code: $code)
}
    `;

/**
 * __useValidInviteCodeQuery__
 *
 * To run a query within a React component, call `useValidInviteCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidInviteCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidInviteCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidInviteCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidInviteCodeQuery, ValidInviteCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidInviteCodeQuery, ValidInviteCodeQueryVariables>(ValidInviteCodeDocument, baseOptions);
      }
export function useValidInviteCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidInviteCodeQuery, ValidInviteCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidInviteCodeQuery, ValidInviteCodeQueryVariables>(ValidInviteCodeDocument, baseOptions);
        }
export type ValidInviteCodeQueryHookResult = ReturnType<typeof useValidInviteCodeQuery>;
export type ValidInviteCodeLazyQueryHookResult = ReturnType<typeof useValidInviteCodeLazyQuery>;
export type ValidInviteCodeQueryResult = ApolloReactCommon.QueryResult<ValidInviteCodeQuery, ValidInviteCodeQueryVariables>;
export const CredentialPageDocument = gql`
    query CredentialPage($projectExtId: ID!) {
  project(extId: $projectExtId) {
    id
    credentials {
      id
      serviceName
      serviceUrl
      username
      password
    }
  }
}
    `;

/**
 * __useCredentialPageQuery__
 *
 * To run a query within a React component, call `useCredentialPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCredentialPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCredentialPageQuery({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *   },
 * });
 */
export function useCredentialPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CredentialPageQuery, CredentialPageQueryVariables>) {
        return ApolloReactHooks.useQuery<CredentialPageQuery, CredentialPageQueryVariables>(CredentialPageDocument, baseOptions);
      }
export function useCredentialPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CredentialPageQuery, CredentialPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CredentialPageQuery, CredentialPageQueryVariables>(CredentialPageDocument, baseOptions);
        }
export type CredentialPageQueryHookResult = ReturnType<typeof useCredentialPageQuery>;
export type CredentialPageLazyQueryHookResult = ReturnType<typeof useCredentialPageLazyQuery>;
export type CredentialPageQueryResult = ApolloReactCommon.QueryResult<CredentialPageQuery, CredentialPageQueryVariables>;
export const DeleteCredentialDocument = gql`
    mutation DeleteCredential($credentialId: ID!) {
  deleteCredential(credentialId: $credentialId)
}
    `;
export type DeleteCredentialMutationFn = ApolloReactCommon.MutationFunction<DeleteCredentialMutation, DeleteCredentialMutationVariables>;

/**
 * __useDeleteCredentialMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialMutation, { data, loading, error }] = useDeleteCredentialMutation({
 *   variables: {
 *      credentialId: // value for 'credentialId'
 *   },
 * });
 */
export function useDeleteCredentialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCredentialMutation, DeleteCredentialMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCredentialMutation, DeleteCredentialMutationVariables>(DeleteCredentialDocument, baseOptions);
      }
export type DeleteCredentialMutationHookResult = ReturnType<typeof useDeleteCredentialMutation>;
export type DeleteCredentialMutationResult = ApolloReactCommon.MutationResult<DeleteCredentialMutation>;
export type DeleteCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCredentialMutation, DeleteCredentialMutationVariables>;
export const CreateCredentialDocument = gql`
    mutation CreateCredential($input: CreateCredentialInput!) {
  createCredential(credentialInput: $input) {
    id
  }
}
    `;
export type CreateCredentialMutationFn = ApolloReactCommon.MutationFunction<CreateCredentialMutation, CreateCredentialMutationVariables>;

/**
 * __useCreateCredentialMutation__
 *
 * To run a mutation, you first call `useCreateCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCredentialMutation, { data, loading, error }] = useCreateCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCredentialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCredentialMutation, CreateCredentialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCredentialMutation, CreateCredentialMutationVariables>(CreateCredentialDocument, baseOptions);
      }
export type CreateCredentialMutationHookResult = ReturnType<typeof useCreateCredentialMutation>;
export type CreateCredentialMutationResult = ApolloReactCommon.MutationResult<CreateCredentialMutation>;
export type CreateCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCredentialMutation, CreateCredentialMutationVariables>;
export const SaveCredentialDocument = gql`
    mutation SaveCredential($input: SaveCredentialInput!) {
  saveCredential(credentialInput: $input) {
    id
  }
}
    `;
export type SaveCredentialMutationFn = ApolloReactCommon.MutationFunction<SaveCredentialMutation, SaveCredentialMutationVariables>;

/**
 * __useSaveCredentialMutation__
 *
 * To run a mutation, you first call `useSaveCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCredentialMutation, { data, loading, error }] = useSaveCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCredentialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveCredentialMutation, SaveCredentialMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveCredentialMutation, SaveCredentialMutationVariables>(SaveCredentialDocument, baseOptions);
      }
export type SaveCredentialMutationHookResult = ReturnType<typeof useSaveCredentialMutation>;
export type SaveCredentialMutationResult = ApolloReactCommon.MutationResult<SaveCredentialMutation>;
export type SaveCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveCredentialMutation, SaveCredentialMutationVariables>;
export const HomePageDocument = gql`
    query HomePage {
  loggedInSiteUser {
    id
    isSuperAdmin
    projects {
      id
      extId
      displayName
      logoUrl
      projectType {
        name
        displayName
      }
    }
  }
}
    `;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
        return ApolloReactHooks.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, baseOptions);
      }
export function useHomePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, baseOptions);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = ApolloReactCommon.QueryResult<HomePageQuery, HomePageQueryVariables>;
export const CreateActionDocument = gql`
    mutation CreateAction($actionInput: CreateActionInput!) {
  createAction(actionInput: $actionInput) {
    id
    displayName
    description
    status {
      id
      name
      displayName
    }
  }
}
    `;
export type CreateActionMutationFn = ApolloReactCommon.MutationFunction<CreateActionMutation, CreateActionMutationVariables>;

/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      actionInput: // value for 'actionInput'
 *   },
 * });
 */
export function useCreateActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActionMutation, CreateActionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActionMutation, CreateActionMutationVariables>(CreateActionDocument, baseOptions);
      }
export type CreateActionMutationHookResult = ReturnType<typeof useCreateActionMutation>;
export type CreateActionMutationResult = ApolloReactCommon.MutationResult<CreateActionMutation>;
export type CreateActionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActionMutation, CreateActionMutationVariables>;
export const CreatePaymentMilestoneDocument = gql`
    mutation CreatePaymentMilestone($input: CreatePaymentMilestoneInput!) {
  createPaymentMilestone(milestoneInput: $input) {
    id
  }
}
    `;
export type CreatePaymentMilestoneMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentMilestoneMutation, CreatePaymentMilestoneMutationVariables>;

/**
 * __useCreatePaymentMilestoneMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMilestoneMutation, { data, loading, error }] = useCreatePaymentMilestoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentMilestoneMutation, CreatePaymentMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentMilestoneMutation, CreatePaymentMilestoneMutationVariables>(CreatePaymentMilestoneDocument, baseOptions);
      }
export type CreatePaymentMilestoneMutationHookResult = ReturnType<typeof useCreatePaymentMilestoneMutation>;
export type CreatePaymentMilestoneMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMilestoneMutation>;
export type CreatePaymentMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentMilestoneMutation, CreatePaymentMilestoneMutationVariables>;
export const CreateProjectMilestoneDocument = gql`
    mutation CreateProjectMilestone($input: CreateProjectMilestoneInput!) {
  createProjectMilestone(milestoneInput: $input) {
    id
  }
}
    `;
export type CreateProjectMilestoneMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMilestoneMutation, CreateProjectMilestoneMutationVariables>;

/**
 * __useCreateProjectMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateProjectMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMilestoneMutation, { data, loading, error }] = useCreateProjectMilestoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMilestoneMutation, CreateProjectMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMilestoneMutation, CreateProjectMilestoneMutationVariables>(CreateProjectMilestoneDocument, baseOptions);
      }
export type CreateProjectMilestoneMutationHookResult = ReturnType<typeof useCreateProjectMilestoneMutation>;
export type CreateProjectMilestoneMutationResult = ApolloReactCommon.MutationResult<CreateProjectMilestoneMutation>;
export type CreateProjectMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMilestoneMutation, CreateProjectMilestoneMutationVariables>;
export const DeleteActionDocument = gql`
    mutation DeleteAction($actionId: ID!) {
  deleteAction(actionId: $actionId)
}
    `;
export type DeleteActionMutationFn = ApolloReactCommon.MutationFunction<DeleteActionMutation, DeleteActionMutationVariables>;

/**
 * __useDeleteActionMutation__
 *
 * To run a mutation, you first call `useDeleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionMutation, { data, loading, error }] = useDeleteActionMutation({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useDeleteActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteActionMutation, DeleteActionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteActionMutation, DeleteActionMutationVariables>(DeleteActionDocument, baseOptions);
      }
export type DeleteActionMutationHookResult = ReturnType<typeof useDeleteActionMutation>;
export type DeleteActionMutationResult = ApolloReactCommon.MutationResult<DeleteActionMutation>;
export type DeleteActionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteActionMutation, DeleteActionMutationVariables>;
export const DeleteProjectMilestoneDocument = gql`
    mutation DeleteProjectMilestone($milestoneId: String!) {
  deleteProjectMilestone(milestoneId: $milestoneId)
}
    `;
export type DeleteProjectMilestoneMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMilestoneMutation, DeleteProjectMilestoneMutationVariables>;

/**
 * __useDeleteProjectMilestoneMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMilestoneMutation, { data, loading, error }] = useDeleteProjectMilestoneMutation({
 *   variables: {
 *      milestoneId: // value for 'milestoneId'
 *   },
 * });
 */
export function useDeleteProjectMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMilestoneMutation, DeleteProjectMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMilestoneMutation, DeleteProjectMilestoneMutationVariables>(DeleteProjectMilestoneDocument, baseOptions);
      }
export type DeleteProjectMilestoneMutationHookResult = ReturnType<typeof useDeleteProjectMilestoneMutation>;
export type DeleteProjectMilestoneMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMilestoneMutation>;
export type DeleteProjectMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMilestoneMutation, DeleteProjectMilestoneMutationVariables>;
export const ReorderMilestonesDocument = gql`
    mutation ReorderMilestones($reorderInput: ReorderMilestoneInput!) {
  reorderMilestones(reorderInput: $reorderInput) {
    extId
  }
}
    `;
export type ReorderMilestonesMutationFn = ApolloReactCommon.MutationFunction<ReorderMilestonesMutation, ReorderMilestonesMutationVariables>;

/**
 * __useReorderMilestonesMutation__
 *
 * To run a mutation, you first call `useReorderMilestonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderMilestonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderMilestonesMutation, { data, loading, error }] = useReorderMilestonesMutation({
 *   variables: {
 *      reorderInput: // value for 'reorderInput'
 *   },
 * });
 */
export function useReorderMilestonesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReorderMilestonesMutation, ReorderMilestonesMutationVariables>) {
        return ApolloReactHooks.useMutation<ReorderMilestonesMutation, ReorderMilestonesMutationVariables>(ReorderMilestonesDocument, baseOptions);
      }
export type ReorderMilestonesMutationHookResult = ReturnType<typeof useReorderMilestonesMutation>;
export type ReorderMilestonesMutationResult = ApolloReactCommon.MutationResult<ReorderMilestonesMutation>;
export type ReorderMilestonesMutationOptions = ApolloReactCommon.BaseMutationOptions<ReorderMilestonesMutation, ReorderMilestonesMutationVariables>;
export const SaveActionDocument = gql`
    mutation SaveAction($actionInput: SaveActionInput!) {
  saveAction(actionInput: $actionInput) {
    id
  }
}
    `;
export type SaveActionMutationFn = ApolloReactCommon.MutationFunction<SaveActionMutation, SaveActionMutationVariables>;

/**
 * __useSaveActionMutation__
 *
 * To run a mutation, you first call `useSaveActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActionMutation, { data, loading, error }] = useSaveActionMutation({
 *   variables: {
 *      actionInput: // value for 'actionInput'
 *   },
 * });
 */
export function useSaveActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveActionMutation, SaveActionMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveActionMutation, SaveActionMutationVariables>(SaveActionDocument, baseOptions);
      }
export type SaveActionMutationHookResult = ReturnType<typeof useSaveActionMutation>;
export type SaveActionMutationResult = ApolloReactCommon.MutationResult<SaveActionMutation>;
export type SaveActionMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveActionMutation, SaveActionMutationVariables>;
export const SavePaymentMilestoneDocument = gql`
    mutation SavePaymentMilestone($milestoneInput: SavePaymentMilestoneInput!) {
  savePaymentMilestone(milestoneInput: $milestoneInput) {
    id
  }
}
    `;
export type SavePaymentMilestoneMutationFn = ApolloReactCommon.MutationFunction<SavePaymentMilestoneMutation, SavePaymentMilestoneMutationVariables>;

/**
 * __useSavePaymentMilestoneMutation__
 *
 * To run a mutation, you first call `useSavePaymentMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePaymentMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePaymentMilestoneMutation, { data, loading, error }] = useSavePaymentMilestoneMutation({
 *   variables: {
 *      milestoneInput: // value for 'milestoneInput'
 *   },
 * });
 */
export function useSavePaymentMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SavePaymentMilestoneMutation, SavePaymentMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<SavePaymentMilestoneMutation, SavePaymentMilestoneMutationVariables>(SavePaymentMilestoneDocument, baseOptions);
      }
export type SavePaymentMilestoneMutationHookResult = ReturnType<typeof useSavePaymentMilestoneMutation>;
export type SavePaymentMilestoneMutationResult = ApolloReactCommon.MutationResult<SavePaymentMilestoneMutation>;
export type SavePaymentMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePaymentMilestoneMutation, SavePaymentMilestoneMutationVariables>;
export const SaveProjectMilestoneDocument = gql`
    mutation SaveProjectMilestone($milestoneInput: SaveProjectMilestoneInput!) {
  saveProjectMilestone(milestoneInput: $milestoneInput) {
    id
  }
}
    `;
export type SaveProjectMilestoneMutationFn = ApolloReactCommon.MutationFunction<SaveProjectMilestoneMutation, SaveProjectMilestoneMutationVariables>;

/**
 * __useSaveProjectMilestoneMutation__
 *
 * To run a mutation, you first call `useSaveProjectMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectMilestoneMutation, { data, loading, error }] = useSaveProjectMilestoneMutation({
 *   variables: {
 *      milestoneInput: // value for 'milestoneInput'
 *   },
 * });
 */
export function useSaveProjectMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveProjectMilestoneMutation, SaveProjectMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveProjectMilestoneMutation, SaveProjectMilestoneMutationVariables>(SaveProjectMilestoneDocument, baseOptions);
      }
export type SaveProjectMilestoneMutationHookResult = ReturnType<typeof useSaveProjectMilestoneMutation>;
export type SaveProjectMilestoneMutationResult = ApolloReactCommon.MutationResult<SaveProjectMilestoneMutation>;
export type SaveProjectMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveProjectMilestoneMutation, SaveProjectMilestoneMutationVariables>;
export const CreateAuditEventDocument = gql`
    mutation CreateAuditEvent($eventInput: CreateAuditEventInput!) {
  createAuditEvent(eventInput: $eventInput) {
    id
  }
}
    `;
export type CreateAuditEventMutationFn = ApolloReactCommon.MutationFunction<CreateAuditEventMutation, CreateAuditEventMutationVariables>;

/**
 * __useCreateAuditEventMutation__
 *
 * To run a mutation, you first call `useCreateAuditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditEventMutation, { data, loading, error }] = useCreateAuditEventMutation({
 *   variables: {
 *      eventInput: // value for 'eventInput'
 *   },
 * });
 */
export function useCreateAuditEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAuditEventMutation, CreateAuditEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAuditEventMutation, CreateAuditEventMutationVariables>(CreateAuditEventDocument, baseOptions);
      }
export type CreateAuditEventMutationHookResult = ReturnType<typeof useCreateAuditEventMutation>;
export type CreateAuditEventMutationResult = ApolloReactCommon.MutationResult<CreateAuditEventMutation>;
export type CreateAuditEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAuditEventMutation, CreateAuditEventMutationVariables>;
export const UploadMilestoneLinkAttachmentDocument = gql`
    mutation UploadMilestoneLinkAttachment($projectExtId: ID!, $milestoneId: ID!, $linkUrl: String!, $displayName: String!) {
  uploadAttachment(input: {projectExtId: $projectExtId, milestoneId: $milestoneId, linkUrl: $linkUrl, displayName: $displayName}) {
    id
  }
}
    `;
export type UploadMilestoneLinkAttachmentMutationFn = ApolloReactCommon.MutationFunction<UploadMilestoneLinkAttachmentMutation, UploadMilestoneLinkAttachmentMutationVariables>;

/**
 * __useUploadMilestoneLinkAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadMilestoneLinkAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMilestoneLinkAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMilestoneLinkAttachmentMutation, { data, loading, error }] = useUploadMilestoneLinkAttachmentMutation({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *      milestoneId: // value for 'milestoneId'
 *      linkUrl: // value for 'linkUrl'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUploadMilestoneLinkAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadMilestoneLinkAttachmentMutation, UploadMilestoneLinkAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadMilestoneLinkAttachmentMutation, UploadMilestoneLinkAttachmentMutationVariables>(UploadMilestoneLinkAttachmentDocument, baseOptions);
      }
export type UploadMilestoneLinkAttachmentMutationHookResult = ReturnType<typeof useUploadMilestoneLinkAttachmentMutation>;
export type UploadMilestoneLinkAttachmentMutationResult = ApolloReactCommon.MutationResult<UploadMilestoneLinkAttachmentMutation>;
export type UploadMilestoneLinkAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadMilestoneLinkAttachmentMutation, UploadMilestoneLinkAttachmentMutationVariables>;
export const UploadMilestoneFileAttachmentDocument = gql`
    mutation UploadMilestoneFileAttachment($projectExtId: ID!, $milestoneId: ID!, $encodedFile: EncodedFileInputWithFileName!, $displayName: String!) {
  uploadAttachment(input: {projectExtId: $projectExtId, milestoneId: $milestoneId, encodedFileInput: $encodedFile, displayName: $displayName}) {
    id
  }
}
    `;
export type UploadMilestoneFileAttachmentMutationFn = ApolloReactCommon.MutationFunction<UploadMilestoneFileAttachmentMutation, UploadMilestoneFileAttachmentMutationVariables>;

/**
 * __useUploadMilestoneFileAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadMilestoneFileAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMilestoneFileAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMilestoneFileAttachmentMutation, { data, loading, error }] = useUploadMilestoneFileAttachmentMutation({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *      milestoneId: // value for 'milestoneId'
 *      encodedFile: // value for 'encodedFile'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUploadMilestoneFileAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadMilestoneFileAttachmentMutation, UploadMilestoneFileAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadMilestoneFileAttachmentMutation, UploadMilestoneFileAttachmentMutationVariables>(UploadMilestoneFileAttachmentDocument, baseOptions);
      }
export type UploadMilestoneFileAttachmentMutationHookResult = ReturnType<typeof useUploadMilestoneFileAttachmentMutation>;
export type UploadMilestoneFileAttachmentMutationResult = ApolloReactCommon.MutationResult<UploadMilestoneFileAttachmentMutation>;
export type UploadMilestoneFileAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadMilestoneFileAttachmentMutation, UploadMilestoneFileAttachmentMutationVariables>;
export const MilestonePageDocument = gql`
    query MilestonePage($extId: ID!) {
  loggedInSiteUser {
    id
    isSuperAdmin
    profilePictureUrl
  }
  project(extId: $extId) {
    id
    milestones {
      ... on PaymentMilestone {
        id
        ranking
        status {
          name
          displayName
        }
        paymentAmountCents
        paymentUrl
      }
      ... on ProjectMilestone {
        id
        ranking
        status {
          name
          displayName
        }
        displayName
        description
        actions {
          id
          metaCreated
          displayName
          description
          status {
            name
            displayName
            id
          }
        }
        attachments {
          id
          type {
            id
            name
            displayName
          }
          displayName
          description
          resourceUrl
        }
        logs {
          id
          metaCreated
          description
          creator {
            id
            profilePictureUrl
            fullName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMilestonePageQuery__
 *
 * To run a query within a React component, call `useMilestonePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilestonePageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilestonePageQuery({
 *   variables: {
 *      extId: // value for 'extId'
 *   },
 * });
 */
export function useMilestonePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MilestonePageQuery, MilestonePageQueryVariables>) {
        return ApolloReactHooks.useQuery<MilestonePageQuery, MilestonePageQueryVariables>(MilestonePageDocument, baseOptions);
      }
export function useMilestonePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MilestonePageQuery, MilestonePageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MilestonePageQuery, MilestonePageQueryVariables>(MilestonePageDocument, baseOptions);
        }
export type MilestonePageQueryHookResult = ReturnType<typeof useMilestonePageQuery>;
export type MilestonePageLazyQueryHookResult = ReturnType<typeof useMilestonePageLazyQuery>;
export type MilestonePageQueryResult = ApolloReactCommon.QueryResult<MilestonePageQuery, MilestonePageQueryVariables>;
export const SaveNotificationDocument = gql`
    mutation SaveNotification($notificationInput: SaveNotificationInput!) {
  saveNotification(notificationInput: $notificationInput) {
    id
  }
}
    `;
export type SaveNotificationMutationFn = ApolloReactCommon.MutationFunction<SaveNotificationMutation, SaveNotificationMutationVariables>;

/**
 * __useSaveNotificationMutation__
 *
 * To run a mutation, you first call `useSaveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotificationMutation, { data, loading, error }] = useSaveNotificationMutation({
 *   variables: {
 *      notificationInput: // value for 'notificationInput'
 *   },
 * });
 */
export function useSaveNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveNotificationMutation, SaveNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveNotificationMutation, SaveNotificationMutationVariables>(SaveNotificationDocument, baseOptions);
      }
export type SaveNotificationMutationHookResult = ReturnType<typeof useSaveNotificationMutation>;
export type SaveNotificationMutationResult = ApolloReactCommon.MutationResult<SaveNotificationMutation>;
export type SaveNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveNotificationMutation, SaveNotificationMutationVariables>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation MarkAllNotificationsAsRead {
  markAllNotificationsAsRead
}
    `;
export type MarkAllNotificationsAsReadMutationFn = ApolloReactCommon.MutationFunction<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>(MarkAllNotificationsAsReadDocument, baseOptions);
      }
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = ApolloReactCommon.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;
export const PageTemplateDocument = gql`
    query PageTemplate {
  loggedInSiteUser {
    id
    isSuperAdmin
    notifications {
      id
      metaCreated
      description
      shortDescription
      isRead
      auditEvent {
        id
        project {
          id
          extId
          displayName
          projectType {
            id
            displayName
          }
          logoUrl
        }
        milestone {
          ... on PaymentMilestone {
            id
          }
          ... on ProjectMilestone {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePageTemplateQuery__
 *
 * To run a query within a React component, call `usePageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PageTemplateQuery, PageTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<PageTemplateQuery, PageTemplateQueryVariables>(PageTemplateDocument, baseOptions);
      }
export function usePageTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PageTemplateQuery, PageTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PageTemplateQuery, PageTemplateQueryVariables>(PageTemplateDocument, baseOptions);
        }
export type PageTemplateQueryHookResult = ReturnType<typeof usePageTemplateQuery>;
export type PageTemplateLazyQueryHookResult = ReturnType<typeof usePageTemplateLazyQuery>;
export type PageTemplateQueryResult = ApolloReactCommon.QueryResult<PageTemplateQuery, PageTemplateQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($projectInput: CreateProjectInput!) {
  createProject(projectInput: $projectInput) {
    id
  }
}
    `;
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      projectInput: // value for 'projectInput'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const ProjectPickerPageDocument = gql`
    query ProjectPickerPage {
  loggedInSiteUser {
    id
    isSuperAdmin
    projects {
      id
      displayName
      extId
      logoUrl
      projectType {
        id
        name
        displayName
      }
    }
  }
  appMetaData {
    projectTypes {
      id
      name
      displayName
    }
  }
}
    `;

/**
 * __useProjectPickerPageQuery__
 *
 * To run a query within a React component, call `useProjectPickerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPickerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPickerPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectPickerPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPickerPageQuery, ProjectPickerPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPickerPageQuery, ProjectPickerPageQueryVariables>(ProjectPickerPageDocument, baseOptions);
      }
export function useProjectPickerPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPickerPageQuery, ProjectPickerPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPickerPageQuery, ProjectPickerPageQueryVariables>(ProjectPickerPageDocument, baseOptions);
        }
export type ProjectPickerPageQueryHookResult = ReturnType<typeof useProjectPickerPageQuery>;
export type ProjectPickerPageLazyQueryHookResult = ReturnType<typeof useProjectPickerPageLazyQuery>;
export type ProjectPickerPageQueryResult = ApolloReactCommon.QueryResult<ProjectPickerPageQuery, ProjectPickerPageQueryVariables>;
export const SaveProjectDocument = gql`
    mutation SaveProject($input: SaveProjectInput!) {
  saveProject(projectInput: $input) {
    id
    displayName
    logoUrl
    projectType {
      displayName
      name
    }
  }
}
    `;
export type SaveProjectMutationFn = ApolloReactCommon.MutationFunction<SaveProjectMutation, SaveProjectMutationVariables>;

/**
 * __useSaveProjectMutation__
 *
 * To run a mutation, you first call `useSaveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectMutation, { data, loading, error }] = useSaveProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveProjectMutation, SaveProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveProjectMutation, SaveProjectMutationVariables>(SaveProjectDocument, baseOptions);
      }
export type SaveProjectMutationHookResult = ReturnType<typeof useSaveProjectMutation>;
export type SaveProjectMutationResult = ApolloReactCommon.MutationResult<SaveProjectMutation>;
export type SaveProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveProjectMutation, SaveProjectMutationVariables>;
export const LinkSiteUserToProjectDocument = gql`
    mutation LinkSiteUserToProject($projectExtId: ID!, $email: String!) {
  inviteUserToProject(projectExtId: $projectExtId, email: $email)
}
    `;
export type LinkSiteUserToProjectMutationFn = ApolloReactCommon.MutationFunction<LinkSiteUserToProjectMutation, LinkSiteUserToProjectMutationVariables>;

/**
 * __useLinkSiteUserToProjectMutation__
 *
 * To run a mutation, you first call `useLinkSiteUserToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkSiteUserToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkSiteUserToProjectMutation, { data, loading, error }] = useLinkSiteUserToProjectMutation({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLinkSiteUserToProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkSiteUserToProjectMutation, LinkSiteUserToProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkSiteUserToProjectMutation, LinkSiteUserToProjectMutationVariables>(LinkSiteUserToProjectDocument, baseOptions);
      }
export type LinkSiteUserToProjectMutationHookResult = ReturnType<typeof useLinkSiteUserToProjectMutation>;
export type LinkSiteUserToProjectMutationResult = ApolloReactCommon.MutationResult<LinkSiteUserToProjectMutation>;
export type LinkSiteUserToProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkSiteUserToProjectMutation, LinkSiteUserToProjectMutationVariables>;
export const SettingsPageDocument = gql`
    query SettingsPage($projectExtId: ID!) {
  appMetaData {
    projectTypes {
      id
      displayName
      name
    }
  }
  project(extId: $projectExtId) {
    id
    displayName
    logoUrl
    projectType {
      id
      displayName
      name
    }
    users {
      id
      email
      fullName
      profilePictureUrl
    }
    pendingInvites {
      id
      inviteCode
      email
    }
  }
}
    `;

/**
 * __useSettingsPageQuery__
 *
 * To run a query within a React component, call `useSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsPageQuery({
 *   variables: {
 *      projectExtId: // value for 'projectExtId'
 *   },
 * });
 */
export function useSettingsPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SettingsPageQuery, SettingsPageQueryVariables>) {
        return ApolloReactHooks.useQuery<SettingsPageQuery, SettingsPageQueryVariables>(SettingsPageDocument, baseOptions);
      }
export function useSettingsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingsPageQuery, SettingsPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SettingsPageQuery, SettingsPageQueryVariables>(SettingsPageDocument, baseOptions);
        }
export type SettingsPageQueryHookResult = ReturnType<typeof useSettingsPageQuery>;
export type SettingsPageLazyQueryHookResult = ReturnType<typeof useSettingsPageLazyQuery>;
export type SettingsPageQueryResult = ApolloReactCommon.QueryResult<SettingsPageQuery, SettingsPageQueryVariables>;
export const AppToolbarDocument = gql`
    query AppToolbar {
  loggedInSiteUser {
    id
    isSuperAdmin
    projects {
      id
      extId
      displayName
      logoUrl
      projectType {
        name
        displayName
      }
    }
  }
}
    `;

/**
 * __useAppToolbarQuery__
 *
 * To run a query within a React component, call `useAppToolbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppToolbarQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppToolbarQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppToolbarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppToolbarQuery, AppToolbarQueryVariables>) {
        return ApolloReactHooks.useQuery<AppToolbarQuery, AppToolbarQueryVariables>(AppToolbarDocument, baseOptions);
      }
export function useAppToolbarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppToolbarQuery, AppToolbarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppToolbarQuery, AppToolbarQueryVariables>(AppToolbarDocument, baseOptions);
        }
export type AppToolbarQueryHookResult = ReturnType<typeof useAppToolbarQuery>;
export type AppToolbarLazyQueryHookResult = ReturnType<typeof useAppToolbarLazyQuery>;
export type AppToolbarQueryResult = ApolloReactCommon.QueryResult<AppToolbarQuery, AppToolbarQueryVariables>;
export const ProjectPickerToolbarDocument = gql`
    query ProjectPickerToolbar {
  loggedInSiteUser {
    id
    isSuperAdmin
  }
}
    `;

/**
 * __useProjectPickerToolbarQuery__
 *
 * To run a query within a React component, call `useProjectPickerToolbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPickerToolbarQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPickerToolbarQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectPickerToolbarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPickerToolbarQuery, ProjectPickerToolbarQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPickerToolbarQuery, ProjectPickerToolbarQueryVariables>(ProjectPickerToolbarDocument, baseOptions);
      }
export function useProjectPickerToolbarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPickerToolbarQuery, ProjectPickerToolbarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPickerToolbarQuery, ProjectPickerToolbarQueryVariables>(ProjectPickerToolbarDocument, baseOptions);
        }
export type ProjectPickerToolbarQueryHookResult = ReturnType<typeof useProjectPickerToolbarQuery>;
export type ProjectPickerToolbarLazyQueryHookResult = ReturnType<typeof useProjectPickerToolbarLazyQuery>;
export type ProjectPickerToolbarQueryResult = ApolloReactCommon.QueryResult<ProjectPickerToolbarQuery, ProjectPickerToolbarQueryVariables>;