import * as React from 'react';
import { SearchField } from 'components/shared/forms/InputField/SearchField';

import styles from './SearchBar.module.css';

import imgSearch from 'assets/images/search.svg';



export interface ISearchBarProps {
  filterText: string;

  onChange: (newSearch: string) => void;
}

export const SearchBar: React.FC<ISearchBarProps> = props => (
  <div className={styles.root}>
    <img
      className={styles.searchIcon}
      src={imgSearch}
      alt="Search" />

    <SearchField
      extraClassName={styles.searchBar}
      placeholder="Search for projects"
      value={props.filterText}
      onChange={newSearch => props.onChange(newSearch)} />
  </div>
);
