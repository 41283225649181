import * as React from 'react';
import * as firebaseUtil from 'util/firebase';
import {
  RouteComponentProps,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router';
import {
  OnboardingForm,
  IOnboardingFormData
} from './Forms/OnboardingForm/OnboardingForm';
import { PageTemplate } from 'components/pages/PageTemplate/PageTemplate';
import { routes } from 'routes';
import { AuthenticationForm } from './Forms/AuthenticationForm/AuthenticationForm';
import { Button } from 'components/shared/Button/Button';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';

import styles from './AuthenticationPage.module.css';

import imgOnboardBackground from 'assets/images/fields2x.jpg';
import imgLogInBackground from 'assets/images/logInBackground.png';
import imgLogo from 'assets/images/ironForgeLogo.png';



type IPageState = 'login' | 'signUp' | 'error';

export interface IAuthenticationPageProps extends RouteComponentProps<{ pageState: IPageState }> { }

export const AuthenticationPage: React.FC<IAuthenticationPageProps> = props => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [pageLoading, setPageLoading] = React.useState(false);
  const [passwordResetSent, setPasswordResetSent] = React.useState(false);
  const [onboardingFormData, setOnboardingFormData] = React.useState<IOnboardingFormData>({
    name: '',
    encodedFileUrl: '',
    email: '',
    password: ''
  });

  const history = useHistory();
  const params = useSearchParams();

  let pageType = props.match.params.pageState;
  if (pageType !== 'signUp' && pageType !== 'login') {
    pageType = 'error';
  }

  const user = firebaseUtil.getLoggedInUser();



  if (user && user !== 'NO_LOGIN_ATTEMPTED_YET' && pageType !== 'error') {
    return <Redirect to={routes.home.path()} />;
  }



  function handleSuccessfulSubmit() {
    setPageLoading(true);
    firebaseUtil.signIn(email, password);
    setPageLoading(false);
  }



  function handleForgotPassword() {
    if (!email) {
      alert("Please enter a valid email.");
      return;
    }

    firebaseUtil.sendPasswordResetEmail(email)
      .then(() => setPasswordResetSent(true));
  }



  async function handleSuccesfulSubmitOnboarding() {
    setPageLoading(true);

    const userDidSignUp = await firebaseUtil.signUp(
      onboardingFormData.email,
      onboardingFormData.password,
      params.inviteCode === null ? '' : params.inviteCode,
      onboardingFormData.name,
      onboardingFormData.encodedFileUrl ? onboardingFormData.encodedFileUrl : undefined);

    if (!userDidSignUp) {
      history.push(routes.auth.path('error', params.inviteCode, params.projectName || ''));
    }

    setPageLoading(false);
  }



  return (
    <>
      <LoadingOverlay
        isVisible={pageLoading}
        text="Loading" />

      <PageTemplate
        {...props}
        toolbarType="hidden">
        <div className={styles.root}>
          <div className={styles.spacer} />

          <div className={styles.formContainer}>
            {pageType === 'login' && (
              <ContentFormContainer
                title="Log In"
                description="Use the credentials supplied to you to access your Iron Forge account. Not sure what your account is? Contact us at info@ironforge.co.">
                <AuthenticationForm
                  email={email}
                  password={password}
                  passwordResetSent={passwordResetSent}
                  formDisabled={pageLoading}
                  onForgotPassword={handleForgotPassword}
                  onSuccessfulSubmit={handleSuccessfulSubmit}
                  onChangeEmail={setEmail}
                  onChangePassword={setPassword} />
              </ContentFormContainer>
            )}

            {pageType === 'signUp' && (
              <ContentFormContainer
                title="You've Been Invited"
                description={`Iron Forge has invited you to join the project ${params.projectName}. Create an account below to begin!`}>
                <OnboardingForm
                  formData={onboardingFormData}
                  projectName={params.projectName || ""}
                  inviteCode={params.inviteCode}
                  onChangeFormData={setOnboardingFormData}
                  onSuccessfulSubmit={handleSuccesfulSubmitOnboarding} />
              </ContentFormContainer>
            )}

            {pageType === 'error' && (
              <div className={styles.errorBox}>
                <div className={styles.title}>
                  Couldn't Create an Account
                </div>

                <div className={styles.description}>
                  We couldn't create you an account.  This could be because your code has expired, or you have an email already linked to the client portal.
                </div>

                <div className={styles.description}>
                  Please check your link and try again.
                </div>

                <Button onClick={() => {
                  firebaseUtil.logOut();
                  history.goBack();
                }} >
                  Try Again
                </Button>
              </div>
            )}
          </div>

          <img
            alt="Background"
            className={styles.backgroundImage}
            src={pageType === 'login' ? imgLogInBackground : imgOnboardBackground} />

          <img
            alt="Iron Forge Logo"
            className={styles.logo}
            src={imgLogo} />
        </div>
      </PageTemplate>
    </>
  );
};



interface IContentFormContainerProps {
  title: string;
  description: string;
}

const ContentFormContainer: React.FC<IContentFormContainerProps> = props => {
  return (
    <div className={styles.contentFormContainer}>
      <h1 className={styles.contentFormTitle}>
        {props.title}
      </h1>

      <div className={styles.contentFormDescription}>
        {props.description}
      </div>

      {props.children}
    </div>
  );
};



export function useSearchParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inviteCode = searchParams.get('inviteCode');
  const projectName = searchParams.get('projectName');
  return {
    inviteCode: !inviteCode ? '' : inviteCode,
    projectName: !projectName ? '' : inviteCode
  };
}
