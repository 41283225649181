/**
 * Converts a number representing a dollar amount into a standard layout for displaying dollars and cents.
 * @param cents a number representing the total value of the currency in cents (10, 4000000, etc...)
 */
export function cents2Dollars(num: number): string {
  const dollars: number = Math.floor(num / 100);
  const cents: number = num % 100;

  const dollarString: string = delimitDollars(dollars);
  const centsString: string = number2DisplayDecimal(cents);

  if (centsString === "") {
    return dollarString;
  }

  return `${dollarString}.${centsString}`;
}

/**
 * This function will arrange a given decimal into a more familiar state for money.
 *  (e.x. {.70} -> {.70}, {.} -> {}, {.23 -> .23})
 * @param cents a 2 digit decimal representing cents after the total value has been converted to dollars.
 */
export function number2DisplayDecimal(cents: number): string {
  return cents === 0 ? "" : cents.toString();
}


/**
 * Adds commas in between thousands places for a dollar amount.
 * @param dollars amount of currency in dollars.
 */
export function delimitDollars(dollars: number): string {
  return dollars.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
