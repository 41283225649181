import * as React from 'react';

import styles from './LogCard.module.css';
import { ProfilePicture } from 'components/shared/ProfilePicture/ProfilePicture';



export interface ILogCardProps {
  profilePicUrl: string;
  name: string;
  description: string;
  date: string;
}



export const LogCard: React.FC<ILogCardProps> = props => (
  <div className={styles.root}>
    <div className={styles.leftContent}>
      <ProfilePicture profilePictureUrl={props.profilePicUrl} />

      <div className={styles.textContainer}>
        <h2 className={styles.name}>
          {props.name}
        </h2>

        <div className={styles.description}>
          {props.description}
        </div>
      </div>
    </div>

    <div className={styles.rightContent}>
      <div className={styles.date}>
        {props.date}
      </div>
    </div>
  </div>
);
