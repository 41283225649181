import _ from 'lodash';

import {
  useAttachmentsPageQuery as useBaseQuery,
  useUploadProjectAttachmentLinkMutation as useBaseUploadProjectAttachmentLinkMutation,
  useUploadProjectAttachmentFileMutation as useBaseUploadProjectAttachmentFileMutation,
  AttachmentsPageDocument
} from "generated/graphqlTypes";

import * as encodedFileUtil from 'util/encodedFile';



export type IAttachmentType = 'file' | 'image' | 'video' | 'link' | 'unknown';

export interface IAttachment {
  id: string;
  attachmentType: IAttachmentType;
  resourceUrl: string;
  displayName: string;
  description: string;

  iconUrl: string;
}



const __validAttachmentTypes: Array<Exclude<IAttachmentType, 'unknown'>> = ['file', 'image', 'video', 'link'];

export function useAttachmentPageQuery(projectExtId: string, filterText: string) {
  const queryTuple = useBaseQuery({
    variables: {
      projectExtId
    }
  });

  const data = {
    projectId: queryTuple.data?.project?.id ?? '',
    attachments: [] as IAttachment[]
  };

  if (!queryTuple.loading) {
    data.attachments = _.map(queryTuple.data?.project?.attachments ?? [], (attachment): IAttachment => ({
      id: attachment.id,
      attachmentType: _.includes(__validAttachmentTypes, attachment.type.name) ? attachment.type.name as IAttachmentType : 'unknown',
      resourceUrl: attachment.resourceUrl,
      displayName: attachment.displayName,
      description: attachment.description,
      iconUrl: ''
    }));

    if (!_.isEmpty(filterText) && !_.isEmpty(data)) {
      data.attachments = _.filter(data.attachments, attachment => _.includes(
        _.toLower(attachment.description + attachment.displayName),
        _.toLower(filterText)
      ));
    }
  }

  return {
    ...queryTuple,
    data
  };
}



export function useUploadProjectAttachmentFileMutation(projectExtId: string) {
  const [fn, tuple] = useBaseUploadProjectAttachmentFileMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: AttachmentsPageDocument,
      variables: { projectExtId }
    }]
  });

  const wrappedFn = (displayName: string, projectId: string, dataUrl: string, filename: string) => {
    return fn({
      variables: {
        projectExtId,
        displayName,
        encodedFile: {
          encodedFileInput: {
            dataUrl,
            fileExtension: encodedFileUtil.getExtensionFromFilename(filename)
          },
          filename
        }
      }
    });
  };

  return [
    wrappedFn,
    tuple
  ] as const;
}



export function useUploadProjectAttachmentLinkMutation(projectExtId: string) {
  const [fn, tuple] = useBaseUploadProjectAttachmentLinkMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: AttachmentsPageDocument,
      variables: { projectExtId }
    }]
  });

  const wrappedFn = (displayName: string, projectId: string, linkUrl: string) => fn({
    variables: {
      displayName,
      linkUrl,
      projectExtId
    }
  });

  return [
    wrappedFn,
    tuple
  ] as const;
}
