import * as React from 'react';
import _ from 'lodash';

import {
  BaseModalTemplate,
  IBaseModalTemplateProps
} from 'components/shared/BaseModalTemplate/BaseModalTemplate';

import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { EmailField } from 'components/shared/forms/InputField/EmailField';
import { Button } from 'components/shared/Button/Button';

import styles from './LinkSiteUserModal.module.css';



export interface ILinkSiteUserModalProps extends IBaseModalTemplateProps {
  onSubmit: (email: string) => void;
}

export const LinkSiteUserModal: React.FC<ILinkSiteUserModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const [email, setEmail] = React.useState('');



  function handleSuccessfulSubmit() {
    props.onSubmit(email);
    setEmail('');
    props.onClose();
  }



  return (
    <BaseModalTemplate
      isVisible={props.isVisible}
      onClose={props.onClose}>
      <div className={styles.root}>
        <h1 className={styles.title}>
          Invite New User
        </h1>

        <p className={styles.desc}>
          In hac habitasse platea dictumst. Vivamus adipiscing fermentum quam volutpat aliquam. Integer et elit eget elit facilisis tristique..
        </p>

        <Form
          ref={refForm}
          onSuccessfulSubmit={handleSuccessfulSubmit}>
          <InputRow>
            <StackedInputCell>
              <label className={styles.formText}>
                Email
              </label>

              <EmailField
                ref={_.partial(Form.captureElement, refForm, 'email')}
                required
                value={email}
                placeholder="email@email.com"
                onChange={setEmail}/>
            </StackedInputCell>
          </InputRow>

          <InputRow>
            <StackedInputCell>
              <Button
                extraClassName={styles.button}
                type="submit">
                Invite
              </Button>
            </StackedInputCell>
          </InputRow>
        </Form>
      </div>
    </BaseModalTemplate>
  );
};
