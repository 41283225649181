import * as React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import * as dataService from './dataService';
import { Form } from 'metisoft-react-components/dist/components/forms/Form/Form';
import { InputRow } from 'metisoft-react-components/dist/components/forms/InputRow/InputRow';
import { StackedInputCell } from 'components/shared/forms/StackedInputCell/StackedInputCell';
import { Button } from 'components/shared/Button/Button';
import { EmailField } from 'components/shared/forms/InputField/EmailField';
import { PasswordField } from 'components/shared/forms/InputField/PasswordField';
import { NameField } from 'components/shared/forms/InputField/NameField';
import { LogoField } from 'components/shared/LogoField/LogoField';

import styles from './OnboardingForm.module.css';



export interface IOnboardingFormData {
  encodedFileUrl: string | null;
  name: string;
  email: string;
  password: string;
}



export interface IOnboardingFormProps {
  formData: IOnboardingFormData;
  projectName: string;
  inviteCode: string | null;

  onSuccessfulSubmit: () => void;
  onChangeFormData: (form: IOnboardingFormData) => void;
}

export const OnboardingForm: React.FC<IOnboardingFormProps> = props => {
  const queryTuple = dataService.useValidInviteCodeQuery(props.inviteCode !== null ? props.inviteCode : '');
  const refForm = React.useRef<Form>(null);
  const buttonDisabled = queryTuple.loading || !(queryTuple.data && queryTuple.data.isValid);

  return (
    <Form
      extraClassName={styles.form}
      ref={refForm}
      onSuccessfulSubmit={props.onSuccessfulSubmit}>
      <InputRow>
        <StackedInputCell>
          <LogoField
            extraClassName={styles.imageBar}
            logoUrl={props.formData.encodedFileUrl || ''}
            onChange={newLogoUrl => (
              props.onChangeFormData(update(props.formData, {
                encodedFileUrl: { $set: newLogoUrl },
              }))
            )} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.inputLabel}>
            Name
          </label>

          <NameField
            autoComplete={false}
            ref={_.partial(Form.captureElement, refForm, 'name')}
            placeholder="John Doe"
            value={props.formData.name}
            onChange={name => (
              props.onChangeFormData(update(props.formData, {
                name: { $set: name }
              }))
            )} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.inputLabel}>
            Email Address
          </label>

          <EmailField
            autoComplete={false}
            ref={_.partial(Form.captureElement, refForm, 'email')}
            placeholder="email@email.com"
            value={props.formData.email}
            onChange={email => (
              props.onChangeFormData(update(props.formData, {
                email: { $set: email }
              }))
            )} />
        </StackedInputCell>
      </InputRow>

      <InputRow>
        <StackedInputCell>
          <label className={styles.inputLabel}>
            Password
          </label>

          <PasswordField
            autoComplete={false}
            ref={_.partial(Form.captureElement, refForm, 'password')}
            placeholder="********"
            value={props.formData.password}
            onChange={password => (
              props.onChangeFormData(update(props.formData, {
                password: { $set: password }
              }))
            )} />
        </StackedInputCell>
      </InputRow>

      <Button
        disabled={buttonDisabled}
        extraClassName={buttonDisabled ? styles.disabledButton : ''}>
        {buttonDisabled ? "Invalid Code" : "Log In"}
      </Button>
    </Form>
  );
};
