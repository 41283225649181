import { useValidInviteCodeQuery as useBaseQuery } from 'generated/graphqlTypes';



export interface IValidCodeQuery {
  isValid: boolean;
}

export function useValidInviteCodeQuery(code: string) {
  const queryTuple = useBaseQuery({
    variables: {
      code
    }
  });

  let data: IValidCodeQuery | null = null;
  if (!queryTuple.loading && queryTuple.data) {
    data = {
      isValid: queryTuple.data.validInviteCode
    };
  }

  return {
    ...queryTuple,
    data
  };
}
